<template lang="pug">
.v-profile(v-if='isLoggedIn')
  h1 Profile
  .form__panel
    .form__row
      .form__row--fields
        .form__header
          span Email:
        .form__input
          | {{ email }}
    .form__row(v-if='admin')
      .form__row--fields
        .form__header
          span Admin:
        .form__input
          | {{ admin }}
    .form__row
      .form__row--fields
        .form__header
          span Payment Plan:
        .form__input
          | {{ paymentPlan }}
    .form__row
      .form__row--fields
        .form__header
          span Plan Expiry:
        .form__input
          | {{ planExpiry }}

  button.action_button.button__danger.mgn-t-20.mgn-b-20(@click='deleteAccount')
    | Delete Account
</template>

<script>
import PermissionsMixin from "@/components/permissionsMixin.vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  mixins: [PermissionsMixin],
  name: 'profile',
  data() {
    return {}
  },
  computed: {
    profile: function() { return this.$store.state.profile },
    email: function () { return this.profile ? this.profile.email : '' },
    admin: function () { return this.profile ? this.profile.admin : '' },
    paymentPlan: function () { return this.profile ? this.profile.payment_plan : '' },
    planExpiry: function () { return this.profile ? this.profile.plan_expiry : '' }
  },
  methods: {
    deleteAccount: function () {
      Swal.fire({
        title: "Delete Account",
        text: 'Are you sure you want to delete your account, all your information will be lost, this action cannot be recovered?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/user/"+this.profile.id).then((response) => {
            // Using window.href because we want to reload the page without profile
            window.location.href = '/'
          })
        }
      })

    }
  },
  mounted: function () {
  },
  components: {
  }
}
</script>

<style lang="scss">
</style>