<template lang='pug'>
.v-welcome
  .welcome-section1.mgn-t-10
    .welcome-welcome-title.mgn-t-50
      img.pad-r-5(src='/images/fyffle_icon.png' height='18px')
      | Welcome to Fyffle
    .welcome-h1.welcome-h1__forecast.mgn-t-10
      | Forecast Your Future. Make Smarter Financial Decisions.
    .welcome-subtext.mgn-t-10.pad-l-20.pad-r-20
      | Make good future financial decisions today by forecasting using your own financial data.
    button.welcome-register-now.mgn-t-20(@click='register')
      | Register Now

  .welcome-section2.mgn-t-40
    .welcome-h1.welcome-h1__compare
      | Compare multiple different financial scenarios
    .welcome-compare-container
      .welcome-compare-left
        .welcome-compare-text.mgn-b-15
          | Create your own financial scenarios, and then compare them.
        .welcome-compare-buttons
          a.welcome-compare-button(href='/learn/keep-renting-vs-buy-house')
            | Can I afford to buy a home or a new car?
          a.welcome-compare-button(href='/learn/keep-renting-vs-buy-house')
            | Am I better off renting or buying a house?
          a.welcome-compare-button(href='/learn/pay-off-mortgage-or-invest')
            | Is it better to invest in shares or put money towards my mortgage?
      .welcome-compare-img
        img.pad-r-5(src='/images/charts1.png' width='480px')

  .welcome-section3.mgn-t-40
    .welcome-h1.welcome-h1__right
      | Track your bank balance in the future
    .welcome-informed-container
      .welcome-informed-img
        img(src='/images/bank_balance.png' width='480px')
      .welcome-informed-text
        | Make informed financial decisions today. See the potential impact of each choice before you commit.
    .welcome-future-container
      button.mgn-t-30.welcome-future-button
        | Asset Balances Report
      button.mgn-t-30.welcome-future-button
        | Income Expense Report
      .welcome-informed-text.mgn-t-30
        | Build a Brighter Future with Data-Driven Insights into Your Finances.

  .welcome-section4.mgn-t-40
    .welcome-h1.welcome-h1__compare
      | Effortlessly view all your finances in one place
    .welcome-events-container
      .welcome-finances
        img(src='/images/finances_summary.png' width='100%')
      .welcome-finances
        | Visualize your annual asset value, liability value, your combined incomes and expenses from all sources.

  .welcome-section5.mgn-t-40
    .welcome-h1.welcome-h1__right
      | See upcoming money events
    .welcome-events-container
      .welcome-upcoming-money
        img(src='/images/whats_happening.png' width='100%')
      .welcome-upcoming-money
        | Prepare for income and expenses in the future with this future week table.

  .welcome-section-last.mgn-t-20
    .welcome-h1.welcome-h1__section-last
      | See financial forecasting reports
    .welcome-forecast-text.mgn-t-20
      | After you create all your different scenarios, create a report and compare the figures.
    button.welcome-forecast-button.mgn-t-30(@click='register')
      | Get Started Today!

</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  methods: {
    register: function () {
      window.location.href='/users/sign_up'
    }
  },
  components: {
  },
  mounted: function () {
    useHead({
      title: 'Welcome to Fyffle - Future Financial Forecasting',
      meta: [
        { name: 'description', content: 'Make good future financial decisions today by forecasting using your own financial data.' },
      ],
    })
  }
}
</script>

<style lang='scss'>
.v-welcome {
  .report-img-container {
    margin-top: 30px;
    height: 164px;
    overflow: hidden;

    img {
      width: 400px;
      max-width: 100%;
      height: auto;
      margin: -8px 0 0 -19px;
    }

  }


}
</style>