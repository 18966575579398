<template lang="pug">
.v-income-change
  Changes2Form(:fields='changeFields' :adding='adding' :fieldValues='changeFieldValues' :moneyType='moneyText' :moneyName='moneyName' @postChanges='postIncomeChanges' @cancelled='cancelled')
  MoneyBar(v-if='scenarioId')
</template>

<script>
import Changes2Form from "@/components/changes2Form.vue";
import axios from "axios";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  props: ['returnPageUrl'],
  name: 'incomeChange',
  data() {
    return {
      changeFields: [
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the amount will change"},
        {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new amount value"},
      ],
      changeFieldValues: [],
      changes: undefined,
      income: {}
    }
  },
  computed: {
    isIncome: function () { return this.$route.name === 'EditScenarioIncomeChange' || this.$route.name === 'AddScenarioIncomeChange'},
    isExpense: function () { return this.$route.name === 'EditScenarioExpenseChange' || this.$route.name === 'AddScenarioExpenseChange' },
    moneyText: function () { return this.isIncome ? "Income" : "Expense" },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return this.isIncome ? "incomes" : "expenses" },
    moneyName: function () { return this.income ? this.income.name : '' },
    scenarioId: function () { let n = this.$route.params.scenarioId; return n ? Number(n) : undefined },
    incomeId: function () { return this.$route.params.incomeId },
    expenseId: function () { return this.$route.params.expenseId },
    moneyId: function () { return this.isIncome ? this.incomeId : this.expenseId },
    changesId: function () { return this.$route.params.id },
    adding: function () { return true },
    addEditStr: function () { return this.changesId ? "Edit" : "Add"},
    baseUrl: function () { return "/api/money_changes"},
  },
  methods: {
    returnPageUrlB: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.moneyText+"_"+id : "/"+this.moneyTextLowerPlural
    },
    postIncomeChanges: function (newChanges) {
      this.changes = newChanges[0]
      let model = {
        money_id: this.moneyId,
        money_type: this.isIncome ? 7 : 8,
        money_changes: this.changes,
        start_date: this.changes['start_date'],
        scenario_id: this.scenarioId
      }
      if (this.adding) {
        axios.post(this.baseUrl, {money_change: model}).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      } else {
        axios.put(this.baseUrl+"/"+this.changesId, model).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      }
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrlB(this.moneyId || ''))
    },
    loadIncome: function () {
      axios.get("/api/"+this.moneyTextLowerPlural+"/"+this.moneyId+"?scenario_id="+this.scenarioId).then((response) => {
        this.income = response.data
      });
    }
  },
  mounted: function () {
    this.loadIncome()
  },
  components: {
    Changes2Form,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>