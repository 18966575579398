<template lang="pug">
.v-add-edit-asset-change
  Changes2Form(:fields='changeFields' :adding='adding' :fieldValues='changeFieldValues' :moneyType='moneyText' :moneyName='moneyName' @postChanges='postAssetChanges' @cancelled='cancelled')
  MoneyBar(v-if='scenarioId')
</template>

<script>
import Changes2Form from "@/components/changes2Form.vue";
import axios from "axios";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  props: ['returnPageUrl'],
  name: 'assetChange',
  data() {
    return {
      changeFields: [
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the change in appreciation will happen"},
        {name: 'appreciation', label: 'Appreciation', type: 'number', mandatory: true, help_text: "The new appreciation value for the "+this.moneyTextLower},
      ],
      changeFieldValues: [],
      changes: undefined,
      asset: {}
    }
  },
  computed: {
    isAsset: function () { return this.$route.name === 'EditScenarioAssetChange' || this.$route.name === 'AddScenarioAssetChange'},
    isLiability: function () { return this.moneyType === 'EditScenarioLiabilityChange' || this.$route.name === 'AddScenarioLiabilityChange' },
    moneyText: function () { return this.isAsset ? "Asset" : "Liability" },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return this.isAsset ? "assets" : "liabilities" },
    moneyName: function () { return this.asset ? this.asset.name : '' },
    scenarioId: function () { let n = this.$route.params.scenarioId; return n ? Number(n) : undefined },
    assetId: function () { return this.$route.params.assetId },
    liabilityId: function () { return this.$route.params.liabilityId },
    moneyId: function () { return this.isAsset ? this.assetId : this.liabilityId },
    changesId: function () { return this.$route.params.id },
    adding: function () { return true },
    addEditStr: function () { return this.changesId ? "Edit" : "Add"},
    baseUrl: function () { return "/api/money_changes"},
  },
  methods: {
    returnPageUrlB: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.moneyText+"_"+id : "/"+this.moneyTextLowerPlural
    },
    postAssetChanges: function (newChanges) {
      this.changes = newChanges[0]
      let model = {
        money_id: this.moneyId,
        money_type: this.isAsset ? 1 : 4,
        money_changes: this.changes,
        start_date: this.changes['start_date'],
        end_date: this.changes['end_date'],
        scenario_id: this.scenarioId
      }
      if (this.adding) {
        axios.post(this.baseUrl, {money_change: model}).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      } else {
        axios.put(this.baseUrl+"/"+this.changesId, model).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      }
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrlB(this.moneyId || ''))
    },
    loadAsset: function () {
      axios.get("/api/"+this.moneyTextLowerPlural+"/"+this.moneyId+"?scenario_id="+this.scenarioId).then((response) => {
        this.asset = response.data
      });
    }
  },
  mounted: function () {
    this.loadAsset()
  },
  components: {
    Changes2Form,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>