<template lang="pug">
.v-expense-table
  table.simple-table
    tr
      th Name
      th Start Date
      th End Date
      th Amount
      th(v-if='isAssetLiab') Interest
      th Frequency
      th Withdraw from Asset
      th Withdraw from Liability
      th Changes
      th(v-if='includeActions') Actions
    tr(v-for='expense in expenses' :value='expense.name')
      td {{expense.name}}
      td.date-cell {{expense.start_date}}
      td.date-cell {{expense.end_date}}
      td.number-cell {{formatNumber(expense.amount)}}
      td.number-cell(v-if='isAssetLiab') {{formatNumber(expense.interest)}}
      td {{formatFrequency(expense.frequency)}}
      td {{expense.withdraw_from_asset}}
      td {{expense.withdraw_from_liability}}
      td {{changes(expense)}}
      td(v-if='includeActions')
        a(href='#' @click.prevent='editExpense(expense)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteExpense(expense)') Delete

</template>

<script>
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  props: ['expenses', 'includeActions','isAssetLiab'],
  name: 'expenseTable.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {
    editExpense: function (expense) {
      this.$emit('edit', expense)
    },
    deleteExpense: function (expense) {
      this.$emit('delete', expense)
    },
    changes(expense) {
      let changeStr = []
      expense.money_changes.forEach(item => {
        changeStr.push(item.start_date+": "+(item.money_changes.amount || item.money_changes.interest) )
      })
      return changeStr.join(", ")
    },

  },
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>