<template lang="pug">
.v-getting-started-panel
  .getting-started-title-image
    img(src='/images/dollars_arrow.png' width='100%')
  .getting-started-h1
    | Getting started with your financial forecast
  button.enter-data-btn(@click="enterData()")
    | {{ nextItemToEnter() }}
  .getting-started-progress-row
    button.progress-btn.btn-assets(@click="goto('assets')" :class='btnProgressClass(1)') Assets
    .line-div(:class='lineProgressClass(1)')
    .line-div(:class='lineProgressClass(2)')
    button.progress-btn.btn-liabilities(@click="goto('liabilities')" :class='btnProgressClass(2)') Liabilities
    .line-div(:class='lineProgressClass(2)')
    .line-div(:class='lineProgressClass(4)')
    button.progress-btn.btn-incomes(@click="goto('incomes')" :class='btnProgressClass(4)') Incomes
    .line-div(:class='lineProgressClass(4)')
    .line-div(:class='lineProgressClass(8)')
    button.progress-btn.btn-expenses(@click="goto('expenses')" :class='btnProgressClass(8)') Expenses
    .line-div(:class='lineProgressClass(8)')
    .line-div(:class='lineProgressClass(16)')
    button.progress-btn.btn-transfers(@click="goto('transfers')" :class='btnProgressClass(16)') Transfers
    .line-div(:class='lineProgressClass(16)')
    .line-div(:class='lineProgressClass(32)')
    button.progress-btn.btn-scenarios(@click="goto('scenarios')" :class='btnProgressClass(32)') Scenarios
    .line-div(:class='lineProgressClass(32)')
    .line-div(:class='lineProgressClass(64)')
    button.progress-btn.btn-report(@click="goto('report/future-forecast-report')" :class='btnProgressClass(64)') Report
</template>

<script>

export default {
  name: 'gettingStartedPanel.vue',
  data() {
    return {}
  },
  computed: {
    profile: function() { return this.$store.state.profile },
    progress: function () { return this.profile.progress },
    progressComplete: function () { return (this.progress & 127 === 127) },
  },
  methods: {
    goto: function (route) {
      this.$router.push(route)
    },
    enterData: function () {
      if ((this.progress & 1) === 0) { this.goto("assets") }
      else if ((this.progress & 2) === 0) { this.goto("liabilities") }
      else if ((this.progress & 4) === 0) { this.goto("incomes") }
      else if ((this.progress & 8) === 0) { this.goto("expenses") }
      else if ((this.progress & 16) === 0) { this.goto("transfers") }
      else if ((this.progress & 32) === 0) { this.goto("scenarios") }
      else if ((this.progress & 64) === 0) { this.goto("report/future-forecast-report") }
    },
    nextItemToEnter: function () {
      const pretext = "Enter your "
      if ((this.progress & 1) === 0) { return pretext + "assets" }
      if ((this.progress & 2) === 0) { return pretext + "liabilities" }
      if ((this.progress & 4) === 0) { return pretext + "incomes" }
      if ((this.progress & 8) === 0) { return pretext + "expenses" }
      if ((this.progress & 16) === 0) { return pretext + "transfers" }
      if ((this.progress & 32) === 0) { return pretext + "scenarios" }
      if ((this.progress & 64) === 0) { return "Run the forecast report" }
    },
    btnProgressClass: function (bit) {
      if ((this.progress & bit) === 0) {
        return "progress-inactive"
      }
    },
    lineProgressClass: function (bit) {
      if ((this.progress & bit) === 0) {
        return "line-inactive"
      }
    }
  },
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
.v-getting-started-panel {
  text-align: center;
  .getting-started-title-image {
    img {
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
    }
  }
  .getting-started-h1 {
    padding-top: 60px;
    font-size: 25px;
    color: var(--color-theme1);
  }
  .enter-data-btn {
    margin-top: 10px;
    border: var(--color-theme1) solid 1px;
    background-color: var(--color-button-primary-background);
    border-radius: 23px;
    color: var(--color-theme1);
    padding: 8px 12px;
    font-size: 20px;
  }
  .getting-started-progress-row {
    margin-top: 50px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
  .progress-btn {
    padding: 4px 8px;
    border: none;
    border-radius: 12px;
    color: #000000;
    margin-bottom: 5px;
  }
  .progress-inactive {
    background-color: #EEEEEE !important;
    color: #AAAAAA;
  }
  .line-div {
    height: 15px;
    width: 6px;
    border-bottom: var(--color-theme1) solid 2px;
  }
  .line-inactive {
    border-bottom: #AAAAAA solid 2px;
  }
  .btn-assets {
    background-color: var(--color-asset-box);
  }
  .btn-liabilities {
    background-color: var(--color-liability-box);
  }
  .btn-incomes {
    background-color: var(--color-income-box);
  }
  .btn-expenses {
    background-color: var(--color-expense-box);
  }
  .btn-transfers {
    background-color: var(--color-transfer-box);
  }
  .btn-scenarios {
    background-color: var(--color-scenario-box);
  }
  .btn-report {
    background-color: var(--color-report-box);
  }
}
</style>