<template lang="pug">
.v-assets(v-if='isLoggedIn')
  .heading-1
    | Assets
  .assets__intro
    .money__intro_p
      | An asset is resource with economic value that you own. Assets can be tangible, such as physical items like bank accounts, real estate, machinery, equipment, inventory, or cash, or intangible, such as intellectual property, patents, trademarks, goodwill, or investments like stocks and bonds.
    .money__intro_p
      | Any income gained from an asset like interest, dividends or expenses like fees should be added in the new Asset page.
  table.simple-table(v-if='hasAssets')
    tr
      th Name
      th Start Date
      th End Date
      th Amount
      th Appreciation
      th Actions
    tr(v-for='asset in assets' :value='asset.name')
      td {{asset.name}}
      td.date-cell {{asset.start_date}}
      td.date-cell {{asset.end_date}}
      td.number-cell {{formatNumber(asset.amount)}}
      td.number-cell {{asset.appreciation}}
      td
        a(href='#' @click.prevent='editAsset(asset)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteAsset(asset)') Delete

  .money-button-bar.mgn-b-30
    button.action_button(@click='addAsset')
      | Add new Asset
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'assets',
  data() {
    return {
      assets: undefined
    }
  },
  computed: {
    profile: function() { return this.$store.state.profile },
    progressAssets: function () { return (this.profile.progress & 1) > 0 },
    showProgressBtn: function () { return !this.progressAssets && !this.hasAssets },
    hasAssets: function () {
      return this.assets != null && this.assets.length > 0
    }
  },
  methods: {
    addAsset() {
      this.progress('/addEditAsset')
    },
    done() {
      this.$router.push('/')
    },
    deleteAsset(asset) {
      Swal.fire({
        title: "Delete Asset",
        text: 'Are you sure you want to delete asset '+asset.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/assets/"+asset.id).then((response) => {
            this.loadAssets()
          })
        }
      })
    },
    editAsset(asset) {
      this.$router.push(`/addEditAsset/${asset.id}`)
    },
    loadAssets() {
      axios.get("/api/assets").then((response) => {
        this.assets = response.data
      });
    },
    progress(url) {
      axios.put("/user/progress?flag=1").then((response) => {
        const progress = response.data
        this.$store.commit('updateUserProgress', progress)
        this.$router.push(url)
      });
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Assets',
      meta: [
        { name: 'description', content: 'A collection of financial assets you own.' },
      ],
    })
    this.loadAssets()
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>