<template lang="pug">
.v-add-edit-scenario(v-if='isLoggedIn')
  .form__panel
    .heading-1
      | {{addEditStr}} Scenario
    .money__intro_p
      | This is where you can create different scenarios with your finances to compare with other scenarios.
    .money__intro_p
      | All your existing Assets, Liabilities, Incomes, Expenses or Bank Transfers will appear
      span.non-scenario-row  gray
      |  in the tables below. You cannot edit your existing money here. But you can make changes by adding new Assets, Liabilities, Incomes, Expenses, Bank Transfers or by overriding existing ones.

    .money__intro_p
      | Any changes you make will appear
      span.scenario-row  green
      |  in the table. <b>Changes you make in this page will only exist in this scenario.</b>
      | You can also create changes for money items for the future, for example if your salary amount changes in the future.

    .money__intro_p
      | Here are some articles that may help you if you need it:
      ul.pad-l-40
        li
          a(href='/learn/keep-renting-vs-buy-house')
            | Buy a new house
        li
          a(href='/learn/pay-off-mortgage-or-invest')
            | Pay off my loan quicker

    .form__row
      .form__row--fields
        .form__header
          span Name
          span.form__input--mandatory
            |  *
        .form__input
          input(type='text' v-model='scenario.name' @focus="focusOnName($event.target)" @blur="scenarioNameChange" ref='input')
    .form__row
      .form__row--fields
        .form__header
          span Description
        .form__input
          textarea(type='text' v-model='scenario.description')

    AssetLiabilityTable(:assetItems='assetItems' :isAsset='true' :scenarioId='scenarioId' @reload='loadData')
    AssetLiabilityTable(:assetItems='liabilityItems' :isAsset='false' :scenarioId='scenarioId' @reload='loadData')

    IncomeExpenseTable(:incomeItems='incomeItems' :isIncome='true' :scenarioId='scenarioId' @reload='loadData')
    IncomeExpenseTable(:incomeItems='expenseItems' :isIncome='false' :scenarioId='scenarioId' @reload='loadData')

    TransferTable(:transferItems='transferItems' :scenarioId='scenarioId' @reload='loadData')

  .form__panel.pad-b-30
    .form__buttons
      button.cancel_button(@click='cancel') Cancel
      button.action_button(@click='save(true)') Save
  MoneyBar
</template>

<script>
import axios from "axios";
import AssetLiabilityTable from "@/scenarios/assetLiabilityTable.vue";
import IncomeExpenseTable from "@/scenarios/incomeExpenseTable.vue";
import TransferTable from "@/scenarios/transferTable.vue";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin],
  name: 'addEditScenario',
  data() {
    return {
      scenario: {},
      stats: {},
      origName: undefined
    }
  },
  computed: {
    scenarioId: function () { let n = this.$route.params.id || this.scenario.id; return n ? Number(n) : undefined },
    addParam: function () { return this.$route.query.add },
    addingScenario: function () { return (this.addParam === 'true') },
    addEditStr: function () { return (this.addingScenario ? "Add" : "Edit") },
    assetItems: function () { return (this.stats['assets'])},
    liabilityItems: function () { return (this.stats['liabilities'])},
    incomeItems: function () { return (this.stats['incomes'])},
    expenseItems: function () { return (this.stats['expenses'])},
    transferItems: function () { return (this.stats['transfers'])},
    scenarioName: function () { return this.scenario.name }
  },
  methods: {
    focusOnName: function (target) {
      if (this.addingScenario) {
        target.select()
      }
    },
    save: function (move) {
      let model = {
        name: this.scenario.name,
        description: this.scenario.description
      }
      axios.put("/api/scenarios/"+this.scenarioId, {scenario: model}).then((response) => {
        this.scenario = response.data
        this.origName = this.scenario.name
        if (move) {
          this.$router.push("/scenarios");
        }
      });
    },
    cancel: function () {
      if (this.addingScenario) {
        // Should delete entry
        axios.delete("/api/scenarios/"+this.scenarioId).then((response) => {
          this.scenario = response.data
          this.$router.push("/scenarios");
        });
      } else {
        this.$router.push("/scenarios");
      }
    },
    loadData: function () {
      axios.get("/api/scenarios/current_financial_status?scenario_id="+this.scenarioId).then((response) => {
        this.stats = response.data
      });
      axios.get("/api/scenarios/"+this.scenarioId).then((response) => {
        this.scenario = response.data
        this.origName = this.scenarioName
      });
    },
    scenarioNameChange: function () {
      if (this.scenarioName !== this.origName) {
        this.save(false)
      }
    }
  },
  mounted: function () {
    this.loadData()
    this.$nextTick(() => {
      setTimeout(() => {
        this.$refs.input.focus()
      }, 100)
    })
  },
  components: {
    AssetLiabilityTable,
    IncomeExpenseTable,
    TransferTable,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>