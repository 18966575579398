<template lang="pug">
.v-incomes(v-if='isLoggedIn')
  .heading-1
    | Incomes
  .incomes__intro
    .money__intro_p
      | Income refers to the money or financial gains received by an individual, company, or entity as a result of providing goods, services, investments, or through other sources such as interest, dividends, rents, royalties, or capital gains.
    .money__intro_p
      | Add your income in below

  IncomeTable(v-if='hasIncomes' :incomes='incomes' :includeActions='true' @edit='editIncome' @delete='deleteIncome')

  .heading-3.mgn-t-30(v-if='hasAssetIncomes')
    | Asset Incomes
  .money__intro_p(v-if='hasAssetIncomes')
    | Any income gained from an asset like interest, dividends should be added in the Asset page.
  IncomeTable(v-if='hasAssetIncomes' :incomes='assetIncomes' :includeActions='false' :isAssetLiab='true')

  .heading-3.mgn-t-30(v-if='hasLiabilityIncomes')
    | Liability Incomes
  .money__intro_p(v-if='hasLiabilityIncomes')
    | Any income gained from a liability should be added in the Liability page.
  IncomeTable(v-if='hasLiabilityIncomes' :incomes='liabilityIncomes' :includeActions='false' :isAssetLiab='true')

  .heading-3.mgn-t-30
    | Total
  table.simple-table(v-if='hasIncomes')
    tr
      th &nbsp;
      th Week
      th Month
      th Year
    tr
      th TOTAL
      td {{ formatNumber(totalAnnual / 52,2) }}
      td {{ formatNumber(totalAnnual / 12,2) }}
      td {{ formatNumber(totalAnnual,2) }}

  .money-button-bar.pad-b-30
    button.action_button(v-if='showProgressBtn' @click='progress("/")')
      | I do not have incomes
    button.action_button(@click='addIncome')
      | Add new Income
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";
import IncomeTable from "@/incomes/incomeTable.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'incomes',
  data() {
    return {
      incomes: undefined,
      assetIncomes: undefined,
      liabilityIncomes: undefined,
      totalAnnual: 0
    }
  },
  computed: {
    hasIncomes: function () { return this.incomes != null && this.incomes.length > 0 },
    hasAssetIncomes: function () { return this.assetIncomes != null && this.assetIncomes.length > 0 },
    hasLiabilityIncomes: function () { return this.liabilityIncomes != null && this.liabilityIncomes.length > 0 },
    progressIncomes: function () { return (this.profile.progress & 4) > 0 },
    showProgressBtn: function () { return !this.progressIncomes && !this.hasIncomes },
  },
  methods: {
    addIncome() {
      this.progress('/addEditIncome')
    },
    done() {
      this.$router.push('/')
    },
    deleteIncome(income) {
      Swal.fire({
        title: "Delete Income",
        text: 'Are you sure you want to delete income '+income.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/incomes/"+income.id).then((response) => {
            this.loadIncomes()
          })
        }
      })
    },
    editIncome(income) {
      this.$router.push(`/addEditIncome/${income.id}`)
    },
    loadIncomes() {
      axios.get("/api/incomes").then((response) => {
        this.incomes = response.data.rows
        this.totalAnnual = response.data.total_annual
        this.assetIncomes = response.data.asset_rows
        this.liabilityIncomes = response.data.liability_rows
      });
    },
    progress(url) {
      axios.put("/user/progress?flag=3").then((response) => {
        const progress = response.data
        this.$store.commit('updateUserProgress', progress)
        this.$router.push(url)
      });
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Incomes',
      meta: [
        { name: 'description', content: 'A collection of incomes you get paid' },
      ],
    })
    this.loadIncomes()
  },
  components: {
    MoneyBar,
    IncomeTable
  }
}
</script>

<style lang="scss">
</style>