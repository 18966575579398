<template lang="pug">
.v-money-table
  h3.pad-t-10
    a(:href='url') {{ title }}
  .money-table-container
    table.simple-table.money-table
      thead
        tr
          th(v-for='header in headers')
            | {{ header }}
      tbody
        tr(v-for='row in rows')
          template(v-for='header,index in headers')
            td.date-cell(v-if="isDateCol(header)") {{row[index]}}
            td.number-cell(v-else-if="isNumberCol(header)") {{formatNumber(row[index])}}
            td(v-else-if="isFrequencyCol(header)") {{formatFrequency(row[index])}}
            td(v-else)
              | {{ row[index] }}
</template>

<script>
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  props: ['title','url','rows', 'headers'],
  name: 'moneyTable.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {
    isDateCol: function (header) { return header === 'Start Date' },
    isNumberCol: function (header) { return header === 'Amount' },
    isFrequencyCol: function (header) { return header === 'Frequency' }
  },
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>