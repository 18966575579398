<script>
import axios from 'axios';
import { clone} from "@/common/copies";
import CommonMixin from "@/components/commonMixin.vue";

const frequencies = [
  { value: 'oneoff', label: 'One Off' },
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'fortnightly', label: 'Fortnightly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: '6_months', label: '6 Months' },
  { value: 'yearly', label: 'Yearly' }
]

export default {
  mixins: [CommonMixin],
  name: 'fieldListsMixin',
  data() {
    return {
      frequencies: frequencies,
      dbAssets: [],
      dbLiabilities: []
    }
  },
  computed: {
      assetLiabilitiesWithSelf: function () {
        let als = [ {label: 'Self', value: 'self'}]
        return als
      },
  },
  methods: {
    assets: function (scenarioId = undefined) {
      let als = [{label: '', value: null}]
      for (let i = 0; i < this.dbAssets.length; i++) {
        if (this.isNull(scenarioId)) {
          if (this.isNull(this.dbAssets[i].scenario_id)) {
            als.push({label: this.dbAssets[i].name, value: this.dbAssets[i].id})
          }
        } else {
          if (this.isNull(this.dbAssets[i].scenario_id) || this.dbAssets[i].scenario_id.toString() === scenarioId.toString()) {
            als.push({label: this.dbAssets[i].name, value: this.dbAssets[i].id})
          }
        }
      }
      return als
    },
    assetsWithSelf: function (scenarioId = undefined) {
      let als = clone(this.assets(scenarioId))
      let self = {label: 'Self', value: 'self'}
      als.splice(1,0,self)
      return als
    },
    liabilities: function (scenarioId = undefined) {
      let als = [{label: '', value: null}]
      for (let i = 0; i < this.dbLiabilities.length; i++) {
        if (this.isNull(scenarioId)) {
          if (this.isNull(this.dbLiabilities[i].scenario_id)) {
            als.push({label: this.dbLiabilities[i].name, value: this.dbLiabilities[i].id})
          }
        } else {
          if (this.isNull(this.dbLiabilities[i].scenario_id) || this.dbLiabilities[i].scenario_id.toString() === scenarioId.toString()) {
            als.push({label: this.dbLiabilities[i].name, value: this.dbLiabilities[i].id})
          }
        }
      }
      return als
    },
    liabilitiesWithSelf: function (scenarioId = undefined) {
      let als = clone(this.liabilities(scenarioId))
      let self = {label: 'Self', value: 'self'}
      als.splice(1,0,self)
      return als
    },
    // Adds the deposit_in/withdraw_from asset/liab name
    populateDepWdrAssLiabName: function (money, scenarioId) {
      if (money.deposit_in_asset_id && !money.deposit_in_asset) {
        let ass = this.assets(scenarioId).find((x) => x.value === money.deposit_in_asset_id)
        money.deposit_in_asset = money.deposit_in_asset_id === 'self' ? 'self' : ass.label
      }
      if (money.deposit_in_liability_id && !money.deposit_in_liability) {
        let liab = this.liabilities(scenarioId).find((x) => x.value === money.deposit_in_liability_id)
        money.deposit_in_liability = money.deposit_in_liability_id === 'self' ? 'self' : liab.label
      }
      if (money.withdraw_from_asset_id && !money.withdraw_from_asset) {
        let ass = this.assets(scenarioId).find((x) => x.value === money.withdraw_from_asset_id)
        money.withdraw_from_asset = money.withdraw_from_asset_id === 'self' ? 'self' : ass.label
      }
      if (money.withdraw_from_liability_id && !money.withdraw_from_liability) {
        let ass = this.liabilities(scenarioId).find((x) => x.value === money.withdraw_from_liability_id)
        money.withdraw_from_liability = money.withdraw_from_liability_id === 'self' ? 'self' : ass.label
      }
    },
    assetName: function (asset_id) {
      let ass = this.dbAssets.find((a) => a.id === asset_id )
      return ass ? ass.name : ''
    },
    liabilityName: function (liability_id) {
      let liab = this.dbLiabilities.find((a) => a.id === liability_id )
      return liab ? liab.name : ''
    }
  },
  created: function () {
    axios.get("/api/assets/assets_liabilities").then((response) => {
      this.dbAssets = response.data['assets'];
      this.dbLiabilities = response.data['liabilities'];
    })
  },
  mounted: function () {
  },
}
</script>
