<template lang="pug">
.v-money-status
  .dashboard__status-panel
    .dashboard__status-item
      button(@click='assets')
        | {{ stats.assets }}
        br
        | Assets
    .dashboard__status-item
      button(@click='liabilities')
        | {{ stats.liabilities }}
        br
        | Liabilities
    .dashboard__status-item
      button(@click='incomes')
        | {{ stats.incomes }}
        br
        | Incomes
    .dashboard__status-item
      button(@click='expenses')
        | {{ stats.expenses }}
        br
        | Expenses
    .dashboard__status-item
      button(@click='transfers')
        | {{ stats.transfers }}
        br
        | Transfers
</template>

<script>
import axios from "axios";

export default {
  name: 'moneyStatus',
  data() {
    return {
      stats: {}
    }
  },
  computed: {},
  methods: {
    assets() {
      this.$router.push("Assets")
    },
    liabilities() {
      this.$router.push("Liabilities")
    },
    incomes() {
      this.$router.push("Incomes")
    },
    expenses() {
      this.$router.push("Expenses")
    },
    transfers() {
      this.$router.push("Transfers")
    },
    loadStats: function () {
      axios.get("/api/reports/money_stats").then((response) => {
        this.stats = response.data
      });
    }
  },
  mounted: function () {
    this.loadStats();
  },
  components: {}
}
</script>

<style lang="scss">
</style>