<template lang="pug">
.v-liabilities(v-if='isLoggedIn')
  .heading-1
    | Liabilities
  .liabilities__intro
    .money__intro_p
      | A liability refers to a financial obligation or debt that you have. Liabilities can take various forms, such as loans, accounts payable, accrued expenses, mortgages.
    .money__intro_p
      | Any expense lost from a liability like interest or fees should be added in the new liability page.
  table.simple-table(v-if='hasLiabilities')
    tr
      th Name
      th Start Date
      th End Date
      th Amount
      th Appreciation
      th Actions
    tr(v-for='liability in liabilities' :value='liability.name')
      td {{liability.name}}
      td.date-cell {{liability.start_date}}
      td.date-cell {{liability.end_date}}
      td.number-cell {{formatNumber(liability.amount)}}
      td.number-cell {{liability.appreciation}}
      td
        a(href='#' @click.prevent='editLiability(liability)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteLiability(liability)') Delete

  .money-button-bar.mgn-b-30
    button.action_button(v-if='showProgressBtn' @click='progress("/")')
      | I do not have liabilities
    button.action_button(@click='addLiability')
      | Add new Liability
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'liabilities',
  data() {
    return {
      liabilities: undefined
    }
  },
  computed: {
    hasLiabilities: function () {
      return this.liabilities != null && this.liabilities.length > 0
    },
    progressLiabilities: function () { return (this.profile.progress & 2) > 0 },
    showProgressBtn: function () { return !this.progressLiabilities && !this.hasLiabilities },
  },
  methods: {
    addLiability() {
      this.progress('/addEditLiability')
    },
    done() {
      this.$router.push('/')
    },
    deleteLiability(liability) {
      Swal.fire({
        title: "Delete Liability",
        text: 'Are you sure you want to delete liability '+liability.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/liabilities/"+liability.id).then((response) => {
            this.loadLiabilities()
          })
        }
      })
    },
    editLiability(liability) {
      this.$router.push(`/addEditLiability/${liability.id}`)
    },
    loadLiabilities() {
      axios.get("/api/liabilities").then((response) => {
        this.liabilities = response.data
      });
    },
    progress(url) {
      axios.put("/user/progress?flag=2").then((response) => {
        const progress = response.data
        this.$store.commit('updateUserProgress', progress)
        this.$router.push(url)
      });
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Liabilities',
      meta: [
        { name: 'description', content: 'A collection of liabilities you own' },
      ],
    })
    this.loadLiabilities()
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>