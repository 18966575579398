<template lang="pug">
.v-getting-started
  .learn-container
    .learn-h1 Getting Started
    .learn-text
      | Fyffle is all about helping you make the best decision today about your financial choices
      | by looking at your current financial status and see what happens if you change it in the
      | future. Everyone's situation is different, so it is difficult to make general statements
      | about what the best choice is. This is where fyffle can help. Fyffle allows you to enter
      | your situation.

    .learn-text
      | This guide will cover the following actions:
      ul
        li
          a(href='#assets') Entering your Assets
        li
          a(href='#liabilities') Entering your Liabilities
        li
          a(href='#incomes') Entering your Incomes
        li
          a(href='#expenses') Entering your Expenses
        li
          a(href='#transfers') Entering your Bank Transfers
        li
          a(href='#scenarios') Creating Scenarios
        li
          a(href='#reports') Generating Reports

    a(name='assets')
      .learn-h2 Assets
    .learn-text
      | The best place to start is with your <b>bank accounts</b>. You do not need to add every bank account
      | you have, just the ones you mainly work with.
    .learn-text
      | On the Home page, click on the <b>Asset</b> button.
    .img-container
      img.img-30(src='/images/learn/getting_started/home_asset_btn.png')
    .learn-text
      | Then click <b>Add new Asset</b>.
      | Enter the current amount of your bank account in the
      | <b>Amount</b> field. In our example we will call it Savings Account and it has a balance of $5,000.
      | For the <b>Start Date</b> you could just start with today's date and balance, or you can use a past date and balance.
    .img-container
      img.img-75(src='/images/learn/getting_started/add_asset.png')
    .learn-text
      | Find out if your bank account has an interest associated
      | with it, and add that to the <b>Incomes</b> section in the Asset page.
      | We are only interested in the Income associated with this Asset, other income like your salary
      | will be entered later.
      | Under Assets Incomes, Press the <b>+</b> button
      | to create a new Asset Income. Be sure to enter the interest
      | rate in the <b>Annual Interest</b> field. In the <b>Frequency Paid</b> field, select how often
      | the interest amount is deposited in your bank account.
    .img-container
      img.img-90(src='/images/learn/getting_started/asset_income.png')
    .learn-text
      | Press the <b>Add Income</b> button when done. You will see the Asset Income in a table.
    .img-container
      img.img-90(src='/images/learn/getting_started/asset_income_table.png')
    .learn-text
      | If your bank account has fees, add those to the <b>Expense</b> part of the Asset.
      | Under Asset Expenses, Press the <b>+</b> button to create a new Asset Expense.
      | We are only interested in the Expenses associated with this Asset, other expenses like your bills
      | will be entered later.
      | In our example there is a $20 fee taken out of the bank account every month.
    .img-container
      img.img-90(src='/images/learn/getting_started/asset_expense.png')
    .learn-text
      | Press the <b>Add Expense</b> button when done. You will see the Asset Expense in a table.
    .img-container
      img.img-90(src='/images/learn/getting_started/asset_expenses_table.png')
    .learn-text
      | Once you have entered all the Asset income and expenses, press the <b>Add Asset</b> button.
      | You will be brought to the Assets page, and you will see your new asset in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/assets_table.png')
    .learn-text
      | Now repeat the same process of entering assets for other bank accounts you have. Also any houses
      | you own or other types of assets like cars, superannuation, shares, etc.

    a(name='liabilities')
      .learn-h2 Liabilities
    .learn-text
      | Liabilities is the next section we will enter. Liabilities are similar to Assets, but they
      | represent money you owe, for example like mortgage to a bank.
    .learn-text
      | In our example we will enter a liability for a mortgage of a bank.
      | On the Home page, click on the <b>Liability</b> button. Then click <b>Add new Liability</b>.
      | In our example the mortgage loan is for $500,000 starting on the 1 Jan 2024 with an interest
      | rate of 6.5%.
    .img-container
      img.img-75(src='/images/learn/getting_started/add_liability.png')
    .learn-text
      | Typically mortgages will not have any incomes, but they have an expense component. This will
      | be the interest you pay each month.
      | Press the <b>+</b> button under Liability Expenses.
      | Enter the <b>Annual Interest</b> to be 6.5. And the interest needs to be paid monthly. Set the
    .img-container
      img.img-75(src='/images/learn/getting_started/liability_expense.png')
    .learn-text
      | Dont worry about paying the mortgage at this stage, we will do that later with bank transfers.
      | Press the <b>Add Expense</b> button when done. You will see the Liability Expense in a table.
    .learn-text
      | Once you have entered all the Liability income and expenses, press the <b>Add Liability</b> button.
      | You will be brought to the Liabilities page, and you will see your new liability in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/liabilities_table.png')

    a(name='incomes')
      .learn-h2 Incomes
    .learn-text
      | Incomes is the next section we will enter. Income is any money you earn from salaries, tax returns,
      | businesses, etc. Keep in mind that if the income is related to an asset, like a rental income
      | or dividends, you can add that in the Asset Income section, or if you prefer, you could add here in Incomes instead.
    .learn-text
      | On the Home page, click on the <b>Income</b> button. Then click <b>Add new Income</b>.
    .img-container
      img.img-90(src='/images/learn/getting_started/incomes.png')
    .learn-text
      | In our example we will add our salary. Lets say we earn $1,000 per week, and we will deposit
      | the money in our Savings Account, and we get paid on Fridays. We will also add an inflation amount
      | which assumes every year our wage increase by 3%. This is only a rough estimate.
    .img-container
      img.img-75(src='/images/learn/getting_started/add_income.png')
    .learn-text
      | Press the <b>Save Income</b> button.
      | You will be brought to the Incomes page, and you will see your new income in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/incomes_table.png')

    a(name='expenses')
      .learn-h2 Expenses
    .learn-text
      | We will look at Expenses next. You can be as general or as specific as you want here. Remember
      | these reports should only give you a rough estimate of a best scenario, since we cannot predict
      | the future.
      | For example you may just want to add general expenses like Groceries, Rent, Fuel, Bills, Other.
      | Or you can enter every expense in fine detail.
      | In our example we will add some groceries of $150 per week and include inflation of 3% and I
      | go shopping on Wednesdays. I use my savings account for my shopping.
    .img-container
      img.img-75(src='/images/learn/getting_started/add_expense.png')
    .learn-text
      | Press the <b>Save Expense</b> button.
      | You will be brought to the Expenses page, and you will see your new expense in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/expenses_table.png')

    a(name='transfers')
      .learn-h2 Bank Transfers
    .learn-text
      | Finally we are going to look at bank transfers.
      | Bank transfers is moving money from one of your bank accounts to another of your bank accounts.
      | This is where we can pay our mortgage off.
      | In this example the mortgage repayments are $3,160, but we will pay a little extra each month.
      | Starting on the 1 Feb 2024 we will pay $3,500 each month from the Savings Account into the
      | Loan.
    .img-container
      img.img-75(src='/images/learn/getting_started/add_transfer.png')
    .learn-text
      | Press the <b>Save Transfer</b> button.
      | You will be brought to the Banking Transfers page, and you will see your new transfer in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/transfers_table.png')

    .learn-h2 Financial Data
    .learn-text
      | Well done, you have entered all your current financial data, you are most of the way there now.
      | If you look on the Home page, will should see a value in each of the 5 boxes.
    .img-container
      img.img-90(src='/images/learn/getting_started/financial_position_buttons.png')

    a(name='scenarios')
      .learn-h2 Scenarios
    .learn-text
      | Now the fun begins!
      | The idea with scenarios is a financial "what if I did this, could I afford it", or what is the
      | best financial decision. For example what if I bought a new car, or house could I afford it.
      | Would I save money if paid my mortgage weekly rather than monthly. You can be as creative
      | as you want.
      | In our example we will create a scenario where we pay our mortgage off weekly rather than monthly
      | and see if that saves us any money.
    .learn-text
      | From the home page click the <b>Scenarios</b> button. Then click the <b>Add new Scenario</b>.
      | First we will create a Scenario where we do nothing. Which means we do not make any changes
      | to for a comparison to other scenarios.
    .img-container
      img.img-50(src='/images/learn/getting_started/scenario_do_nothing.png')
    .learn-text
      | Go to the bottom of the page, click the <b>Save</b> button.
    .learn-text
      | Click the <b>Add new Scenario</b> button again.
      | We will call this Scenario "Weekly Mortgage Payments"
    .learn-text
      | Under the <b>Transfer</b> heading find the <b>Mortgage Repayments</b> transfer.
      | Click the <b>Change</b> link in the table.
    .img-container
      img.img-90(src='/images/learn/getting_started/scenario_transfers.png')
    .learn-text
      | Here we want to change the existing transfer so it no longer is transferring the money, so we
      | will enter a value of 0 and make it start on the 1 June.
    .img-container
      img.img-75(src='/images/learn/getting_started/scenario_add_transfer.png')
    .learn-text
      | Now Click the <b>Add Change</b>
      | You will see a green entry in the Transfers table.
    .img-container
      img.img-90(src='/images/learn/getting_started/scenario_transfer_change.png')
    .learn-text
      | Now that we have stopped the monthly transfer on the 1 Jun, we want to start the weekly
      | transfer on the 1 Jun.
      | Click the <b>Add Transfer</b> button
      | We are now going to send $808 per week from the Savings Account to the Mortgage.
    .img-container
      img.img-75(src='/images/learn/getting_started/scenario_add_transfer_form.png')
    .learn-text
      | Click the <b>Save Transfer</b> button
      | You should now see another green row in the Transfer table.
    .img-container
      img.img-90(src='/images/learn/getting_started/scenario_transfers_table2.png')
    .learn-text
      | To complete the Scenario, click the <b>Save</b> button at the bottom.
      | You should now see the the new Scenario in the table.
    .img-container
      img.img-50(src='/images/learn/getting_started/scenarios_table.png')

    a(name='reports')
      .learn-h2 Reports
    .learn-text
      | We are now ready to run reports to see how our 2 scenarios compare.
      | In the Scenarios page click the <b>Report</b> button.
      | We will make the report start on 1 Jan 2024, so set the <b>Start Date</b> for that.
    .img-container
      img.img-50(src='/images/learn/getting_started/report_start.png')
    .learn-text
      | Now Click <b>Generate Report</b>. You should soon see a 5 year report generated below. If any of the Asset balances fall below zero, a warning will be shown beneath the report. Also you can download a detailed daily report for each scenario at the bottom of the page under the <b>Download Detailed Reports</b> section.
    .img-container.mgn-b-30
      img.img-50(src='/images/learn/getting_started/report.png')
</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: 'gettingStarted.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
    useHead({
      title: 'Fyffle Getting Started Guide',
      meta: [
        { name: 'description', content: 'This guide helps beginners quickly get started with entering their financial data, creating scenarios, and finally generating a report.'},
      ],
    })
  },
  components: {
  }
}
</script>

<style lang="scss">
</style>