<template lang="pug">
div(:class="{ 'body--loggedin': isLoggedIn, 'body--dashboard': isDashboard }")
  .v-app
    navbar
    router-view
    BottomBar
</template>

<script>
import Navbar from "./navbar.vue";
import '../assets/stylesheets/main.scss'
import BottomBar from "@/pages/bottomBar.vue";

export default {
  computed: {
    profile: function() { return this.$store.state.profile },
    isLoggedIn: function () { return this.profile !== undefined && this.profile !== null && this.profile !== '' },
    isDashboard: function () { return this.$route.name === 'Home' && this.isLoggedIn },
  },

  created: function() {
    return this.$store.dispatch('fetchProfile')
  },
  components: {
    Navbar,
    BottomBar
  }
}
</script>

<style lang="scss">
.background {
  height: 100vh;
  width: 100vw;
  background-color: #ffffff;
}

.otherColor {
  background-color: #d4d4d4; // whatever background color you want.
}
</style>
