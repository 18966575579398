<template lang="pug">
.v-week-panel
  .dashboard-panel__heading
    span What is happening this week?
    span
      button.dashboard-panel__more-button(@click='gotoReport') More
  .week-panel-row
    .week-day-div(v-for='day,index in weekDays()' :class='weekDayClass(index)')
      .week-day-date(v-html='day')
      .event-items(v-if='weekEvents')
        .event-item(v-for='evt in weekEvents[index].ev')
          .event-container(:class='eventClass(evt)')
            .event-row1
              span {{ evt['name']}}
              span {{ formatCurrency(evt['amount']) }}
            .event-row2 {{ eventRow2(evt) }}
</template>

<script>
import moment from "moment";
import axios from "axios";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  name: 'weekPanel.vue',
  data() {
    return {
      startDate: undefined,
      weekEvents: undefined
    }
  },
  computed: {
  },
  methods: {
    wkEvents: function (index) {
      return this.weekEvents ? this.weekEvents[index].ev : [];
    },
    weekDays: function () {
      var days = []
      if (this.startDate === undefined) { return days }
      var currDay = moment(this.startDate)
      for (var i = 1; i <= 7; i++) {
        days.push(currDay.format('MMM D') + "<br>" + currDay.format('dddd'));
        currDay = currDay.add(1, 'day');
      }
      return days;
    },
    dayEvents: function (day) {
      if (this.weekEvents === undefined) {
        return []
      }
      return this.weekEvents[day].ev
    },
    eventClass: function (event) {
      if (event === undefined) return '';
      let type = event.type
      return "money-event event-"+type
    },
    weekDayClass: function (index) {
      let currDay = moment(this.startDate).add(index, 'day')
      return "week-day-"+currDay.format('ddd');
    },
    eventRow2: function (evt) {
      if (evt.type === 'transfer') {
        return (evt.from_asset || evt.from_liability) + " -> " + (evt.to_asset || evt.to_liability)
      } else {
        return evt.asset || evt.liability
      }
    },
    gotoReport: function () {
      this.$router.push("/report/calendar")
    }
  },
  mounted: function () {
    axios.get("/api/reports/week_events").then((response) => {
      this.startDate = response.data.start_date;
      this.weekEvents = response.data.data;
    })

  },
  components: {}
}
</script>

<style lang="scss">
.v-week-panel {
}

@media only screen and (min-width: 760px) {

}

</style>