<template lang="pug">
.v-savings-report(v-if='isLoggedIn')
  .heading-1
    | Savings Report

  .report_div.pad-t-10(v-if='chartData' )
    p
      | This chart will show you the amount of savings you make over time. This will be the <b>sum of the balances</b> of the assets you choose.
    p.pad-t-20
      | This report depends on the
      a(href='/report/future-forecast-report')  Future Forecast Report.
    p.pad-t-20
      | Select the Assets you use for savings.
    .report-asset-row.mgn-l-30(v-for='asset in dbAssets' :key='asset.id')
      input(type='checkbox' v-model='selAsset[asset.id]' @change='assetSelected(asset)')
      div.pad-l-10 {{ asset.name }}
  .report_div.pad-t-10(v-if='chartData' )
    .chart-container
      Line(:data='chartData' :options='chartOptions')

  .summary_div(v-if='tableData')
    div.pad-t-50
      | The table shows <b>all income minus all expenses</b> you make each month from all money.
    table.simple-table.mgn-b-50
      template(v-for="row,row_index in tableData['rows']")
        thead
          tr(v-if='row_index==0')
            th(v-for="item in row")
              | {{item}}
        tbody
          tr(v-if='row_index > 0')
            td(v-for="item,cell_index in row" :class="{'item-cell' : cell_index==0, 'number-cell' : cell_index > 0, 'negative-cell' : isNegative(cell_index, item) }")
              template(v-if="cell_index == 0")
                | {{item}}
              template(v-else)
                | {{formatDollars(item)}}

  .report_div.pad-t-10(v-if='!chartData' )
    p
      | You must generate the Future Forecast report before you can see this report.

  MoneyBar
</template>

<script>

import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const cdata = {
  "labels": [],
  "datasets":[]
}

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'savingsReport.vue',
  data() {
    return {
      selAsset: {},
      dbAssets: [],
      chartData: cdata,
      tableData: [],
      scenarios: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right'
          }
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
          }
        }
      }
    }
  },
  computed: {
    selAssetIds: function () { return Object.keys(this.selAsset).filter( (f) => this.selAsset[f] === true ) }
  },
  methods: {
    loadChart: function () {
      axios.get("/api/reports/savings_chart_data?asset_ids="+this.selAssetIds).then((response) => {
        this.chartData = response.data
      })
    },
    loadTable: function () {
      axios.get("/api/reports/savings_table_data").then((response) => {
        this.tableData = response.data['month_data']
        this.scenarios = response.data['scenarios']
        this.errorMessage = response.data['errors']
      })
    },
    assetSelected: function (asset) {
      this.loadChart()
    },
    isNegative: function (cell_index, item) {
      if (cell_index === 0) {
        return false
      }
      return (item < 0);
    },

  },
  mounted: function () {
    this.loadChart()
    this.loadTable()
    axios.get("/api/assets/assets_liabilities").then((response) => {
      this.dbAssets = response.data['assets'];
    })
  },
  components: {
    Line,
    MoneyBar
  }
}
</script>

<style lang="scss">
.v-savings-report {
  .chart-container {
    height: 400px;
  }
  .report-asset-row {
    display: flex;
  }
}
</style>