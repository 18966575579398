<template lang="pug">
.v-income-table
  table.simple-table
    tr
      th Name
      th Start Date
      th End Date
      th Amount
      th(v-if='isAssetLiab') Interest
      th Frequency
      th Deposit in Asset
      th Deposit in Liability
      th Changes
      th(v-if='includeActions') Actions
    tr(v-for='income in incomes' :value='income.name')
      td {{income.name}}
      td.date-cell {{income.start_date}}
      td.date-cell {{income.end_date}}
      td.number-cell {{formatNumber(income.amount)}}
      td.number-cell(v-if='isAssetLiab') {{formatNumber(income.interest)}}
      td {{formatFrequency(income.frequency)}}
      td {{income.deposit_in_asset}}
      td {{income.deposit_in_liability}}
      td {{changes(income)}}
      td(v-if='includeActions')
        a(href='#' @click.prevent='editIncome(income)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteIncome(income)') Delete

</template>

<script>
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  props: ['incomes', 'includeActions','isAssetLiab'],
  name: 'incomeTable.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {
    editIncome: function (income) {
      this.$emit('edit', income)
    },
    deleteIncome: function (income) {
      this.$emit('delete', income)
    },
    changes(income) {
      let changeStr = []
      income.money_changes.forEach(item => {
        changeStr.push(item.start_date+": "+(item.money_changes.amount || item.money_changes.interest) )
      })
      return changeStr.join(", ")
    },

  },
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>