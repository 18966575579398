<template lang="pug">
.v-navbar
  nav.navbar-nav.nav-loggedout(v-if='!profile')
    router-link.nav-link.active(to="/" aria-current='page') Home
    router-link.nav-link(to='/learn') Learn
    span.flex-grow &nbsp;
    .btn.btn-secondary.pad-b-2
      img(src='/images/nav_logo.png' height='26px')
    span.flex-grow &nbsp;
    a.nav-link(href="/users/sign_in") Login
    a.nav-link.btn.btn-primary(href="/users/sign_up") Register

  nav.navbar-nav.nav-loggedin(v-if='profile')
    .btn.btn-secondary.pad-b-2
      img(src='/images/nav_logo.png' height='22px')
    router-link.nav-link.active(to="/" aria-current='page') Dashboard
    dropdown-menu.dropdown-navbar.dropdown-navbar--data(:overlay="false" direction="right")
      template('#trigger')
        .nav-link
          | Data
          font-awesome.arrow-icon(icon="chevron-down")
      template('#body')
        ul
          li
            a.nav-link(href="/assets") Assets
        ul
          li
            a.nav-link(href="/liabilities") Liabilities
        ul
          li
            a.nav-link(href="/incomes") Incomes
        ul
          li
            a.nav-link(href="/expenses") Expenses
        ul
          li
            a.nav-link(href="/transfers") Transfers
        ul
          li
            a.nav-link(href="/scenarios") Scenarios
    router-link.nav-link(to='/reports') Reports
    router-link.nav-link(to='/learn') Learn
    // router-link.nav-link(to='/feedback') Feedback
    router-link.nav-link(v-if='admin' to='/admin/dashboard') Admin
    span.flex-grow &nbsp;
    dropdown-menu.dropdown-navbar.dropdown-navbar--user(:overlay="false" direction="right")
      template('#trigger')
        .nav-link
          font-awesome.user-icon(icon="circle-user")
          | {{ profileName }}
          font-awesome.arrow-icon(icon="chevron-down")
      template('#body')
        ul
          li
            a.nav-link(href="/user_profile") Profile
        ul
          li
            a.nav-link(href="/users/sign_out" data-method='delete' rel="nofollow" id='logoutBtn') Logout
</template>

<script>
import DropdownMenu from 'v-dropdown-menu'
import 'v-dropdown-menu/css'

export default {
  computed: {
    profile: function() { return this.$store.state.profile },
    admin: function() { return this.profile && this.profile.admin },
    profileName: function () { return this.profile.name }
  },
  mounted: function () {
  },
  components: {
    DropdownMenu
  }
}
</script>

<style lang="scss">
</style>