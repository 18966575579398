<template lang="pug">
.v-add-edit-form
  .form__panel
    .form__row(v-for='field in visibleFields' :value='field.name')
      .form__row--fields
        .form__header
          span(:class="{'disabled-field' : field.disabled}") {{ field.label }}
          span.form__input--mandatory(v-if='field.mandatory && !field.oneOfTwoMandatoryField')
            |  *
          span.form__input--mandatory__one-of-two(v-if='field.oneOfTwoMandatoryField')
            |  +
          span.pad-l-5(v-if='field.help_text')
            font-awesome.help-icon(icon="fa-solid fa-info-circle" v-tooltip="field.help_text" :class="{'disabled-field' : field.disabled}")
        .form__input
          template(v-if="field.type == 'string'")
            input(type='text' v-model='model[field.name]' @change='validateData(field)' :ref="refName()")
          template(v-if="field.type == 'text'")
            textarea(type='text' v-model="model[field.name]" maxlength='1000' @change='validateData(field)' :ref="refName()")
          template(v-if="field.type == 'number'")
            input(type='number' v-model='model[field.name]' @change='validateData(field)' :ref="refName()")
          template(v-if="field.type == 'list'")
            select(v-model='model[field.name]' @change='validateData(field)' :ref="refName()")
              option(v-for='item in field.list_items' :value='item.value')
                | {{ item.label }}
          template(v-if="field.type == 'boolean'")
            input.width-auto(type='checkbox' v-model='model[field.name]' @change='validateData(field)' :ref="refName()")
          .form__input--100(v-if="field.type == 'date'")
            VueDatePicker(v-model='model[field.name]' :enableTimePicker="false" @internal-model-change='validateData(field)' :format='datePickerFormatDate')
      .error_text(v-if='showErrors && errors[field.name]') {{ errors[field.name] }}
    slot
  .form__panel
    .form__buttons.mgn-b-30
      button.cancel_button(@click='cancel') Cancel
      button.action_button(v-if="adding" @click='addData' :disabled='disableSave') {{ addText || 'Add' }}
      button.action_button(v-if="!adding" @click='editData' :disabled='disableSave') Save
</template>

<script>
import {clone} from "@/common/copies";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  props: { fields: Array, fieldValues: Object, adding: Boolean, addText: String, oneOfTwoMandatoryFields: Array, changes: Array, disableSave: Boolean, focusName: String },
  name: 'addEditForm',
  data() {
    return {
      model: {},
      errors: {},
      showErrors: false,
      visibleFields: []
    }
  },
  computed: { },
  methods: {
    addData: function () {
      this.showErrors = true;
      let valid = this.validateData(undefined);
      if (valid) {
        this.$emit('dataAdded', this.model)
      }
    },
    cancel: function () {
      this.$emit('cancelled', this.model)
    },
    editData: function () {
      this.showErrors = true;
      let valid = this.validateData(undefined);
      if (valid) {
        this.$emit('dataEdited', this.model)
      }
    },
    setDefaultValues: function () {
      for (var index in this.fields) {
        let field = this.fields[index];
        this.model[field.name] = field.default_value;
      }
    },
    validateData: function (editedField) {
      let valid = true;
      for (var index in this.fields) {
        let field = this.fields[index];
        this.errors[field.name] = null;
        if (field.mandatory && (this.isNull(this.model[field.name]))) {
          this.errors[field.name] = "You must set a value for " + field.label + "."
          valid = false;
        }
        // Will be an array of array of two values
        if (this.oneOfTwoMandatoryFields) {
          for (let i = 0; i < this.oneOfTwoMandatoryFields.length; i++) {
            let mfield1 = this.oneOfTwoMandatoryFields[i][0]
            let mfield2 = this.oneOfTwoMandatoryFields[i][1]
            if (this.isNull(this.model[mfield1]) && this.isNull(this.model[mfield2])) {
              this.errors[mfield1] = "You must set a value for either " + mfield1 + " or " + mfield2 + "."
              valid = false;
            }
            if (!this.isNull(this.model[mfield1]) && !this.isNull(this.model[mfield2])) {
              this.errors[mfield1] = "You cannot set a value for both " + mfield1 + " and " + mfield2 + ", enter a value for only one."
              valid = false;
            }
          }
        }
      }

      if (editedField) {
        this.$emit('valueChange', {field: editedField, value: this.model[editedField.name]});

        // If this field is linked with another, it means there can only be one value.
        // So we will clear and disable the other value
        if (this.isOneOfTwoMandatoryFields(editedField)) {
          this.clearOtherField(editedField)
        }
      }

      return  valid;
    },
    isOneOfTwoMandatoryFields: function (field) {
      if (!this.oneOfTwoMandatoryFields || field === undefined) {
        return false;
      }
      for (let i = 0; i < this.oneOfTwoMandatoryFields.length; i++) {
        let mfield1 = this.oneOfTwoMandatoryFields[i][0]
        let mfield2 = this.oneOfTwoMandatoryFields[i][1]
        if (mfield1 === field.name || mfield2 === field.name) {
          return true;
        }
      }
      return false;
    },
    clearOtherField: function (field) {
      if (field === undefined) {
        return;
      }
      let otherField = undefined
      for (let i = 0; i < this.oneOfTwoMandatoryFields.length; i++) {
        let mfield1 = this.oneOfTwoMandatoryFields[i][0]
        let mfield2 = this.oneOfTwoMandatoryFields[i][1]
        if (field.name === mfield1) {
          otherField = this.findField(mfield2)
          break;
        }
        if (field.name === mfield2) {
          otherField = this.findField(mfield1)
          break;
        }
      }
      // clear value
      if (!this.isNull(this.model[otherField.name])) {
        this.model[otherField.name] = undefined
        this.validateData()
      }
      // disable
      otherField.disabled = true
      field.disabled = undefined
    },
    findField: function (name) {
      return this.fields.find( (f) => f.name === name)
    },
    datePickerFormatDate: function (date) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    },
    refName: function () {
      return "input_"+(this.focusName ? this.focusName : '');
    }
  },
  mounted: function () {
    this.visibleFields = this.fields.filter( (f) => f.editable != false )
    if (this.adding) {
      this.setDefaultValues();
    }
    this.$nextTick(() => {
      if (!this.isNull(this.focusName) && this.$refs && this.$refs[this.refName()] && this.$refs[this.refName()][0]) {
        this.$refs[this.refName()][0].focus()
      }
    })
  },
  components: {},
  watch: {
    "model" : function () {
      this.validateData();
    },
    "fields" : function () {
      this.visibleFields = this.fields.filter( (f) => f.editable != false )
    },
    "fieldValues" : function () {
      this.model = clone(this.fieldValues)
    },
    "changes" : function () {
      this.model.money_changes = this.changes
    }
  }
}
</script>

<style lang="scss">
</style>