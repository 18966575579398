<template lang="pug">
.v-add-edit-income-expense(v-if='isLoggedIn')
  .form__panel
    .heading-1
      | {{addEditStr}} {{ incomeExpenseStr }}{{scenarioText}}
    AddEditForm(:fields='fields' :fieldValues='fieldValues' :adding='addingIncome' :oneOfTwoMandatoryFields='oneOfTwoMandatoryFields' @dataAdded="incomeExpenseAdded" @dataEdited="incomeExpenseEdited" @cancelled="cancelled" :addText='addText' :changes='newMoneyChanges' focusName="Income")
      ChangesForm(:fields='changeFields' :fieldValues='changeFieldValues' :moneyType='changeMoneyType' @postChanges='postIncomeExpenseChanges')
  MoneyBar
</template>

<script>
import AddEditForm from "../components/addEditForm.vue"
import {clone} from "@/common/copies";
import FieldListsMixin from "@/components/fieldListsMixin.vue";
import ChangesForm from "@/components/changesForm.vue";
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [FieldListsMixin, PermissionsMixin],
  props: {money: Object},
  name: 'addEditIncomeExpense.vue',
  data() {
    return {
      newMoneyChanges: [],
      depositOneOfTwoMandatoryFields: [['deposit_in_asset_id','deposit_in_liability_id']],
      withdrawOneOfTwoMandatoryFields: [['withdraw_from_asset_id','withdraw_from_liability_id']],
      fieldValues: {},
      changeFieldValues: [],
      incomeFields: [],
      expenseFields: [],
      changeFields: [],
    }
  },
  computed: {
    isIncome: function () { return this.$route.name === 'EditIncome' || this.$route.name === 'AddIncome'},
    isExpense: function () { return this.$route.name === 'EditExpense' || this.$route.name === 'AddExpense'},
    incomeId: function () { return this.$route.params.id },
    addingIncome: function () { return (this.incomeId === undefined) },
    addEditStr: function () { return (this.addingIncome ? "Add" : "Edit") },
    addText: function () { return "Save "+ this.incomeExpenseStr },
    incomeExpenseStr: function () { return this.isIncome ? "Income" : "Expense" },
    incomeExpenseStrLower: function () { return this.incomeExpenseStr.toLowerCase() },
    scenarioId: function () { let n = this.$route.query.scenario_id; return n ? Number(n) : undefined },
    scenarioText: function () { return this.scenarioId ? " for Scenario" : "" },
    changeMoneyType: function () { return this.incomeExpenseStr},
    fields: function() { return this.isIncome ? this.incomeFields : this.expenseFields },
    oneOfTwoMandatoryFields: function () { return this.isIncome ? this.depositOneOfTwoMandatoryFields : this.withdrawOneOfTwoMandatoryFields },
    baseUrl: function () { return "/api/"+this.incomeExpenseStrLower+"s"},
  },
  methods: {
    returnPageUrl: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.incomeExpenseStr+"_"+id : "/"+this.incomeExpenseStrLower+"s"
    },
    prepareData: function (model) {
      let mcs = this.newMoneyChanges
      let data = {}
      data[this.incomeExpenseStrLower] = model
      data[this.incomeExpenseStrLower]['money_changes'] = mcs
      data['money_changes'] = mcs
      if (this.scenarioId) {
        data[this.incomeExpenseStrLower]['scenario_id'] = this.scenarioId
        // All the money changes will include the scenario_id
        for (var index in mcs) {
          mcs[index]['scenario_id'] = this.scenarioId
        }
      }
      return data
    },
    incomeExpenseAdded: function (model) {
      axios.post(this.baseUrl, this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    incomeExpenseEdited: function (model) {
      axios.put(this.baseUrl+"/"+this.incomeId,this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrl(this.incomeId || ''))
    },
    loadIncomeExpense: function (incomeId) {
      axios.get(this.baseUrl + "/" + incomeId).then((response) => {
        let income = response.data
        this.fieldValues = {
          // id: assetId,
          name: income['name'],
          start_date: income['start_date'],
          end_date: income['end_date'],
          amount: income['amount'],
          frequency: income['frequency'],
          deposit_in_asset_id: income['deposit_in_asset_id'],
          deposit_in_liability_id: income['deposit_in_liability_id'],
          withdraw_from_asset_id: income['withdraw_from_asset_id'],
          withdraw_from_liability_id: income['withdraw_from_liability_id'],
          description: income['description'],
          inflation_rate: income['inflation_rate']
        }
        this.changeFieldValues = income['money_changes']
        this.newMoneyChanges = []
        for (let i = 0; i < this.changeFieldValues.length; i++) {
          this.newMoneyChanges.push(this.changeFieldValues[i].money_changes)
        }
      });
    },
    postIncomeExpenseChanges: function (data) {
      this.newMoneyChanges = clone(data)
    },
    setFieldValues: function () {
      this.incomeFields =
        [
          {name: 'name', label: 'Name', type: 'string', mandatory: true},
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date you first get paid the income"},
          {name: 'end_date', label: 'End Date', type: 'date', help_text: "The date the income stops. Leave empty if it doesnt"},
          {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "Amount is the fixed amount of the income"},
          {name: 'frequency', label: 'Frequency', type: 'list', mandatory: true, list_items: this.frequencies, help_text: "How often will the income be paid into the account"},
          {name: 'deposit_in_asset_id', label: 'Deposit in Asset', type: 'list', list_items: this.assets(this.scenarioId), help_text: "Select the Asset account the income will be paid in to.", oneOfTwoMandatoryField: true},
          {name: 'deposit_in_liability_id', label: 'Deposit in Liability', type: 'list', list_items: this.liabilities(this.scenarioId), help_text: "Select the Liability account the income will be paid in to.", oneOfTwoMandatoryField: true},
          {name: 'changes', label: 'Changes', type: 'string', editable: false},
          {name: 'description', label: 'Description', type: 'text'},
          {name: 'inflation_rate', label: 'Inflation Rate', type: 'number', help_text: "The percentage your country's inflation changes per year, eg 2%. This will change the income amount each year based on the inflation amount."},
          // {name: 'inflation_frequency', label: 'Inflation Frequency', type: 'list', list_items: this.frequencies}
        ]
      this.expenseFields =
        [
          {name: 'name', label: 'Name', type: 'string', mandatory: true},
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the expense is first taken from your account"},
          {name: 'end_date', label: 'End Date', type: 'date', help_text: "The date the expense stops. Leave empty if it doesnt"},
          {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "Amount is the fixed amount of the expense"},
          {name: 'frequency', label: 'Frequency', type: 'list', mandatory: true, list_items: this.frequencies, help_text: "How often will the expense be taken from the account"},
          {name: 'withdraw_from_asset_id', label: 'Withdraw from Asset', type: 'list', list_items: this.assets(this.scenarioId), help_text: "Select the Asset account the expense will be taken from.", oneOfTwoMandatoryField: true},
          {name: 'withdraw_from_liability_id', label: 'Withdraw from Liability', type: 'list', list_items: this.liabilities(this.scenarioId), help_text: "Select the Liability account the expense will be taken from.", oneOfTwoMandatoryField: true},
          {name: 'changes', label: 'Changes', type: 'string', editable: false},
          {name: 'description', label: 'Description', type: 'text'},
          {name: 'inflation_rate', label: 'Inflation Rate', type: 'number', help_text: "The percentage your country's inflation changes per year, eg 2%. This will change the expense amount each year based on the inflation amount."},
          // {name: 'inflation_frequency', label: 'Inflation Frequency', type: 'list', list_items: this.frequencies}
        ]
      this.changeFields =
        [
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the amount will change"},
          {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new amount value"},
        ]
    }
  },
  mounted: function () {
    if (this.incomeId !== undefined) {
      this.loadIncomeExpense(this.incomeId)
    }
    this.setFieldValues();
  },
  components: {
    AddEditForm,
    ChangesForm,
    MoneyBar
  },
  watch: {
    "monies": function () {
      this.loadMonies();
    },
    "dbLiabilities": function () {
      this.setFieldValues();
    }
  }
}
</script>

<style lang="scss">
</style>