<template lang="pug">
.v-asset-income-change
  Changes2Form(:fields='changeFields' :adding='adding' :fieldValues='changeFieldValues' :moneyType='moneyText' :moneyName='moneyName' @postChanges='postIncomeChanges' @cancelled='cancelled')
</template>

<script>
import Changes2Form from "@/components/changes2Form.vue";
import axios from "axios";

export default {
  props: ['returnPageUrl'],
  name: 'incomeChange',
  data() {
    return {
      changeFields: [],
      changeFieldValues: [],
      changes: undefined,
      income: {}
    }
  },
  computed: {
    isAsset: function () { return this.$route.name === 'AddScenarioAssetIncomeChange' || this.$route.name === 'AddScenarioAssetExpenseChange'},
    isLiability: function () { return this.$route.name === 'AddScenarioLiabilityIncomeChange' || this.$route.name === 'AddScenarioLiabilityExpenseChange' },
    isIncome: function () { return this.$route.name === 'AddScenarioAssetIncomeChange' || this.$route.name === 'AddScenarioLiabilityIncomeChange'},
    isExpense: function () { return this.$route.name === 'AddScenarioAssetExpenseChange' || this.$route.name === 'AddScenarioLiabilityExpenseChange' },
    assetLiabilityText: function () { return this.isAsset ? "Asset" : "Liability" },
    moneyText: function () { return this.isIncome ? (this.isAsset ? "AssetIncome" : "LiabilityIncome") : (this.isAsset ? "AssetExpense" : "LiabilityExpense") },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return this.isIncome ? (this.isAsset ? "asset_incomes" : "liability_incomes") : (this.isAsset ? "asset_expenses" : "liability_expenses") },
    moneyName: function () { return this.income ? this.income.name : '' },
    scenarioId: function () { let n = this.$route.params.scenarioId; return n ? Number(n) : undefined },
    assetId: function () { return this.$route.params.assetId },
    liabilityId: function () { return this.$route.params.liabilityId },
    incomeId: function () { return this.$route.params.incomeId },
    expenseId: function () { return this.$route.params.expenseId },
    moneyId: function () { return this.isIncome ? this.incomeId : this.expenseId },
    changesId: function () { return this.$route.params.id },
    adding: function () { return true },
    addEditStr: function () { return this.changesId ? "Edit" : "Add"},
    baseUrl: function () { return "/api/money_changes"},
  },
  methods: {
    returnPageUrlB: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.assetLiabilityText+"_"+id : "/"+this.moneyTextLowerPlural
    },
    postIncomeChanges: function (newChanges) {
      this.changes = newChanges[0]
      let model = {
        money_id: this.moneyId,
        money_type: this.isIncome ? (this.isAsset ? 2: 5) : (this.isAsset ? 3: 6),
        money_changes: this.changes,
        start_date: this.changes['start_date'],
        scenario_id: this.scenarioId
      }
      if (this.adding) {
        axios.post(this.baseUrl, {money_change: model}).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      } else {
        axios.put(this.baseUrl+"/"+this.changesId, model).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      }
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrlB(this.moneyId || ''))
    },
    loadIncome: function () {
      axios.get("/api/"+this.moneyTextLowerPlural+"/"+this.moneyId+"?scenario_id="+this.scenarioId).then((response) => {
        this.income = response.data
        this.setChangeFields()
      });
    },
    setChangeFields: function () {
      this.changeFields = [
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the amount will change"}
      ]
      if (this.income.amount) {
        this.changeFields.push({name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new amount value"})
      } else {
        this.changeFields.push({name: 'interest', label: 'Annual Interest', type: 'number', mandatory: true, help_text: "The new interest value"})
      }
    }
  },
  mounted: function () {
    this.loadIncome()
  },
  components: {
    Changes2Form,
  }
}
</script>

<style lang="scss">
</style>