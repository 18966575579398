export function selective(obj, attrs, fn) {
  var attr, copy, i, len;
  copy = {};
  for (i = 0, len = attrs.length; i < len; i++) {
    attr = attrs[i];
    copy[attr] = obj[attr];
  }
  if (fn) {
    fn(copy);
  }
  return copy;
}

export function clone(obj, fn) {
  var copy;
  copy = JSON.parse(JSON.stringify(obj));
  if (fn) {
    fn(copy);
  }
  return copy;
}

export function patch(obj, source, attrs) {
  var attr, i, len;
  for (i = 0, len = attrs.length; i < len; i++) {
    attr = attrs[i];
    obj[attr] = source[attr];
  }
  return obj;
}

export function areEquivalent(obj1, obj2, attrs) {
  var attr, i, len;
  for (i = 0, len = attrs.length; i < len; i++) {
    attr = attrs[i];
    if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
}
