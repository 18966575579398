<template lang="pug">
.v-asset-income-expense
  .form__panel.form__section
    h2 {{moneyType}} {{incomeExpenseStr}}s
    template(v-if='isAsset')
      p(v-if='isIncome') Any income this asset produces. The Asset income must have either an amount or an interest.
      p(v-if='isExpense') Any expenses this asset uses. The Asset expense must have either an amount or an interest.
    template(v-if='isLiability')
      p(v-if='isIncome') Any income this liability produces. Typically Liabilities do not make an income. The Liability income must have either an amount or an interest.
      p(v-if='isExpense') Any expenses this liability uses. The Liability expense must have either an amount or an interest. Typically an expense would be an interest on the loan.
    .incomes__buttons(v-if='!showOnLoad')
      button.action_button.small_button(@click='newIncomeExpense' :disabled='disableAdd') +
    div(v-if='showInputs || showOnLoad')
      AddEditForm(:fields='fields' :adding='true' @dataAdded="incomeExpenseAdded" @cancelled="cancelled" :addText='addText' :oneOfTwoMandatoryFields='oneOfTwoMandatoryFields' :changes='newMoneyChanges' @valueChange="valueChange")
        ChangesForm(:fields='changeFields' :moneyType='changeMoneyType' @postChanges='postIncomeExpenseChanges')
    div(v-if='!hasMonies')
      | No {{incomeExpenseStr}}s
    div(v-if='hasMonies')
      table.simple-table
        thead
          tr
            template(v-for='field in tableFields')
              th(v-if='!field.hide' :value='field.name')
                | {{ field.label}}
            th Actions
        tbody
          tr(v-for='money,index in newMonies' :value='index')
            template(v-for='field in tableFields')
              td(v-if='!field.hide' :value='field.name')
                | {{ incomeExpenseValue(money, field)}}
            td
              a(href='#' @click.prevent='deleteIncomeExpense(index)') Delete

</template>

<script>
import moment from 'moment'
import AddEditForm from "../components/addEditForm.vue"
import { clone } from "@/common/copies"
import FieldListsMixin from "@/components/fieldListsMixin.vue";
import ChangesForm from "@/components/changesForm.vue";
import { capitalize} from "@/common/strings";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [FieldListsMixin, CommonMixin],
  props: { income: Boolean, monies: Array, moneyType: String, showOnLoad: Boolean, disableAdd: Boolean, scenarioId: Number },
  name: 'assetIncomeExpense',
  data() {
    return {
      showInputs: false,
      newMonies: [],
      newMoneyChanges: [],
      oneOfTwoMandatoryFields: [['amount','interest']],
      incomeFields: [],
      expenseFields: [],
      changeFields: [],
      enteredAmountInt: undefined
    }
  },
  computed: {
    isAsset: function () { return this.moneyType === 'Asset'},
    isLiability: function () { return this.moneyType === 'Liability'},
    isIncome: function () { return this.income === true },
    isExpense: function () { return !this.isIncome },
    addText: function () { return "Add "+ this.incomeExpenseStr },
    incomeExpenseStr: function () { return this.isIncome ? "Income" : "Expense" },
    changeMoneyType: function () { return this.moneyType+" "+this.incomeExpenseStr},
    hasMonies: function () { return this.newMonies != null && this.newMonies.length > 0},
    fields: function() { return this.isIncome ? this.incomeFields : this.expenseFields },
    tableFields: function () {
      return this.fields.filter( (f) => f.showInTable !== false )
    }
  },
  methods: {
    incomeExpenseValue: function (money, field) {
      if (field.type === 'date') {
        if (this.isNull(money[field.name])) {
          return ""
        }
        return moment(money[field.name]).format("YYYY-MM-DD")
      } else if (field.name === 'money_changes') {
        let cs = [];
        if (this.isNull(money[field.name])) {
          return ""
        }
        for(let i = 0; i < money[field.name].length; i++) {
          let sd = moment(money[field.name][i]['start_date'])
          let int_value = money[field.name][i]['interest']
          let amount_value = money[field.name][i]['amount']
          // money_changes comes from database
          if (money[field.name][i]['money_changes'] !== undefined) {
            int_value = money[field.name][i]['money_changes']['interest']
            amount_value = money[field.name][i]['money_changes']['amount']
          }
          cs.push(sd.format("YYYY-MM-DD")+" "+(int_value ? ("interest: "+int_value) : '')+(amount_value ? ("amount: "+amount_value) : ''))
        }
        return cs.join(", ");
      } else {
        if (field.table_name !== undefined) {
          return capitalize(money[field.table_name]);
        }
        return capitalize(money[field.name]);
      }
    },
    addIncomeExpense: function () {
      this.showInputs = false
      this.$emit('formClose', true);
    },
    newIncomeExpense: function () {
      this.showInputs = true
      this.$emit('formOpen', true);
    },
    incomeExpenseAdded: function (data) {
      this.showInputs = false
      if (this.scenarioId) {
        data.scenario_id = this.scenarioId
        if (data.money_changes) {
          data.money_changes.forEach(item => {
            item.scenario_id = this.scenarioId
          })
        }
      }
      this.populateDepWdrAssLiabName(data, this.scenarioId)
      this.newMonies.push(data)
      this.postChanges()
      this.$emit('formClose', true);
    },
    cancelled: function () {
      this.showInputs = false
      this.$emit('formClose', true);
      this.$emit('cancelled', true);
    },
    deleteIncomeExpense: function (index) {
      this.newMonies.splice(index,1)
      this.postChanges()
    },
    loadMonies: function () {
      if (this.monies != null) {
        this.newMonies = clone(this.monies)
      } else {
        this.newMonies = []
      }
    },
    postChanges: function () {
      this.$emit('postMonies', this.newMonies);
    },
    postIncomeExpenseChanges: function (data) {
      this.newMoneyChanges = clone(data)
    },
    valueChange: function (payload) {
      if (payload.field.name === 'amount' || payload.field.name === 'interest') {
        this.enteredAmountInt = payload.field.name
      }
    },
    setFieldValues: function () {
      this.incomeFields =
        [
          {name: 'name', label: 'Name', type: 'string', mandatory: true},
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date you first get paid the income"},
          {name: 'end_date', label: 'End Date', type: 'date', help_text: "The date the income stops. Leave empty if it doesnt"},
          {name: 'amount', label: 'Amount', type: 'number', help_text: "Select either an Amount or Annual Interest. Amount is the fixed amount of the income", oneOfTwoMandatoryField: true},
          {name: 'interest', label: 'Annual Interest', type: 'number', help_text: "Select either an Amount or Annual Interest. Annual Interest is the interest percent paid to the account in 1 year", oneOfTwoMandatoryField: true},
          {name: 'frequency', label: 'Frequency Paid', type: 'list', mandatory: true, list_items: this.frequencies, help_text: "How often will the income be paid into the account"},
          {name: 'deposit_in_asset_id', table_name: 'deposit_in_asset', label: 'Deposit in Asset', type: 'string', default_value: this.isAsset && this.isIncome ? "self" : undefined, editable: false, hide: true },
          {name: 'deposit_in_liability_id', table_name: 'deposit_in_liability', label: 'Deposit in Liability', type: 'string', default_value: this.isLiability && this.isIncome ? "self" : undefined, editable: false, hide: true },
          {name: 'money_changes', label: 'Changes', type: 'string', editable: false},
          // { name: 'type', type: 'string'},
          {name: 'description', label: 'Description', type: 'text', showInTable: false},
        ]
      this.expenseFields =
        [
          {name: 'name', label: 'Name', type: 'string', mandatory: true},
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the expense is first taken from your account"},
          {name: 'end_date', label: 'End Date', type: 'date', help_text: "The date the expense stops. Leave empty if it doesnt"},
          {name: 'amount', label: 'Amount', type: 'number', help_text: "Select either an Amount or Annual Interest. Amount is the fixed amount of the expense", oneOfTwoMandatoryField: true},
          {name: 'interest', label: 'Annual Interest', type: 'number', help_text: "Select either an Amount or Annual Interest. Annual Interest is the interest percent taken from the account in 1 year", oneOfTwoMandatoryField: true},
          {name: 'frequency', label: 'Frequency Paid', type: 'list', mandatory: true, list_items: this.frequencies, help_text: "How often will the expense be taken from the account"},
          {name: 'withdraw_from_asset_id', table_name: 'withdraw_from_asset', label: 'Withdraw from Asset', type: 'string', default_value: this.isAsset && this.isExpense ? "self" : undefined, editable: false, hide: true },
          {name: 'withdraw_from_liability_id', table_name: 'withdraw_from_liability', label: 'Withdraw from Liability', type: 'string', default_value: this.isLiability && this.isExpense ? "self" : undefined, editable: false, hide: true },
          {name: 'money_changes', label: 'Changes', type: 'string', editable: false},
          // { name: 'type', type: 'string'},
          {name: 'description', label: 'Description', type: 'text', showInTable: false},
        ]
      this.setChangeFields()
    },
    setChangeFields: function () {
      this.changeFields =
        [
          {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the amount or interest will change"}
        ]
      if (this.enteredAmountInt === undefined || this.enteredAmountInt === 'amount') {
        this.changeFields.push({name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new amount value"})
      }
      if (this.enteredAmountInt === undefined || this.enteredAmountInt === 'interest') {
        this.changeFields.push({name: 'interest', label: 'Annual Interest', type: 'number', mandatory: true, help_text: "The new annual interest value"})
      }
    }
  },
  mounted: function () {
    this.loadMonies()
  },
  components: {
    AddEditForm,
    ChangesForm
  },
  watch: {
    "monies": function () {
      this.loadMonies();
    },
    "dbLiabilities": function () {
      this.setFieldValues();
    },
    "enteredAmountInt": function () {
      this.setChangeFields()
    }
  }
}
</script>

<style lang="scss">
</style>