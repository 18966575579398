<template lang="pug">
.v-buy-house
  .learn-container
    .learn-h1 Buy a House or Keep Renting?
    .learn-text
      | In this example, we will assume you are renting and would like to determine if you can
      | afford to stop renting and move into your own house. We will explore houses in different
      | price ranges to see which is best for you. We will also assume you have saved for a
      | deposit for a home.
    .learn-text
      | If you are new to Fyffle, I recommend you follow the
      a(href='/learn/getting-started')  Getting Started Guide

    .learn-h2 Setup
    .learn-text
      | This guide will cover the following actions:
      ul
        li
          a(href='#assets') Entering your Assets
        li
          a(href='#incomes') Entering your Incomes
        li
          a(href='#expenses') Entering your Expenses
        li
          a(href='#scenarios') Creating Scenarios
        li
          a(href='#reports') Generating Reports

    a(name='assets')
      .learn-h2 Assets
    .learn-text
      | In our example we will create a new Asset for our Savings Account.
      | Click the <b>Asset</b> button, then click <b>Add new Asset</b>.
      | If you have more assets include them. Do not include the new house here, we will do
      | that later in Scenarios.
    .img-container
      img.img-70(src='/images/learn/buy_house/savings_account.png')

    a(name='incomes')
      .learn-h2 Incomes
    .learn-text
      | We will add our salary to the incomes. In this example we get paid monthly of $5,000.
      | Click the <b>Income</b> button, Click <b>Add new Income</b>, and enter your income.
    .img-container
      img.img-70(src='/images/learn/buy_house/salary.png')

    a(name='expenses')
      .learn-h2 Expenses
    .learn-text
      | We will keep our expenses simple. We will add an expense for Rent, Weekly expenses,
      | Monthly expenses. But you can be as detailed as you like.
      | Click the <b>Expense</b> button, Click <b>Add new Expense</b>, and enter your expenses.
    .img-container
      img.img-90(src='/images/learn/buy_house/expenses.png')

    a(name='scenarios')
      .learn-h2 Scenarios
    .learn-text
      | Scenarios is where we can make future forecasting with different possible scenarios.
      | In our case lets see if we can afford to buy a $500,000 house and a $700,000 house.

    .learn-h3 Keep Renting Scenario
    .learn-text
        | We will first start off with creating a scenario for our current renting situation.
        | Which means we create a scenario with no changes.
        | Click on the <b>Scenarios</b> button and then the <b>Add new Scenario</b>
        | Give it a name of "Keep renting". Move to the bottom of the page, and click <b>Save</b>.

    .learn-h3 Buy 500K house
    .learn-text
        | In this scenario we will plan to buy our cheapest house in June and transition from renting
        | into home ownership. There are a lot of fees involved with buying a house,
        | I recommend finding out all the expenses for buying a house in your area. Some expenses maybe
        | included in your loan. There also maybe discounts for first home buyers.
    .learn-text
      | In our example we want to buy a home for $500,000, and include all the necessary fees.
      | Go to the Scenarios page, and click <b>Add new Scenario</b>. We will call it "Buy 500K home".
      | Click <b>Add Asset</b>. Enter the details for the home:
    .img-container
      img.img-75(src='/images/learn/buy_house/house500K.png')
    .learn-text
      | This home does not produce any income, or have any expenses. The expenses mentioned will not be part of the Asset. Click the Save button on the bottom of the screen.
      | On the scenario page the assets should look like this:
    .img-container
      img.img-90(src='/images/learn/buy_house/scenarioAssets.png')
    .learn-text
      | You will also gain a Liability - the mortgage for the house. Here we are adding a loan
      | of full price of house subtract the deposit: $500,000 - $100,000 = $400,000.
      | Click on the <b>Add Liability</b> button.
      | Fill in the details, the amount will be 400000.
    .img-container
      img.img-75(src='/images/learn/buy_house/400KLoan.png')
    .learn-text
      | We also need to add the interest on the load. Under <b>Liability expenses</b>, click the
      | <b>+</b> button. The interest rate for the loan should be entered in the <b>Annual Interest</b>
      | field, in our example its 5%. Usually interest in calculated monthly. Click the <b>Add Expense</b> button. Then click the <b>Add Liability</b>.
    .img-container
      img.img-75(src='/images/learn/buy_house/mortgageExpense.png')
    .learn-text
      | The scenario liabilities should look like this:
    .img-container
      img.img-90(src='/images/learn/buy_house/scenarioLiabilities.png')
    .learn-text
      | One of our expenses will change. We will no longer be paying Rent, so lets stop that.
      | On the right side of the table for the <b>Rent</b> row, click the <b>Change</b> link.
      | On the date of the settlement, we will change the value to 0.
      | Click <b>Add Change</b>
    .img-container
      img.img-75(src='/images/learn/buy_house/rentChange.png')
    .learn-text
      | Now we will enter all the new expenses for buying a house as we listed above.
      | Click the <b>Add Expense</b> button.
      | Enter the details for each of the expenses
      | related to buying the property, most of these fees will be one off amounts.
    .learn-text
      | Here is a list of possible expenses:
      ul
        li Application Fees or establishment fees. One off fee that deals with setup of mortgage. $500
        li Valuation fee. Banks may get a valuation of the property before granting the home loan. $200
        li Legal Fees. A solicitor or conveyancer maybe needed to perform the legal paperwork. $1000
        li Settlement Fees. $800
        li Mortgage Insurance. Typically if you have not saved up 20% deposit, you may need to
           pay mortgage insurance. Since we have saved the 20% deposit, we do not need to pay this.
        li Government fees. If you are living in Australia, this is Stamp duty, mortgage regsitration, transfer fee. $10,546
        li Building inspection. Always a good idea to get your house inspected before buying. $400
        li House Contents insurance $500
        li Moving costs $200
        li Deposit $100,000. We are paying for a 20% deposit.
    .learn-text
      | Our scenario expenses should look like this:
    .img-container
      img.img-90(src='/images/learn/buy_house/scenarioExpenses.png')

    .learn-text
      | We have one last action for this scenario. We need to pay off the monthly mortgage
      | bill. To do this we will add a transfer from our Savings Account into the Mortgage.
      | Click the <b>Add Transfer</b>
      | Depending on what type of Loan you get, will determine how much money you need to pay
      | per month. Find out from your bank, or an online mortgage calculator. In our
      | example we will pay $2,684 per month.
    .img-container
      img.img-75(src='/images/learn/buy_house/payMortgage.png')
    .learn-text
      | Our scenario transfers should look like this:
    .img-container
      img.img-90(src='/images/learn/buy_house/scenarioTransfer.png')
    .learn-text
      | Click the <b>Save</b> button to save the Scenario.

    .learn-h3.pad-t-20 Buy 700K house
    .learn-text
        | We will create another scenario which will be almost identical to the 500K example
        | above, but in this case we do not have a 20% deposit, so we will need to pay mortgage
        | insurance. We will pay a 10% deposit. You will need to check with your bank if
        | you qualify for a loan of only 10% deposit.
        | The changes compare with above scenario are:
        ul
          li Government fees have increased to $19,989
          li Mortgage Insurance is $12,600
          li Deposit is $70,000  (10% of the house value)
          li Mortgage payments have increased to $3,382
    .learn-text
      | The scenario should look something like this below.
    .img-container.flex
      img.img-90(src='/images/learn/buy_house/700KAssetLiabilities.png')
      img.img-90(src='/images/learn/buy_house/700KExpensesTransfers.png')

    a(name='reports')
      .learn-h2 Reports
    .learn-text
      | We are now ready to run reports to see how our 3 scenarios compare.
      | In the Scenarios page click the <b>Report</b> button.
      | We will make the report start on 1 Jan 2024, so set the <b>Start Date</b> for that.
    .img-container
      img.img-75(src='/images/learn/buy_house/reportEntry.png')
    .learn-text
      | Now Click <b>Generate Report</b>. You should soon see a 5 year report generated below.
      | In this case we see that we have errors for out 700K house scenario.
    .img-container
      img.img-75(src='/images/learn/buy_house/reportTable.png')
    .learn-text
      | If you look at the bottom of the page you will see Report warnings indicating
      | the Savings Account balance dipped below 0, and keeps getting larger (only 10 are shown).
      | We cannot afford to buy a house that is worth $700K, but we can afford to buy a house for $500K.
    .img-container.mgn-b-30
      img.img-90.pad-t-30(src='/images/learn/buy_house/reportWarnings.png')
</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: 'buyHouse.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
    useHead({
      title: 'Buying a House Scenario',
      meta: [
        { name: 'description', content: 'The process of buying a house in Fyffle involves entering your financial details and then creating a scenario to generate a report.'},
      ],
    })

  },
  components: {}
}
</script>

<style lang="scss">
</style>