<template lang="pug">
.v-reports(v-if='isLoggedIn')
  .flex-grid
    .card-container(v-for="card in cards" :value="card.title")
      .card.cursor-pointer(@click='cardClick(card)')
        .card-title
          | {{ card.title }}
        .card-icon
          font-awesome(:icon="cardIcon(card)")
        .card-body
          | {{ card.desc }}

  MoneyBar
</template>

<script>
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

const cards = [
  {
    title: "Future Forecast Report",
    desc: "A report forecasting into the future using your own financial data and comparing with possible financial scenarios. Make finacial decisions today based on future scenarios.",
    icon: 'fa-table',
    url: "future-forecast-report"
  },
  {
    title: "Income Expense Report",
    desc: "Where is my money going? A chart showing where all your money is going.",
    icon: 'fa-chart-pie',
    url: "income-expense-report"
  },
  {
    title: "Net Worth Report",
    desc: "The total net worth by adding all our Assets and Liabilities, for each scenario. Depends on future forecast report.",
    icon: 'fa-chart-line',
    url: "net-worth-report"
  },
  {
    title: "Asset Balances Report",
    desc: "The balances of all assets in a given scenario for a given year. Depends on future forecast report.",
    icon: 'fa-chart-line',
    url: "asset-balance-report"
  },
  {
    title: "Calendar",
    desc: "Any incomes or expenses coming up for the month.",
    icon: 'fa-table',
    url: "calendar"
  },
  {
    title: "Savings Report",
    desc: "The total savings from selected assets, for each scenario. Depends on future forecast report.",
    icon: 'fa-chart-line',
    url: "savings-report"
  },
]

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'reports.vue',
  data() {
    return {
      cards: cards
    }
  },
  computed: {
  },
  methods: {
    card_url: function (card) {
      return "/report/"+card.url;
    },
    cardClick: function (card) {
      this.$router.push(this.card_url(card));
    },
    cardIcon: function (card) {
      return "fa-solid "+card.icon;
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Reports',
      meta: [
        { name: 'description', content: 'Reports based on your financial information and scenarios.' },
      ],
    })
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
@import "../../assets/stylesheets/_variables.scss";

.v-reports {
  .flex-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
    margin-bottom: 30px;

    .card-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: var(--color-theme4);
      border-radius: 10px;

      .card {
        border-bottom: 5px solid #ddd;
        border-radius: 10px;
        overflow: clip;
        height: 100%;
        margin: 0;
        transition: 200ms ease;
        width: auto;
      }

      .card:hover {
        border-color: #CCC;
      }

      .image-container {
        position: relative;
        flex-shrink: 0;

        img {
          aspect-ratio: 3/1;
          height: auto;
          object-fit: cover;
          width: 100%;
        }
      }

      .card-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 16px;
        padding: 24px;
      }

      .card-title {
        height: 60px;
        font-size: 20px;
        font-weight: bold;
        background-color: var(--color-theme3);
        color: white;
        text-align: center;

        a {
          text-decoration: none;
          color: white
        }

        a:hover {
          text-decoration: underline;
        }
      }

      .card-icon {
        text-align: center;
        font-size: 40px;
        margin-top: 10px;
      }
    }
  }
}

@media only screen and (min-width: $med-screen) {
  .v-reports {
    .flex-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media only screen and (min-width: $large-screen) {
  .v-reports {
    .flex-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}

</style>