<template lang="pug">
.v-expenses(v-if='isLoggedIn')
  .heading-1
    | Expenses
  .expenses__intro
    .money__intro_p
      | An expense refers to the costs incurred or the resources used by an individual, company, or entity. It represents the outflow or depletion of assets, usually in the form of money, to acquire goods, services, or assets necessary for ongoing operations.
    .money__intro_p
      | Some examples of your expenses will be groceries, fuel, rent, school fees, holidays, birthdays, insurances, subscriptions, car maintenance, etc. You can group them together, or enter them individually.
    .money__intro_p
      | Add your expense in below

  ExpenseTable(v-if='hasExpenses' :expenses='expenses' :includeActions='true' @edit='editExpense' @delete='deleteExpense')

  .heading-3.mgn-t-30(v-if='hasAssetExpenses')
    | Asset Expenses
  .money__intro_p(v-if='hasAssetExpenses')
    | Any expense from an asset like fees should be added in the Asset page.
  ExpenseTable(v-if='hasAssetExpenses' :expenses='assetExpenses' :includeActions='false' :isAssetLiab='true')

  .heading-3.mgn-t-30(v-if='hasLiabilityExpenses')
    | Liability Expenses
  .money__intro_p(v-if='hasLiabilityExpenses')
    | Any expense from a liability like loan interest should be added in the Liability page.
  ExpenseTable(v-if='hasLiabilityExpenses' :expenses='liabilityExpenses' :includeActions='false' :isAssetLiab='true')

  .heading-3.mgn-t-30
    | Total
  table.simple-table(v-if='hasExpenses')
    tr
      th &nbsp;
      th Week
      th Month
      th Year
    tr
      th TOTAL
      td {{ formatNumber(totalAnnual / 52,2) }}
      td {{ formatNumber(totalAnnual / 12,2) }}
      td {{ formatNumber(totalAnnual,2) }}

  .money-button-bar.pad-b-30
    button.action_button(v-if='showProgressBtn' @click='progress("/")')
      | I do not have expenses
    button.action_button(@click='addExpense')
      | Add new Expense
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";
import ExpenseTable from "@/expenses/expenseTable.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'expenses',
  data() {
    return {
      expenses: undefined,
      assetExpenses: undefined,
      liabilityExpenses: undefined,
      totalAnnual: 0
    }
  },
  computed: {
    hasExpenses: function () { return this.expenses != null && this.expenses.length > 0 },
    hasAssetExpenses: function () { return this.assetExpenses != null && this.assetExpenses.length > 0 },
    hasLiabilityExpenses: function () { return this.liabilityExpenses != null && this.liabilityExpenses.length > 0 },
    progressExpenses: function () { return (this.profile.progress & 8) > 0 },
    showProgressBtn: function () { return !this.progressExpenses && !this.hasExpenses },
  },
  methods: {
    addExpense() {
      this.progress('/addEditExpense')
    },
    done() {
      this.$router.push('/')
    },
    deleteExpense(expense) {
      Swal.fire({
        title: "Delete Expense",
        text: 'Are you sure you want to delete expense '+expense.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/expenses/"+expense.id).then((response) => {
            this.loadExpenses()
          })
        }
      })
    },
    editExpense(expense) {
      this.$router.push(`/addEditExpense/${expense.id}`)
    },
    loadExpenses() {
      axios.get("/api/expenses").then((response) => {
        this.expenses = response.data.rows
        this.totalAnnual = response.data.total_annual
        this.assetExpenses = response.data.asset_rows
        this.liabilityExpenses = response.data.liability_rows
      });
    },
    progress(url) {
      axios.put("/user/progress?flag=4").then((response) => {
        const progress = response.data
        this.$store.commit('updateUserProgress', progress)
        this.$router.push(url)
      });
    }
  },
  mounted: function () {
    this.loadExpenses()
    useHead({
      title: 'Fyffle Expenses',
      meta: [
        { name: 'description', content: 'A collection of expenses you pay' },
      ],
    })
  },
  components: {
    MoneyBar,
    ExpenseTable
  }
}
</script>

<style lang="scss">
</style>