<template lang="pug">
.v-changes-form
  .form__panel.form__section
    h2 {{ moneyType}} Changes
    p Any changes that will happen to the {{moneyType}} in the future.
    .changes__buttons
      button.action_button.small_button(@click='newChange' :disabled='disableAdd') +
    div(v-if='showInputs')
      AddEditForm(:fields='fields' :adding='true' @dataAdded="changeAdded" @cancelled="cancelled" addText='Add Change')
    div(v-if='!hasChanges')
      | No changes
    div(v-if='hasChanges')
      table.simple-table
        thead
          tr
            th(v-for='field in visibleFields' :value='field.name')
              | {{ field.label}}
            th Actions
        tbody
          tr(v-for='change,index in newChanges' :value='index')
            td(v-for='field in visibleFields' :value='field.name')
              | {{ changeValue(change, field)}}
            td
              a(href='#' @click.prevent='deleteChange(index)') Delete
</template>

<script>
import moment from 'moment'
import AddEditForm from "../components/addEditForm.vue"
import { clone } from "../common/copies.js"

export default {
  props: { fields: Array, fieldValues: Array, changes: Array, moneyType: String, disableAdd: Boolean },
  name: 'changesForm',
  data() {
    return {
      showInputs: false,
      newChanges: [],
      visibleFields: []
    }
  },
  computed: {
    hasChanges: function () { return this.newChanges != null && this.newChanges.length > 0},
  },
  methods: {
    changeValue: function (change, field) {
      if (change === null) {
        return ''
      }
      if (field.type === 'date') {
        return moment(change[field.name]).format("YYYY-MM-DD")
      } else {
        return change[field.name];
      }
    },
    addChange: function () {
      this.showInputs = false
      this.$emit('formClose', true);
    },
    newChange: function () {
      this.showInputs = true
      this.$emit('formOpen', true);
    },
    changeAdded: function (data) {
      this.showInputs = false
      this.newChanges.push(data)
      this.postChanges()
      this.$emit('formClose', true);
    },
    cancelled: function () {
      this.showInputs = false
      this.$emit('formClose', true);
    },
    deleteChange: function (index) {
      this.newChanges.splice(index,1)
      this.postChanges()
    },
    loadChanges: function () {
      if (this.changes != null) {
        this.newChanges = clone(this.changes)
      } else {
        this.newChanges = []
      }
    },
    postChanges: function () {
      this.$emit('postChanges', this.newChanges);
    },
    setVisibleFields: function () {
      this.visibleFields = this.fields.filter( (f) => f.editable != false )
      this.loadChanges()
    },
    setNewChanges: function () {
      if (this.fieldValues != undefined) {
        let chgs = []
        for (let i=0; i < this.fieldValues.length; i++) {
          chgs.push(this.fieldValues[i]['money_changes'])
        }
        this.newChanges = clone(chgs)
      }
    }
  },
  mounted: function () {
    this.setVisibleFields()
    this.setNewChanges()
  },
  components: {
    AddEditForm
  },
  watch: {
    "fields" : function () {
      this.setVisibleFields()
    },
    "fieldValues": function () {
      this.setNewChanges()
    }
  }
}
</script>

<style lang="scss">
</style>