<template lang="pug">
.v-calendar(v-if='isLoggedIn')
  .heading-1
    | Calendar
  p Displays any incomes, expenses or transfers that are coming up for the month.
  .report-row.pad-t-20
    .currdate-div
      button.small_button.button__fitted.button__gray(@click="changeYear(-1)") <
      span.pad-l-10.pad-r-10 {{ year }}
      button.small_button.button__fitted.button__gray(@click="changeYear(1)") >
    .currdate-div
      button.small_button.button__fitted.button__gray(@click="changeMonth(-1)") <
      span.month-str.pad-l-10.pad-r-10 {{ monthStr }}
      button.small_button.button__fitted.button__gray(@click="changeMonth(1)") >

  .calendar-container(v-if='dateData')
    table.simple-table
      tr
        th(v-for='day in 7' v-html='dowStr(day-1)' :class='weekDayClass(0, day-1)')
      tr(v-for='wc in weekCount')
        td(v-for='day in 7' :class='weekDayClass(wc-1, day-1)')
          .calendar-td
            .week-day-date(v-html='dayStr(wc-1, day-1)' :class="{'curr-day' : isCurrDay(wc-1, day-1)}")
            .event-items
              .event-item(v-for='evt in dayEvents(wc-1,day-1)')
                .event-container(:class='eventClass(evt)')
                  .event-row1
                    span {{ evt['name']}}
                    span {{ formatCurrency(evt['amount']) }}
                  .event-row2 {{ eventRow2(evt) }}

  MoneyBar.mgn-t-30
</template>

<script>
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";
import axios from "axios";
import moment from "moment";

// .calendar-row
//     .calendar-day-div(v-for='day,index in weekDays()' :class='weekDayClass(index)')
// .calendar-date(v-html='day')

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'calendar.vue',
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth(),
      startDate: undefined,
      dateData: [],
      delayTimer: undefined,
    }
  },
  computed: {
    weekCount: function () { return this.dateData.length / 7 },
    monthStr: function () { return moment("2024-"+(this.month+1)+"-1").format('MMMM') }

  },
  methods: {
    loadData: function () {
      axios.get("/api/reports/month_events?year="+this.year+"&month="+this.month).then((response) => {
        this.startDate = response.data.start_date
        this.dateData = response.data.data
      })
    },
    loadDataDelayed: function () {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
      this.delayTimer = setTimeout(() => {
        this.loadData();
      }, 400)
    },
    weekDayClass: function (weekCount, dow) {
      if (!this.dateData) {
        return ""
      }
      let day = this.dateData[weekCount * 7 + dow]
      if (!day) {
        return ""
      }
      let currDay = moment(day.date)
      return "week-day-"+currDay.format('ddd');
    },
    dowStr: function (dow) {
      let currDay = moment(this.startDate).add(dow, 'day');
      return currDay.format('dddd');
    },
    dayStr: function (weekCount, dow) {
      let day = this.dateData[weekCount * 7 + dow]
      let currDay = moment(day.date)
      return currDay.format('MMM D');
    },
    dayEvents: function (weekCount, dow) {
      let day = this.dateData[weekCount * 7 + dow]
      return day.ev
    },
    isCurrDay: function (weekCount, dow) {
      let day = moment(this.startDate).add(weekCount * 7 + dow, 'day')
      let now = moment()
      return (day.year() === now.year() && day.month() === now.month() && day.date() === now.date())
    },
    eventClass: function (event) {
      if (event === undefined) return '';
      let type = event.type
      return "money-event event-"+type
    },
    eventRow2: function (evt) {
      if (evt.type === 'transfer') {
        return (evt.from_asset || evt.from_liability) + " -> " + (evt.to_asset || evt.to_liability)
      } else {
        return evt.asset || evt.liability
      }
    },
    changeYear: function (val) {
      this.dateData = []
      this.year = this.year + val;
      this.loadDataDelayed()
    },
    changeMonth: function (val) {
      this.dateData = []
      this.month = this.month + val;
      if (this.month < 0) {
        this.month = 11;
        this.year = this.year - 1;
      } else if (this.month > 11) {
        this.month = 0;
        this.year = this.year + 1;
      }
      this.loadDataDelayed()
    }

  },
  mounted: function () {
    this.loadData()
  },
  components: {
    MoneyBar,
  }
}
</script>

<style lang="scss">
.v-calendar {
  .calendar-container {
    width: 100%;
    padding-top: 30px;
  }
  .simple-table {
    width: 100%;
    margin-top: 0;
    td {
      align-content: baseline;
    }
  }
  .calendar-td {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .month-str {
    display: inline-block;
    width: 106px;
  }
  .curr-day {
    background-color: #333333;
    color: white;
  }
}
</style>