import { createStore } from 'vuex';
import axios from 'axios';
// import ReportsModule from "@/pages/modules/reportsModule"

const store = createStore({

  state () {
    return {
      count: 0,
      profile: undefined,
      // account: undefined,
      // fetchingAccount: false
    }
  },

  mutations: {
    initProfile(state, profile) {
      state.profile = profile
      return state
    },

    updateUserProgress(state, progress) {
      state.profile.progress = progress
      return state
    }

    // initAccount(state, account) {
    //   state.account = account
    //   console.log("---initaccount account="+JSON.stringify(account))
    //   return state
    // },
    //
    // setAccount(state, payload) {
    //   state.account = payload
    // },
    //
    // increment (state) {
    //   state.count++
    // },
    //
    // fetchingAccount (state, fetching) {
    //   state.fetchingAccount = fetching
    // },
  },

  actions: {
    fetchProfile(context) {
      return axios.get('/profile.json').then(response =>
        context.commit('initProfile', response.data))
        .catch(err => console.error(err))
    },
    // fetchAccount(context, id) {
    //   context.commit('fetchingAccount', true)
    //   console.log("******************* fetchAccount id="+id)
    //   return axios.get("/api/accounts/"+id).then((response) => {
    //     context.commit('initAccount', response.data)
    //     context.commit('fetchingAccount', false)
    //   })
    //   .catch(err => {
    //     console.error(err)
    //     context.commit('fetchingAccount', false)
    //   })
    // }
  },

  getters: {
    profile(state) {
      return (state.profile);
    },
    // account(state) {
    //   console.log("--get Account="+JSON.stringify(state.account))
    //   return (state.account);
    // },
  }

})

export default store
