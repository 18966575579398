<template lang="pug">
.v-money-bar
  .money-bar
    router-link.money-link(to='/assets') Assets
    router-link.money-link(to='/liabilities') Liabilities
    router-link.money-link(to='/incomes') Incomes
    router-link.money-link(to='/expenses') Expenses
    router-link.money-link(to='/transfers') Transfers
    router-link.money-link(to='/scenarios') Scenarios
    router-link.money-link(to='/reports') Reports
</template>

<script>

export default {
  name: 'moneyBar.vue',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
</style>