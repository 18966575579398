<template lang="pug">
.v-pricing
  h2.pad-t-10.pad-b-20 Pricing
  .text-align-center
    button.btn.btn-primary.btn-pricing(@click='register')
      div 100% free
      div Get started with early access.

  p.pad-t-50.pricing-text
   | Our goal is to deliver value and empower our users ahead of the official launch. It’s a mutually beneficial approach: you experience new features first and share your feedback, while we use your insights to enhance the product and tailor it to your needs.

</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: 'pricing.vue',
  methods: {
    register: function () {
      window.location.href='/users/sign_up'
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Pricing',
      meta: [
        { name: 'description', content: 'Fyffle pricing' },
      ],
    })
  },
  components: {
  }
}
</script>

<style lang="scss">
.v-pricing {
  p {
    padding-bottom: 30px;
  }
  .pricing-text {
    padding-left: 20%;
    padding-right: 20%;
  }
}
</style>