<template lang="pug">
.v-add-edit-asset-expense
  .form__panel.form__section
    h2 {{ addEditStr}} {{ moneyType}} Expense for {{moneyName}}{{scenarioText}}
    div
      AssetIncomeExpense(:moneyType='moneyText' :income='false' :monies='expenses' :scenarioId='scenarioId' :showOnLoad='true' @postMonies='postExpense' @cancelled='cancelled')
  MoneyBar(v-if='scenarioId')
</template>

<script>
import AddEditForm from "@/components/addEditForm.vue";
import AssetIncomeExpense from "@/assets/assetIncomeExpense.vue";
import axios from "axios";
import { clone } from "@/common/copies"
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  props: ['returnPageUrl'],
  name: 'assetExpense',
  data() {
    return {
      expenseFieldValues: [],
      expense: undefined,
      asset: {}
    }
  },
  computed: {
    isAsset: function () { return this.$route.name === 'AddScenarioAssetExpense'},
    isLiability: function () { return this.$route.name === 'AddScenarioLiabilityExpense' },
    moneyText: function () { return this.isAsset ? "Asset" : "Liability" },
    addText: function () { return this.addEditStr + ' Expense' },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return this.isAsset ? "assets" : "liabilities" },
    moneyName: function () { return this.asset ? this.asset.name : '' },
    scenarioId: function () { let n = this.$route.params.scenarioId; return n ? Number(n) : undefined },
    scenarioText: function () { return this.scenarioId ? " for Scenario" : "" },
    assetId: function () { return this.$route.params.assetId },
    liabilityId: function () { return this.$route.params.liabilityId },
    moneyId: function () { return this.isAsset ? this.assetId : this.liabilityId },
    assetExpenseId: function () { return this.$route.params.id },
    adding: function () { return true },
    addEditStr: function () { return this.assetExpenseId ? "Edit" : "Add"},
    baseUrl: function () { return "/api/"+this.moneyTextLower+"_expenses"},
  },
  methods: {
    returnPageUrlB: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.moneyText+"_"+id : "/"+this.moneyTextLowerPlural
    },
    postExpense: function (newChanges) {
      this.expense = newChanges[0]
      let model = clone(this.expense)
      if (this.isAsset) { model['asset_id'] = this.assetId }
      if (this.isLiability) { model['liability_id'] = this.liabilityId }
      model['scenario_id'] = this.scenarioId
      if (this.adding) {
        let data = {}
        if (this.isAsset) { data['asset_expense'] = model }
        if (this.isLiability) { data['liability_expense'] = model }
        axios.post(this.baseUrl, data).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      } else {
        axios.put(this.baseUrl+"/"+this.assetExpenseId, model).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      }
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrlB(this.moneyId || ''))
    },
    loadAsset: function () {
      axios.get("/api/"+this.moneyTextLowerPlural+"/"+this.moneyId+"?scenario_id="+this.scenarioId).then((response) => {
        this.asset = response.data
      });
    }

  },
  mounted: function () {
    this.loadAsset()
  },
  components: {
    AddEditForm,
    AssetIncomeExpense,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>