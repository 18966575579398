<template lang="pug">
.v-legal-list
  template(v-if='items')
    .legal-line(v-for='item,index in items' :key='itemKey(index)')
      .legal-indent
        | {{ bulletVal(index, item) }}
      .legal-content(v-html='item')
  template(v-else-if='nestedItems')
    .legal-line(v-for='item,index in nestedItems' :key='itemKey(index)')
      .legal-indent(:class="{'legal-mainhead' : isHead(item)}")
        | {{ bulletVal(index, item) }}
      div(v-if='item.type == "subitem"')
        .legal-line
          .legal-indent
            | {{ subBulletVal(index, item)}}
          .legal-content(v-html='item.value')
      .legal-content(v-else v-html='item.value' :class="{'legal-mainhead' : isHead(item)}")
</template>

<script>

export default {
  props: ['listType', 'items', 'nestedItems'],
  name: 'legalList.vue',
  data() {
    return {
    }
  },
  computed: {},
  methods: {
    itemKey: function (index) {
      return "item-"+index;
    },
    bulletVal: function (index, item) {
      switch(this.listType) {
        case 'numbers':
          return (index+1)+".";
        case 'number-brackets':
          return "("+(index+1)+")";
        case 'alpha-brackets':
          return "("+this.numberToChar(index)+")";
        case 'nested':
          if (item.type === 'head') {
            return this.nestedBulletVals(index).num+"."
          }
          else if (item.type === 'item') {
            let n = this.nestedBulletVals(index)
            return n.num+"."+n.subnum+"."
          }
          return ""
      }
    },
    subBulletVal: function (index, item) {
      switch(this.listType) {
        case 'nested':
          if (item.type === 'subitem') {
            let n = this.nestedBulletVals(index)
            return "("+this.numberToChar(n.subsubnum-1).toLowerCase()+")"
          }
          return ""
      }
    },
    nestedBulletVals: function (index) {
      let num = 0
      let subnum = 0
      let subsubnum = 0
      for (const [i, item] of this.nestedItems.entries()) {
        if (item.type === 'head') {
          num++;
          subnum = 0;
          subsubnum = 0
        }
        else if (item.type === 'item') {
          subnum++;
          subsubnum = 0
        }
        else if (item.type === 'subitem') {
          subsubnum++;
        }
        if (i === index) {
          break;
        }
      }
      return {num: num, subnum: subnum, subsubnum: subsubnum}
    },
    numberToChar: function (index) {
      return String.fromCharCode(index+65);
    },
    isHead: function (item) {
      return item.type === 'head';
    }
  },
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>