import jquery from "jquery"
window.$ = jquery
window.jQuery = jquery

// We also need to consider the scenario where the user has multiple tabs open. This will run this code in each
// tab. We use localStorage to store the last time a user did something in the app.
import Swal from "sweetalert2";

const alPollTime = 2000;
// Warning message appears for 1 minute
const alTimoutNow = 1 * 60 * 1000;
// We are matching session timeout found in session_store.rb, and subtract time for modal to show
// Logout after 10 minutes
const alWarningTimeout    = (10 * 60 * 1000) - alTimoutNow;
var alPollTimerID;
var showingLogoutSwal = false;

function startPollTimer() {
  alPollTimerID = window.setTimeout(pollTimeout, alPollTime);
}

function alTimeDiff() {
  let lastTime = new Date(localStorage.getItem('lastResetTimeAutoLogout'));
  if (lastTime === undefined || lastTime === null) {
    lastTime = new Date();
  }
  let currTime = new Date();
  return (currTime.getTime() - lastTime.getTime());
}

// Polls every 2 seconds, and checks if it should show warning or logout
function pollTimeout() {
  let timeDiff = alTimeDiff();
  let timeout = alWarningTimeout

  if (isLoggedIn()) {
    // Warning time plus time now exceeded, then logout
    if (timeDiff > (timeout + alTimoutNow)) {
      logoutUser();
    }
    // Are we up to warning modal time?
    else if (timeDiff > timeout) {
      if (!showingLogoutSwal) {
        warningInactive();
      }
    } else {
      if (showingLogoutSwal) {
        closeLogoutSwal();
      }
    }
  }

  // If neither, reset poll timer
  window.clearTimeout(alPollTimerID);
  startPollTimer();
}

function warningInactive() {
  Swal.fire({
    title: "Warning!",
    text: "Your login appears to be inactive and you will be logged out in 1 minute. If you would like to stay logged in, please click below.",
    icon: 'warning'})
  showingLogoutSwal = true;
}

function resetTimer() {
  localStorage.setItem('lastResetTimeAutoLogout', new Date());
  window.clearTimeout(alPollTimerID);
  startPollTimer();
  if (showingLogoutSwal) {
    closeLogoutSwal();
  }
}

function closeLogoutSwal() {
  Swal.close();
  showingLogoutSwal = false;
}

function isLoggedIn() {
  return document.getElementById('logoutBtn') != null;
}

// Logout the user.
function logoutUser() {
  if (document.getElementById('logoutBtn') != null) {
    document.getElementById('logoutBtn').click();
  }
}

function setupTimers () {
  document.addEventListener("mousemove", resetTimer, false);
  document.addEventListener("mousedown", resetTimer, false);
  document.addEventListener("keypress", resetTimer, false);
  document.addEventListener("touchmove", resetTimer, false);
  document.addEventListener("onscroll", resetTimer, false);
  resetTimer();
}

$(document).ready(function(){
  // Comment line out below to disable auto logout
  // Lets not timeout in dev mode.
  if (window.location.href.includes("https:")) {
    setupTimers();
  }
});
