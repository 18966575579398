<template lang="pug">
.v-future-forecast-report(v-if='isLoggedIn')
  .heading-1
    | Future Forecast Report
  p
    | Generates a year by year report summarising your expected future incomes, expenses, profit, and net worth based on the scenarios you have entered. Select a <b>Start Date</b> of when to start the report, then click <b>Generate Report</b>. The report will project 5 years into the future from this point. The report will be saved for future reference, if you make changes in your financial data, you must regenerate the report.

  .warning-panel.mgn-t-10.pad-b-20(v-if='hasWarnings')
    h3 Warnings:
    .warning-row(v-for="error in validate_data")
      | {{ error }}
  .error-panel.mgn-t-10(v-if='hasErrorMessage')
    h3 Errors:
    .error-row
      | Error Generating Report: {{ errorMessage }}

  .form__panel
    .form__row
      .form__row--fields
        .form__header
          span Start Date
          span.form__input--mandatory
            |  *
        .form__input
          .form__input--100
            VueDatePicker(v-model='startDate' :enableTimePicker="false" :format='datePickerFormatDate')

  .money-button-bar.pad-b-20
    button.action_button(@click='gotoScenarios')
      | Scenarios
    button.action_button(@click='generateReport' :disabled='generating')
      template(v-if='!generating')
        | Generate Report
      template(v-if='generating')
        | Generating...

  .summary_div.pad-t-10(v-if='summary_report')
    span.pad-r-20
     b Generated on:
    span {{ formatTime(summary_report.job_start_time) }}
  .summary_div.pad-t-10(v-if='summary_report')
    span.pad-r-20
     b Start Date:
    span {{ summary_report.start_date }}

  .summary_div.pad-t-10(v-if='summary_report')
    h2.pad-b-10 Summary Per Year
    p
      | Initial Assets is the sum of all your Assets at the end of the first day. Similarly with Liabilities.
    p
      | If there are any warnings with the report, for example an Asset balance going below 0, the warning will appear at the bottom of this page.
  .summary_div(v-if='summary_report')
    table.simple-table
      template(v-for="row,row_index in summary_report['rows']")
        thead
          tr(v-if='row_index==0')
            th(v-for="item in row")
              | {{item}}
        tbody
          tr(v-if='row_index > 0')
            td(v-for="item,cell_index in row" :class="{'item-cell' : cell_index==0, 'number-cell' : cell_index > 0, 'negative-cell' : isNegative(cell_index, item), 'net-worth-row' : isNetWorth(row[0]) }")
              template(v-if="cell_index == 0")
                | {{item}}
              template(v-else)
                | {{formatNumber(item)}}

  .summary_div.pad-t-30.pad-b-30(v-if='hasReportResults')
    h2 Scenario Report Warnings
    table.simple-table
        thead
          tr
            th Scenario
            th Money
            th Message
        tbody
          tr(v-for="row in report_results" :class="{'warn-text' : isWarnRow(row), 'error-text' : isErrorRow(row) }")
            td {{ row['scenario'] }}
            td {{ row['money'] }}
            td {{ row['comment'] }}

  .summary_div.pad-b-30.pad-t-20(v-if='summary_report')
    h2 Download Detailed Reports
    div(v-for='scenario,index in scenarios' )
      a(:href="scenarioUrl(scenario)" :disabled='generating')
        | {{scenario.name}}

  MoneyBar
</template>

<script>

import axios from "axios";
import moment from "moment/moment";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'futureForecastReport.vue',
  data() {
    return {
      generating: false,
      validate_data: undefined,
      summary_report: {},
      report_results: [],
      scenarios: [],
      number_format: new Intl.NumberFormat(),
      delayTimer: undefined,
      retryCount: 0,
      startDate: moment(),
      errorMessage: undefined,
    }
  },
  computed: {
    hasWarnings: function () { return this.validate_data !== undefined && this.validate_data.length > 0 },
    formattedStartDate: function () { return moment(this.startDate).format("YYYY-MM-DD") },
    hasReportResults: function () { return (this.report_results !== undefined && this.report_results.length > 0) },
    hasErrorMessage: function () { return (!this.isNull(this.errorMessage)) },
    profile: function() { return this.$store.state.profile },
    progress: function () { return this.profile ? this.profile.progress : '' }
  },
  methods: {
    isWarnRow: function (row) {
      return (row['severity'] === 'warn')
    },
    isErrorRow: function (row) {
      return (row['severity'] === 'error')
    },
    generateReport: function () {
      this.generating = true
      this.summary_report = undefined
      this.report_results = undefined
      this.retryCount = 0
      this.errorMessage = undefined;
      this.$store.commit('updateUserProgress', this.progress | 64)
      if (this.delayTimer) {
        clearTimeout(this.delayTimer)
      }
      this.delayTimer = setTimeout(() => {
        this.loadSummaryReport(true);
      }, 5000)
      axios.post("/api/reports/generate_report",{start_date: this.formattedStartDate}).then(
          (response) => {
          });
    },
    detailedReport: function () {
    },
    scenarioUrl: function (scenario) {
      return '/api/reports/detailed_report.csv?scenario_id='+scenario.id;
    },
    formatNumber: function (num) {
      return this.number_format.format(num)
    },
    formatTime: function (dt) {
      return moment(dt).format("YYYY-MM-DD HH:mm:ss")
    },
    datePickerFormatDate: function (date) {
      return `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`
    },
    isNegative: function (cell_index, item) {
      if (cell_index === 0) {
        return false
      }
      return (item < 0);
    },
    isNetWorth: function (str) {
      return (str.includes("Net Worth"));
    },
    gotoScenarios: function () {
      this.$router.push("/scenarios")
    },
    loadSummaryReport: function (retry) {
      this.errorMessage = undefined;
      let _this = this;
      axios.get("/api/reports/summary_report").then((response) => {
        this.summary_report = response.data['year_forecast']
        this.report_results = response.data['results']
        this.scenarios = response.data['scenarios']
        this.errorMessage = response.data['errors']
        if (this.summary_report) {
          this.generating = false
          this.startDate = moment(this.summary_report.start_date)
        } else {
          this.retryCount = this.retryCount + 1;
          if (this.retryCount < 4) {
            clearTimeout(this.delayTimer)
            this.delayTimer = setTimeout(() => {
              this.loadSummaryReport(true);
            }, 3000)
          } else {
            this.generating = false
          }
        }
      }).catch(function (error) {
        _this.generating = false;
        _this.errorMessage = JSON.stringify(error['message'] || error);
      });
    },
  },
  mounted: function () {
    useHead({
      title: 'Future Forecast Report',
      meta: [
        { name: 'description', content: 'Future Forecast Report, extrapolating your own financial details to forecast the future.' },
      ],
    })
    axios.get("/api/reports/validate_data").then((response) => {
      this.validate_data = response.data['errors']
    });
    this.loadSummaryReport(false);
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>