<template lang="pug">
.v-scenario-panel
  .dashboard-panel__heading
    span Scenarios
    span
      button.dashboard-panel__more-button(@click='gotoScenarios') More
  .scenario-panel__main.scenario-panel__desc(:class="{'scenario-not-ready' : !scenarioReady}")
    .scenario-panel-bar.scenario-panel-bar--blue
    .scenario-panel-right
      .scenario-panel__heading
        b What is a scenario?
      .scenario-panel__text
        | A scenario is where you can test your future financial ideas.

  .scenario-panel__main.scenario-panel__best(:class="{'scenario-not-ready' : !scenarioReady}")
    .scenario-panel-bar.scenario-panel-bar--green
    .scenario-panel-right
      .scenario-panel__heading
        b Best performing
      .scenario-panel__text
        | Net Worth 1 Year
      .scenario-panel__best-performing
        .scenario-panel__scenario(v-for='scen,index in bestPerforming1')
          div
            b {{bestPerfScenName('1', index)}}
          div {{formatDollars(bestPerfScenAmount('1', index))}}
      .scenario-panel__text.pad-t-5
        | Net Worth 5 Years
      .scenario-panel__best-performing
        .scenario-panel__scenario(v-for='scen,index in bestPerforming5')
          div
            b {{bestPerfScenName('5', index)}}
          div {{formatDollars(bestPerfScenAmount('5', index))}}

  .scenario-panel__main.scenario-panel__footer(:class="{'scenario-not-ready' : !scenarioReady}")
    button.dashboard-panel__more-button.mgn-r-30(@click='gotoScenarios')
      | Edit Scenarios
    button.dashboard-panel__more-button(@click='viewReports')
      | View Reports
</template>

<script>
import axios from "axios";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  name: 'scenarioPanel.vue',
  data() {
    return {
      bestPerforming: undefined
    }
  },
  computed: {
    profile: function() { return this.$store.state.profile },
    progress: function () { return this.profile.progress },
    scenarioReady: function () { return this.progress & 15 === 15 },
    bestPerforming1: function () { return this.bestPerforming ? this.bestPerforming['1'] : [] },
    bestPerforming5: function () { return this.bestPerforming ? this.bestPerforming['5'] : [] }
  },
  methods: {
    bestPerfScenName: function (year, index) {
      return this.bestPerforming ? this.bestPerforming[year][index][0] : ''
    },
    bestPerfScenAmount: function (year, index) {
      return this.bestPerforming ? this.bestPerforming[year][index][1] : ''
    },
    gotoScenarios: function () {
      this.$router.push("scenarios")
    },
    viewReports: function () {
      this.$router.push("reports")
    }
  },
  mounted: function () {
    axios.get("/api/scenarios/best_performing").then((response) => {
      this.bestPerforming = response.data;
    })
  },
  components: {}
}
</script>

<style lang="scss">
.v-scenario-panel {
  font-size: 15px;
  .scenario-panel__main {
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 5px;
    padding: 8px;
    border-radius: 10px;
    display: flex;
  }
  .scenario-panel__desc {
    background-color: #C5DDED;
  }
  .scenario-panel__best {
    background-color: #EEF8EE;
    margin-top: 10px;
  }
  .scenario-panel-bar {
    width: 4px;
    height: auto;
    margin-right: 5px;
    border-radius: 3px;
  }
  .scenario-panel-bar--blue {
    background-color: #0D72B3;
  }
  .scenario-panel-bar--green {
    background-color: #01A101;
  }
  .scenario-panel-right {
    width: 100%;
  }
  .scenario-panel__best-performing {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .scenario-panel__scenario {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    background-color: #A9DBA9;
    padding: 0px 6px;
    border-radius: 10px;
    align-items: center;
  }
  .scenario-panel-action-button {

  }
  .scenario-not-ready {
    opacity: 0.3;
  }
}
</style>