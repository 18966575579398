<template lang="pug">
.v-about
  .about-main
    .about-section
      h2 The beginning
      p Fyffle started as a concept many years before it was released into the real world. Whenever I would have a major financial decision to make like buying a house or car, or moving overseas, I would pull up an Excel spreadsheet and enter all my financial data, and create my own scenarios to compare and see which was best for me.
      p Now, many years later, it is finally a website that I can use, and I have shared it with others.

      .about-img-container
        img(src='/images/future_forecast_spreadsheet.png' width='80%')

      h2.pad-t-30 The product
      p Fyffle is a tool that can be used to predict future financial path based on your current assets, liabilities, incomes and expenses. You can make it as simple or detailed as you want. You can easily change things in the future by using scenarios to forecast which is the best decision for you.
      p I hope you too can find this a useful tool in determining good financial decisions based on future financial forecasting. This should be used as a guide only, it is impossible to predict the future.

      .about-img-container
        img(src='/images/future_forecast.png' width='80%')

      h2.pad-t-30 What it is not
      p Fyffle is not an accounting software or budgeting software, there are plenty of those around already. But you could use Fyffle to help in those areas if you wanted to.

</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: 'about.vue',
  mounted: function () {
    useHead({
      title: 'About Fyffle',
      meta: [
        { name: 'description', content: 'Fyffle company details' },
      ],
    })
  },
  components: {
  }
}
</script>

<style lang="scss">
@import "../../assets/stylesheets/_variables.scss";
.v-about {
  .about-main {
    padding: 0 20px;

    .about-section {
      padding-top: 20px;
      p {
        padding-top: 10px;
        padding-bottom: 30px;
      }
    }

    .about-img-container {
      display: flex;
      justify-content: center;
    }

    .img-container {
      display: flex;
      justify-content: center;
      img {
        border-radius: 30px;
      }
    }
  }
}
@media only screen and (min-width: $med-screen) {
  .v-about {
    .about-main {
      padding: 0 50px;
    }
  }
}
@media only screen and (min-width: $large-screen) {
  .v-about {
    .about-main {
      padding: 0 150px;
    }
  }
}

</style>