<template lang="pug">
.v-net-worth-report(v-if='isLoggedIn')
  .heading-1
    | Net Worth Report

  .report_div.pad-t-10(v-if='netWorthChartData' )
    p
      | The Net Worth is the sum of all the Asset and Liability balances. In this chart the value is averaged per month.
    p.pad-t-20
      | This report depends on the
      a(href='/report/future-forecast-report')  Future Forecast Report.

  .report_div.pad-t-10(v-if='netWorthChartData' )
    .chart-container
      Line(:data='netWorthChartData' :options='chartOptions')
  .report_div.pad-t-10(v-else )
    p
      | You must generate the Future Forecast report before you can see this report.

  MoneyBar
</template>

<script>

import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const cdata = {
  "labels": [],
  "datasets":[]
}

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'netWorthReport.vue',
  data() {
    return {
      netWorthChartData: cdata,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right'
          }
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    loadNetWorthChart: function () {
      axios.get("/api/reports/net_worth_chart_data").then((response) => {
        this.netWorthChartData = response.data
      })
    }
  },
  mounted: function () {
    this.loadNetWorthChart()
  },
  components: {
    Line,
    MoneyBar
  }
}
</script>

<style lang="scss">
.v-net-worth-report {
  .chart-container {
    height: 400px;
  }
}
</style>