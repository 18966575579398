<template lang="pug">
.v-transfers(v-if='isLoggedIn')
  .heading-1
    | Banking Transfers
  .transfers__intro
    .money__intro_p
      | Transferring money from one asset or liability to another. This could be to simply move money around, or help pay off an expense.
  table.simple-table(v-if='hasTransfers')
    tr
      th Name
      th Start Date
      th End Date
      th Amount
      th From Asset
      th From Liability
      th To Asset
      th To Liability
      th Frequency
      th Actions
    tr(v-for='transfer in transfers' :value='transfer.name')
      td {{transfer.name}}
      td.date-cell {{transfer.start_date}}
      td.date-cell {{transfer.end_date}}
      td.number-cell {{formatNumber(transfer.amount)}}
      td {{transfer.from_asset}}
      td {{transfer.from_liability}}
      td {{transfer.to_asset}}
      td {{transfer.to_liability}}
      td {{formatFrequency(transfer.frequency)}}
      td
        a(href='#' @click.prevent='editTransfer(transfer)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteTransfer(transfer)') Delete

  .money-button-bar.pad-b-30
    button.action_button(v-if='showProgressBtn' @click='progress("/")')
      | I do not have transfers
    button.action_button(@click='addTransfer')
      | Add new Transfer
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'Transfers',
  data() {
    return {
      transfers: undefined
    }
  },
  computed: {
    hasTransfers: function () {
      return this.transfers != null && this.transfers.length > 0
    },
    progressTransfers: function () { return (this.profile.progress & 16) > 0 },
    showProgressBtn: function () { return !this.progressTransfers && !this.hasTransfers },
  },
  methods: {
    addTransfer() {
      this.progress('/addEditTransfer')
    },
    done() {
      this.$router.push('/')
    },
    deleteTransfer(transfer) {
      Swal.fire({
        title: "Delete Transfer",
        text: 'Are you sure you want to delete transfer '+transfer.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/transfers/"+transfer.id).then((response) => {
            this.loadTransfers()
          })
        }
      })
    },
    editTransfer(transfer) {
      this.$router.push(`/addEditTransfer/${transfer.id}`)
    },
    loadTransfers() {
      axios.get("/api/transfers").then((response) => {
        this.transfers = response.data
      });
    },
    progress(url) {
      axios.put("/user/progress?flag=5").then((response) => {
        const progress = response.data
        this.$store.commit('updateUserProgress', progress)
        this.$router.push(url)
      });
    }
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Transfers',
      meta: [
        { name: 'description', content: 'A collection of transfers from one asset/liability to another' },
      ],
    })
    this.loadTransfers()
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>