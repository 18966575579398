<template lang="pug">
.v-financial-position
  .heading-1
    | Current Financial Position
  .financial-position__intro
    | There are 5 parts to your financial position that we need to run the scenarios. They are:
    ul
      li
        b Assets
        br
        | An asset is resource with economic value that you own. Assets can be tangible, such as physical items like bank accounts, real estate, cars, boats, machinery, equipment, inventory, or cash, or intangible, such as intellectual property, patents, trademarks, goodwill, or investments like stocks and bonds.
      li
        b Liabilities
        br
        | A liability refers to a financial obligation or debt that you have. Liabilities can take various forms, such as loans, accounts payable, accrued expenses, mortgages.
      li
        b Incomes
        br
        | Income refers to the money or financial gains received by an individual, company, or entity as a result of providing goods, services, investments, or through other sources such as interest, dividends, rents, royalties, or capital gains.
      li
        b Expenses
        br
        | An expense refers to the costs incurred or the resources used by an individual, company, or entity in order to generate revenue or operate a business. It represents the outflow or depletion of assets, usually in the form of money, to acquire goods, services, or assets necessary for ongoing operations.
      li
        b Banking Transfers
        br
        | Transferring money from one asset or liability to another
  .financial-position__buttons.section2
    | It is important you enter your current financial position. Future plans can be created through scenarios.
    br
    | Click on one of the buttons below to start entering your current financial status.
  .financial-position__buttons.section2
    | Not sure where to start. Follow the&nbsp;
    span
    a(href='/learn/getting-started') Getting Started Guide

  .section1
    MoneyStatus
</template>

<script>
import MoneyStatus from "../components/moneyStatus.vue"

export default {
  name: 'currentFinancialPosition',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
  },
  components: {
    MoneyStatus
  }
}
</script>

<style lang="scss">
</style>