<template lang="pug">
.v-admin-dashboard.mgn-b-30(v-if='isAdmin')
  h2 Admin
  table.simple-table
    tr
      th
      th Last Hour
      th Last Day
      th Last Week
      th Last Month
      th Total
    tr
      th Users:
      td {{ users.last_hour }}
      td {{ users.last_day }}
      td {{ users.last_week }}
      td {{ users.last_month }}
      td {{ users.total }}
    tr
      th Assets:
      td {{ assets.last_hour }}
      td {{ assets.last_day }}
      td {{ assets.last_week }}
      td {{ assets.last_month }}
      td {{ assets.total }}
    tr
      th Scenarios:
      td {{ scenarios.last_hour }}
      td {{ scenarios.last_day }}
      td {{ scenarios.last_week }}
      td {{ scenarios.last_month }}
      td {{ scenarios.total }}
    tr
      th Job Runs:
      td {{ job_runs.last_hour }}
      td {{ job_runs.last_day }}
      td {{ job_runs.last_week }}
      td {{ job_runs.last_month }}
      td {{ job_runs.total }}
    tr
      th Job Average Time (ms):
      td {{ job_runs.hour_average }}
      td {{ job_runs.day_average }}
      td {{ job_runs.week_average }}
      td {{ job_runs.month_average }}
      td {{ job_runs.total_average }}
    tr
      th Size of database:
      td(colspan=5) {{ database.size }}
</template>

<script>
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";

export default {
  mixins: [PermissionsMixin],
  name: 'dashboard.vue',
  data() {
    return {
      stats: undefined,
      subFn: undefined
    }
  },
  computed: {
    users: function () { return (this.stats === undefined ? {} : this.stats.users ) },
    assets: function () { return (this.stats === undefined ? {} : this.stats.assets ) },
    scenarios: function () { return (this.stats === undefined ? {} : this.stats.scenarios ) },
    database: function () { return (this.stats === undefined ? {} : this.stats.database ) },
    job_runs: function () { return (this.stats === undefined ? {} : this.stats.job_runs ) },
  },
  methods: {
    loadStats: function () {
      axios.get("/api/admin/dashboard.json").then((response) => {
        this.stats = response.data
      });
    }
  },
  mounted: function () {
    this.subFn = this.$store.subscribe(mutation => {
      if (mutation.type === 'initProfile') {
        if (this.isAdmin === true) {
          this.loadStats()
        }
      }
    })
    if (this.isAdmin === true) {
      this.loadStats()
    }
  },
  beforeDestroy: function () {
    // Unsubscribe from mutations
    this.subFn();
  }
}
</script>

<style lang="scss">
</style>