<template lang="pug">
.v-learn
  .flex-grid
    .card-container(v-for="card in cards" :value="card.title")
      .card.cursor-pointer(@click='cardClick(card)')
        .card-title
          | {{ card.title }}
        .card-body
          | {{ card.desc }}
</template>

<script>
import { useHead } from "@unhead/vue";

const cards = [
  {
    title: "Getting Started",
    desc: "For newcomers to the site, how to get started",
    url: "getting-started"
  },
  {
    title: "Buy a House vs Renting Scenario",
    desc: "Am I better off renting or buying a house? Can I afford to buy a house? Instructions on how to create a scenario to buy a house if you are currently renting",
    url: "keep-renting-vs-buy-house"
  },
  {
    title: "Pay off Mortgage or Invest?",
    desc: "Am I better off investing my spare income or pay off my Mortgage",
    url: "pay-off-mortgage-or-invest"
  },
]

export default {
  name: 'learn.vue',
  data() {
    return {
      cards: cards
    }
  },
  methods: {
    card_url: function (card) {
      return "/learn/"+card.url;
    },
    cardClick: function (card) {
      this.$router.push(this.card_url(card));
    }

  },
  components: {
  },
  mounted: function () {
    useHead({
      title: 'Fyffle Learning',
      meta: [
        { name: 'description', content: 'A collection of guides to help you use Fyffle.'},
      ],
    })
  }
}
</script>

<style lang="scss">
@import "../../assets/stylesheets/_variables.scss";

.v-learn {
  .flex-grid {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    margin-top: 20px;
    margin-bottom: 30px;

    .card-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      background-color: var(--color-theme4);
      border-radius: 10px;

      .card {
        border-bottom: 5px solid #ddd;
        border-radius: 10px;
        overflow: clip;
        height: 100%;
        margin: 0;
        transition: 200ms ease;
        width: auto;
      }

      .card:hover {
        border-color: #CCC;
      }

      .image-container {
        position: relative;
        flex-shrink: 0;

        img {
          aspect-ratio: 3/1;
          height: auto;
          object-fit: cover;
          width: 100%;
        }
      }

      .card-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 16px;
        padding: 24px;
      }

      .card-title {
        height: 60px;
        font-size: 20px;
        font-weight: bold;
        background-color: var(--color-theme3);
        color: white;
        text-align: center;

        a {
          text-decoration: none;
          color: white
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }

}

@media only screen and (min-width: $med-screen) {
  .v-learn {
    .flex-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
@media only screen and (min-width: $large-screen) {
  .v-learn {
    .flex-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>