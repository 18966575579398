<template lang="pug">
.v-terms.mgn-b-30
  .legal-container
    h2.pad-t-10.pad-b-20 Terms and Conditions
    .legal-date
      | Date Updated: 14 July 2024
    .legal-heading
      | Parties
    LegalList(listType='number-brackets' :items='partiesList')
    .legal-heading
      | Background
    LegalList(listType='alpha-brackets' :items='backgroundList')
    LegalList.pad-t-20(listType='nested' :nestedItems='mainList')
</template>

<script>
import { useHead } from "@unhead/vue";
import LegalList from "@/pages/legalList.vue";

const partiesList = [
  'Fyffle, a company incorporated under the laws of Australia ("We"/"Us"/"Our").',
  'The user accessing or using the website located at https://fyffle.com/ ("You"). "You" means the person, company or organisation accessing or using the Website. By accessing or using the Website, You agree to be bound by the terms of this agreement.'
]

const backgroundList = [
  'Fyffle operates the website located at <a href="https://fyffle.com">https://fyffle.com/</a> (the "Website").',
  'The Website allows users to manually enter their financial details such as income, expenses, assets and liabilities. Users can then create scenarios by changing values to forecast future financial scenarios, such as the ability to afford a house. Users can generate reports showing all scenarios.',
  'The Website and its services are for personal use only. The Website does not connect to any banks or third party sites. The only personal information collected by Us is your email address.',
  'By accessing or using the Website, you agree to be bound by the terms set out in this agreement.'
]

const mainList = [
  {type: 'head', value: 'Definitions'},
  {type: 'item', value: '<b>Website</b> means the website located at <a href="https://fyffle.com">https://fyffle.com/</a> and any successor or related website operated by Fyffle.'},
  {type: 'item', value: '<b>Services</b> means the financial forecasting tool and services provided on the Website as described in the Background section, including the ability for users to manually enter financial details to forecast future scenarios and generate reports.'},
  {type: 'item', value: '<b>Content</b> means any material, information, communications, software, photos, videos, graphics or other material submitted, posted or displayed on the Website by users, but excludes Personal Information.'},
  {type: 'item', value: '<b>Personal Information</b> has the meaning given to it in the Privacy Act 1988 (Cth).'},
  {type: 'item', value: 'A reference to a party includes that party\'s personal representatives, successors and permitted assigns.'},
  {type: 'head', value: 'Access and Use'},
  {type: 'item', value: 'You represent that you are at least 18 years old, and have the legal capacity and authority to enter into this agreement.'},
  {type: 'item', value: 'To access certain Services, you must register for an account by providing your email address.'},
  {type: 'item', value: 'You are responsible for maintaining confidentiality of your password. You are responsible for all activities that occur under your password or account.'},
  {type: 'item', value: 'You agree to only use the Website and Services for your personal, non-commercial use. You must not use the Website or Services for any commercial or business purposes.'},
  {type: 'item', value: 'You must not use the Website or Services for any illegal, fraudulent, malicious or harmful purpose. This includes collecting information about others without consent.'},
  {type: 'item', value: 'We do not guarantee that the Website or Services will be continuously available or error-free. Access may be interrupted for maintenance, updates or other reasons.'},
  {type: 'item', value: 'You agree to comply with all applicable laws and third party rights when using the Website and Services. You are solely responsible for all of your Content and activities on the Website.'},
  {type: 'item', value: 'We may suspend or terminate your access if we believe you have breached this agreement.'},
  {type: 'head', value: 'User Content'},
  {type: 'item', value: 'Licensing of Content. By submitting, posting or displaying any content on or through the Website, you grant to Us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such content in any and all media or distribution methods now known or later developed.'},
  {type: 'item', value: 'Ownership of Content. You retain ownership and all intellectual property rights in any content submitted, posted or displayed on the Website.'},
  {type: 'item', value: 'Acceptable Use of Content. You represent and warrant that you own or have the necessary licenses and rights to use and authorise Us to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any content, and that such content complies with all applicable laws.'},
  {type: 'item', value: 'Removal of Content. We may review, modify, delete or remove any content from the Website, for any reason or no reason, with or without notice to you.'},
  {type: 'item', value: 'No Responsibility for Content. We do not take any responsibility for the accuracy, completeness or legality of any user content submitted on the Website. The views expressed by users on the Website do not represent our views or values.'},
  {type: 'item', value: 'Privacy of Content. We will handle any personal information contained in your content in accordance with our Privacy Policy.'},
  {type: 'head', value: 'Intellectual Property'},
  {type: 'item', value: 'Ownership of intellectual property rights<br><br>All intellectual property rights in the Website and its content belong to and are owned by Us. This includes registered and unregistered rights.'},
  {type: 'item', value: 'License to use <br><br>We grant you a limited, non-exclusive and revocable license to access and use the Website for your personal, non-commercial use only.'},
  {type: 'item', value: 'Restrictions on use'},
  {type: 'subitem', value: 'You must not copy, modify, publish, transmit, participate or sell any content from the Website without our prior written consent.'},
  {type: 'subitem', value: 'You must not remove or modify any copyright or other proprietary notices.'},
  {type: 'item', value: 'User content'},
  {type: 'subitem', value: 'You retain ownership of any intellectual property rights you own in your user content.'},
  {type: 'subitem', value: 'By submitting any user content, you grant Us a perpetual, irrevocable, worldwide, royalty-free license to use that content for the purposes of operating the Website.'},
  {type: 'item', value: 'Third party rights<br><br>This agreement does not affect the ownership of intellectual property rights held by third parties.'},
  {type: 'head', value: 'Privacy'},
  {type: 'item', value: 'Personal information collected. The only personal information We collect is your email address.'},
  {type: 'item', value: 'Use of personal information. Your email address will only be used to provide the services available through the Website and to contact You regarding the Website, such as service announcements and administrative messages. We will not use your email address for marketing purposes without your express consent.'},
  {type: 'item', value: 'Storage of personal information. Personal information collected via the Website will be stored securely on servers located in India.'},
  {type: 'item', value: 'Access and correction. You may access, correct, or request deletion of your personal information by contacting Us at the email address set out on the Website, or you can delete your account yourself online. Please note that account deletion requests processed online will result in the account being deactivated immediately but the actual deletion of your data will occur after 30 days. We will respond to any direct requests within 30 days.'},
  {type: 'item', value: 'Disclosure of personal information. We will not disclose your personal information to any third party other than as necessary to provide the services, comply with laws or regulations, or protect the rights, property, or safety of Us, our users, or others.'},
  {type: 'item', value: 'Privacy policy. Our collection and handling of personal information is also governed by our Privacy Policy located on the Website.'},
  {type: 'item', value: 'Compliance with laws. We will handle all personal information in accordance with the Privacy Act 1988 (Cth), the Australian Privacy Principles, and any other applicable privacy and data protection laws.'},
  {type: 'head', value: 'Disclaimer of Warranties'},
  {type: 'item', value: 'We make no representations or warranties regarding the accuracy, completeness or timeliness of any information, content, services or products provided on or through the use of the Website. Any reliance you place on such information, content, services or products is strictly at your own risk.'},
  {type: 'item', value: 'We do not represent or warrant that your access to the Website will be uninterrupted or error-free. You acknowledge that such access may be interrupted, unavailable or incomplete from time to time for various reasons.'},
  {type: 'item', value: 'We make no representations or warranties regarding the functionality or suitability of the Website or any services, tools or content accessible via the Website for any particular purpose. Use of the Website and any such services, tools or content is at your own risk.'},
  {type: 'item', value: 'We are not responsible for and do not represent or warrant that the Website or its server(s) are free of viruses or other harmful components. It is your responsibility to implement sufficient procedures and virus checks (including anti-virus and other security checks) to satisfy your particular requirements for the accuracy of data input and output.'},
  {type: 'item', value: 'Any third party content accessible directly or indirectly via a hypertext link is not under our control and we do not endorse or make any representations about such third party content or third party websites. It is your own responsibility to take precautions to ensure that whatever you select for your use is free of viruses and other items of a destructive nature.'},
  {type: 'item', value: 'Any information, tools or scenarios generated by the Website tools do not constitute financial advice. You should seek independent financial advice regarding your personal circumstances before making any financial decisions.'},
  {type: 'head', value: 'Limitation of Liability'},
  {type: 'item', value: 'All implied warranties and conditions are excluded from this agreement to the fullest extent permitted by law.'},
  {type: 'item', value: 'Our liability for loss or damage is limited to resupplying you with the services or paying the costs of having the services resupplied, at our option. We are not liable for any loss, damage, cost or expense whether direct, indirect, incidental, special, consequential or economic arising from your use of the website or any content on it.'},
  {type: 'item', value: 'You use all third party content links, products and services available through our website at your own risk. We make no representation or warranty about the content or accuracy of any third party websites linked to from our website.'},
  {type: 'item', value: 'In no event will we be liable for any loss of profits, loss of business, loss of data or any indirect or consequential loss.'},
  {type: 'item', value: 'We are not responsible or liable for any user content, loss or removal of uploads, altered, incorrect or incomplete user content or any claims resulting from user content.'},
  {type: 'item', value: 'You use our website at your sole risk and rely on your own enquiries and judgment. We are not providing financial advice to you.'},
  {type: 'item', value: 'You agree to fully indemnify, defend and hold us and our officers, directors, employees, agents and third parties harmless immediately on demand, from and against all claims, liability, damages, losses, costs and expenses, including reasonable legal fees, arising out of or in connection with your use of our website, your violation of this agreement or your violation of any laws or third party rights.'},
  {type: 'head', value: 'Termination'},
  {type: 'item', value: 'Termination by us. We may terminate this agreement at any time by giving you 30 days\' notice in writing. We may immediately terminate this agreement if you breach any term of this agreement.'},
  {type: 'item', value: 'Termination by you. You may terminate this agreement at any time by closing your account and ceasing use of the Website.'},
  {type: 'item', value: 'Post-termination obligations. Upon termination of this agreement:'},
  {type: 'subitem', value: 'Your right to access and use the Website ceases immediately.'},
  {type: 'subitem', value: 'We may delete any content or personal information associated with your account.'},
  {type: 'subitem', value: 'Clauses intended to survive termination will continue in full force, including intellectual property, limitation of liability, governing law and jurisdiction.'},
  {type: 'item', value: 'No refunds. Termination of this agreement does not entitle you to any refund of fees paid to access the Website or services.'},
  {type: 'head', value: 'General'},
  {type: 'item', value: 'Governing law. This agreement is governed by the laws of Australia.'},
  {type: 'item', value: 'Jurisdiction. Any dispute arising out of or in connection with this agreement shall be subject to the exclusive jurisdiction of the courts of Australia.'},
  {type: 'item', value: 'Severability. If any part of this agreement is found to be invalid or unenforceable, the remaining terms shall continue in full force and effect.'},
  {type: 'item', value: 'No waiver. No failure or delay by us to exercise any right or remedy under this agreement shall be construed or operate as a waiver of that right or remedy nor shall any single or partial exercise of any right or remedy preclude any further exercise.'},
  {type: 'item', value: 'Variation. We may vary the terms of this agreement by providing notice to you by email or on the Website. Your continued use of the Website following notice of any changes will amount to your acceptance of the revised terms.'},
  {type: 'item', value: 'Assignment. We may assign our rights and obligations under this agreement to any third party.'},
  {type: 'item', value: 'Survival. Terms that either are expressed to survive termination or by their nature or context are intended to survive termination shall remain in full force and effect.'},
  {type: 'item', value: 'Entire agreement. This agreement constitutes the entire agreement between you and us regarding its subject matter and supersedes all prior agreements, arrangements, dealings or discussions between you and us.'},
  {type: 'head', value: 'Changes to Terms'},
  {type: 'item', value: 'We may change these terms at any time by updating this agreement on the Website.'},
  {type: 'item', value: 'You will be notified of any material changes through a notice on the Website.'},
  {type: 'item', value: 'By continuing to use or access the Website after any changes come into effect, you agree to be bound by the updated terms.'},
  {type: 'item', value: 'Any updated terms will take effect from the date they are published on the Website.'},
  {type: 'item', value: 'We will maintain access to prior versions of these terms on the Website for your reference.'},
  {type: 'item', value: 'If you do not agree with any updated terms, you may terminate this agreement by ceasing use of the Website.'},
  {type: 'head', value: 'Contact'},
  {type: 'item', value: 'Queries about the website, services or terms should be directed to the Support team at <a href="mailto:support@fyffle.com">support@fyffle.com</a>. Privacy or security issues should be directed to the Privacy team at <a href="mailto:support@fyffle.com">support@fyffle.com</a>.'},
  {type: 'item', value: 'We aim to respond to all queries within 2 business days, except for privacy or security issues which will be responded to within 1 business day.'},
  {type: 'item', value: 'For quality and training purposes, communications may be recorded or monitored. Records will be retained for a period of 7 years.'},
  {type: 'item', value: 'If you have a complaint or dispute, you may escalate it internally by contacting the Support team. If the dispute cannot be resolved, you may refer it to the Telecommunications Industry Ombudsman as a last resort. This agreement has been entered into on the date stated at the beginning of it.'},
]

export default {
  name: 'termsConditions.vue',
  data() {
    return {
      partiesList: partiesList,
      backgroundList: backgroundList,
      mainList: mainList,
    }
  },
  computed: {},
  methods: {},
  mounted: function () {
    useHead({
      title: 'Fyffle Terms & Conditions',
      meta: [
        { name: 'description', content: 'Fyffle Terms & Conditions' },
      ],
    })
  },
  components: {
    LegalList
  }
}
</script>

<style lang="scss">
</style>