<template lang="pug">
.v-contact.mgn-b-30
  h2.pad-t-10.pad-b-20 Contact
  span.pad-t-10.pad-r-5.mgn-b-30 For any queries contact us at
  span
    a(href="mailto:support@fyffle.com") support@fyffle.com

</template>

<script>
import { useHead } from "@unhead/vue";

export default {
  name: 'contact.vue',
  mounted: function () {
    useHead({
      title: 'Contact Fyffle',
      meta: [
        { name: 'description', content: 'Fyffle contact details' },
      ],
    })
  },
  components: {
  }
}
</script>

<style lang="scss">
.v-contact {
  .container {
    padding: 60px;
  }
}

</style>