<template lang="pug">
.v-bottom-bar.mgn-t-40
  .bottom-bar
    .bottom-bar-column.bottom-bar-column__left
      h3 Company
      router-link.nav-link(to='/') Home
      router-link.nav-link(to='/about') About
      router-link.nav-link(to='/pricing') Pricing
      router-link.nav-link(to='/contact') Contact
    .bottom-bar-column
      h3 Resources
      router-link.nav-link(to='/learn') Learn
    .bottom-bar-column.bottom-bar-column__right
      h3 Legal
      router-link.nav-link(to='/termsConditions') Terms & Conditions
      router-link.nav-link(to='/privacyPolicy') Privacy Policy
      // router-link.nav-link(to='/security') Security
</template>

<script>

export default {
  name: 'bottomBar.vue',
  data() {
    return {}
  },
}
</script>

<style lang="scss">
</style>