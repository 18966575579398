<template lang="pug">
.v-asset-liability-table(:ref='refTable')
  .heading-3.mgn-t-40
    | {{ typeStrPlural }}
    button.small_button.mgn-l-50(@click='addAsset')
      | Add {{ typeStr }}
  table.simple-table
    thead
      tr
        th Type
        th Name
        th Start
        th Details
        th New Action
    tbody
      tr(v-for='item,index in assetItems' :value='index' :class="{'scenario-row' : isScenario(item), 'non-scenario-row' : !isScenario(item), 'asset-row' : isTypeAssetLiability(item)}")
        td {{item.type}}
        td {{item.name}}
        td.date-cell {{item.start}}
        td {{item.details}}
        td
          a(v-if='showChange(item)' href='#' @click.prevent="addChange(item)") Change
          a(v-if='showIncome(item)' href='#' @click.prevent="addIncome(item)")  Income
          a(v-if='showExpense(item)' href='#' @click.prevent="addExpense(item)")  Expense
          a(v-if='showEdit(item)' href='#' @click.prevent="editItem(item)")  Edit
          a(v-if='showDelete(item)' href='#' @click.prevent="deleteItem(item)")  Delete

</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";

export default {
  props: ['assetItems', 'isAsset','scenarioId'],
  name: 'assetLiabilityTable',
  data() {
    return {}
  },
  computed: {
    typeStr: function () { return this.isAsset ? 'Asset' : 'Liability'},
    typeStrPlural: function () { return this.isAsset ? 'Assets' : 'Liabilities'},
    refTable: function () { return this.typeStr + "Ref" }
  },
  methods: {
    showChange: function (item) {
      return !this.isScenario(item) && (this.isTypeAssetLiability(item) || (this.isTypeIncomeExpense(item) && !this.isScenario(item)) )
    },
    showIncome: function (item) {
      return !this.isScenario(item) && this.isTypeAssetLiability(item)
    },
    showExpense: function (item) {
      return !this.isScenario(item) && this.isTypeAssetLiability(item)
    },
    showDelete: function (item) {
      return this.isScenario(item) && (
          item.type === 'Asset' || item.type === 'Asset Change' ||
          item.type === 'Asset Income' || item.type === 'Asset Expense' ||
          item.type === 'Asset Income Change' || item.type === 'Asset Expense Change' ||
          item.type === 'Liability' || item.type === 'Liability Change' ||
          item.type === 'Liability Income' || item.type === 'Liability Expense' ||
          item.type === 'Liability Income Change' || item.type === 'Liability Expense Change' ||
          item.type === 'Income Change' || item.type === 'Expense Change')
    },
    showEdit: function (item) {
      return this.isScenario(item) && this.isTypeAssetLiability(item)
      // TODO later
      // return item.scenario_id != null && (item.type == 'Asset Change' || item.type == 'Asset Income' || item.type == 'Asset Expense')
    },
    addChange: function (item) {
      if (this.isTypeAssetLiability(item)) {
        this.$router.push('/scenario'+this.typeStr+'Change/'+this.scenarioId+"/"+item.id)
      } else if (this.isTypeIncomeExpense(item)) {
        let assLibId = item.asset_id || item.liability_id
        if (item.type === 'Asset Income' || item.type === 'Liability Income') {
          this.$router.push('/scenario'+this.typeStr+'IncomeChange/'+this.scenarioId+"/"+assLibId+"/"+item.id)
        } else if (item.type === 'Asset Expense' || item.type === 'Liability Expense') {
          this.$router.push('/scenario'+this.typeStr+'ExpenseChange/'+this.scenarioId+"/"+assLibId+"/"+item.id)
        }
      }
    },
    addIncome: function (assetLiab) {
      this.$router.push('/scenario'+this.typeStr+'Income/'+this.scenarioId+"/"+assetLiab.id)
    },
    addExpense: function (assetLiab) {
      this.$router.push('/scenario'+this.typeStr+'Expense/'+this.scenarioId+"/"+assetLiab.id)
    },
    addAsset: function () {
      this.$router.push('/addEdit'+this.typeStr+'?scenario_id='+this.scenarioId)
    },
    editItem: function (item) {
      // TODO later. Must make the assetIncomeExpense.vue file editable
      if (item.type === 'Asset' || item.type === 'Liability' ) {
        this.$router.push('/addEdit'+this.typeStr+'/'+item.id+'?scenario_id='+this.scenarioId)
      }
      // if (item.type == 'Asset Income') {
      //   this.$router.push('/scenarioAssetIncome/'+this.scenarioId+"/"+item.asset_id+"/"+item.id)
      // }
    },
    deleteItem: function (item) {
      Swal.fire({
        title: "Delete "+item.type,
        text: 'Are you sure you want to delete '+item.type+' '+item.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.type === 'Asset Change' || item.type === 'Liability Change' ||
              item.type === 'Income Change' || item.type === 'Expense Change' ||
              item.type === 'Asset Income Change' || item.type === 'Asset Expense Change' ||
              item.type === 'Liability Income Change' || item.type === 'Liability Expense Change' ) {
            axios.delete("/api/money_changes/" + item.id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Asset') {
            axios.delete("/api/assets/" + item.id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Asset Income') {
            axios.delete("/api/asset_incomes/" + item.id+"?asset_id="+item.asset_id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Asset Expense') {
            axios.delete("/api/asset_expenses/" + item.id+"?asset_id="+item.asset_id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Liability') {
            axios.delete("/api/liabilities/" + item.id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Liability Income') {
            axios.delete("/api/liability_incomes/" + item.id+"?liability_id="+item.liability_id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Liability Expense') {
            axios.delete("/api/liability_expenses/" + item.id+"?liability_id="+item.liability_id).then((response) => {
              this.$emit('reload')
            })
          }

        }
      })

    },
    isScenario: function (item) {
      return (item.scenario_id != null)
    },
    isTypeAssetLiability: function (item) {
      return (item.type === 'Asset' || item.type === 'Liability')
    },
    isTypeIncomeExpense: function (item) {
      return (
          item.type === 'Asset Income' || item.type === 'Asset Expense' ||
          item.type === 'Liability Income' || item.type === 'Liability Expense'
      )
    },
    maybeScroll() {
      if (this.$route.query && this.$route.query.compEdited) {
        let compEdited = this.$route.query.compEdited.split('_')
        let compType = compEdited[0]
        if ((this.isAsset && compType === 'Asset') || (!this.isAsset && compType === 'Liability')) {
          setTimeout(() => this.scrollToElement(), 200)
        }
      }
    },
    scrollToElement() {
      let el = this.isAsset ? this.$refs.AssetRef : this.$refs.LiabilityRef;
      if (el) {
        el.scrollIntoView({ behavior: "instant" });
      }
    },

  },
  mounted: function () {
  },
  components: {},
  watch: {
    "assetItems": function () {
      this.maybeScroll()
    },
  }
}
</script>

<style lang="scss">
</style>