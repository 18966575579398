<template lang="pug">
.v-scenarios(v-if='isLoggedIn')
  .heading-1
    | Scenarios
  .scenarios__intro
    .money__intro_p
      | A scenario is where you can test your future financial ideas. In a scenario you can enter future changes in your finances and compare to your existing finances, forecasting into the future.
    .money__intro_p
      | It is <b>recommended</b> that you first <b>create a scenario with no changes</b> in it, so you can compare your current progress by keeping things as you have right now without any changes. To do this click on the <b>Add new Scenario</b> button below, call the scenario something like "No changes", and click <b>Save</b>.

    .money__intro_p
      | Here are some ideas to help you with creating your own scenarios:
      ul.pad-l-40
        li
          a(href='/learn/keep-renting-vs-buy-house')
            | Buy a new house
        li
          a(href='/learn/pay-off-mortgage-or-invest')
            | Pay off my loan quicker
        li
          a(href='/learn/keep-renting-vs-buy-house')
            | Put extra money into investments
        li
          | Put extra money in a term deposit
        li
          | Move in with family

    .money__intro_p
      | Once you have created all your scenarios, you will want to generate a
      a(href='/report/future-forecast-report')  Future Forecast report
      |  to compare each of the scenarios.



  table.simple-table(v-if='hasScenarios')
    tr
      th Name
      th Description
      th Actions
    tr(v-for='scenario in scenarios' :value='scenario.name')
      td {{scenario.name}}
      td {{scenario.description}}
      td
        a(href='#' @click.prevent='editScenario(scenario)') Edit
        a.pad-l-20(href='#' @click.prevent='deleteScenario(scenario)') Delete

  .money-button-bar.pad-b-30
    button.action_button(@click='addScenario')
      | Add new Scenario
    button.action_button(@click='gotoReport')
      | Reports
    button.action_button(@click='done')
      | Done
  MoneyBar
</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [PermissionsMixin],
  name: 'scenarios',
  data() {
    return {
      scenarios: undefined
    }
  },
  computed: {
    hasScenarios: function () {
      return this.scenarios != null && this.scenarios.length > 0
    },
    profile: function() { return this.$store.state.profile },
    progress: function () { return this.profile ? this.profile.progress : '' }
  },
  methods: {
    addScenario() {
      axios.post("/api/scenarios/create_blank").then((response) => {
        let scenario = response.data
        this.$store.commit('updateUserProgress', this.progress | 32)
        this.$router.push('/addEditScenario/'+scenario.id+'?add=true')
      });
    },
    done() {
      this.$router.push('/')
    },
    gotoReport() {
      this.$router.push('/reports')
    },
    deleteScenario(scenario) {
      Swal.fire({
        title: "Delete Scenario",
        text: 'Are you sure you want to delete scenario '+scenario.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          axios.delete("/api/scenarios/"+scenario.id).then((response) => {
            this.loadScenarios()
          })
        }
      })
    },
    editScenario(scenario) {
      this.$router.push(`/addEditScenario/${scenario.id}`)
    },
    loadScenarios() {
      axios.get("/api/scenarios").then((response) => {
        this.scenarios = response.data
      });
    }

  },
  mounted: function () {
    useHead({
      title: 'Fyffle Scenarios',
      meta: [
        { name: 'description', content: 'Scenarios you created for future forecasting' },
      ],
    })
    this.loadScenarios()
  },
  components: {
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>