<template lang="pug">
.v-home
  Welcome(v-if="!profile")
  Dashboard(v-if="profile")
</template>

<script>
// import Navbar from "~/navbar.vue";
import Welcome from "./welcome.vue";
import Dashboard from "../dashboard/dashboard.vue";

export default {
  computed: {
    profile: function() { return this.$store.state.profile }
  },
  methods: {
    getStarted() {
    }
  },
  components: {
    Welcome,
    Dashboard
  }

}
</script>

<style lang="scss">
</style>