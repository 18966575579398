<template lang="pug">
.v-transfer-table
  .heading-3.mgn-t-40(:ref='refTable')
    | {{ typeStrPlural }}
    button.small_button.mgn-l-50(@click='addTransfer')
      | Add Transfer
  table.simple-table
    thead
      tr
        th Type
        th Name
        th Start
        th Amount
        th Frequency
        th From
        th To
        th New Action
    tbody
      tr(v-for='item,index in transferItems' :value='index' :class="{'scenario-row' : isScenario(item), 'non-scenario-row' : !isScenario(item), 'transfer-row' : isTypeTransfer(item)}")
        td {{item.type}}
        td {{item.name}}
        td.date-cell {{item.start}}
        td.number-cell {{formatNumber(item.amount)}}
        td {{formatFrequency(item.frequency)}}
        td {{item.from}}
        td {{item.to}}
        td
          a(v-if='showChange(item)' href='#' @click.prevent="addChange(item)") Change
          a(v-if='showEdit(item)' href='#' @click.prevent="editItem(item)")  Edit
          a(v-if='showDelete(item)' href='#' @click.prevent="deleteItem(item)")  Delete

</template>

<script>

import Swal from "sweetalert2";
import axios from "axios";
import CommonMixin from "@/components/commonMixin.vue";

export default {
  mixins: [CommonMixin],
  props: ['transferItems', 'isTransfer','scenarioId'],
  name: 'transferTable',
  data() {
    return {}
  },
  computed: {
    typeStr: function () { return 'Transfer' },
    typeStrPlural: function () { return 'Transfers' },
    refTable: function () { return "TransferRef" }
  },
  methods: {
    showChange: function (item) {
      return !this.isScenario(item) && this.isTypeTransfer(item)
    },
    showDelete: function (item) {
      return this.isScenario(item)
    },
    showEdit: function (item) {
      return this.isScenario(item) && this.isTypeTransfer(item)
      // TODO later
    },
    addChange: function (item) {
      this.$router.push('/scenarioTransferChange/'+this.scenarioId+"/"+item.id)
    },
    addTransfer: function () {
      this.$router.push('/addEditTransfer?scenario_id='+this.scenarioId)
    },
    editItem: function (item) {
      this.$router.push('/addEditTransfer/'+item.id+'?scenario_id='+this.scenarioId)
    },
    deleteItem: function (item) {
      Swal.fire({
        title: "Delete "+item.type,
        text: 'Are you sure you want to delete '+item.type+' '+item.name+"?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete'
      }).then((result) => {
        if (result.isConfirmed) {
          if (item.type === 'Transfer Change') {
            axios.delete("/api/money_changes/" + item.id).then((response) => {
              this.$emit('reload')
            })
          } else if (item.type === 'Transfer') {
            axios.delete("/api/transfers/" + item.id).then((response) => {
              this.$emit('reload')
            })
          }
        }
      })
    },
    isScenario: function (item) {
      return (item.scenario_id != null)
    },
    isTypeTransfer: function (item) {
      return (item.type === 'Transfer')
    },
    maybeScroll() {
      if (this.$route.query && this.$route.query.compEdited) {
        let compEdited = this.$route.query.compEdited.split('_')
        let compType = compEdited[0]
        if (compType === 'Transfer') {
          setTimeout(() => this.scrollToElement(), 200)
        }
      }
    },
    scrollToElement() {
      let el = this.$refs.TransferRef;
      if (el) {
        el.scrollIntoView({ behavior: "instant" });
      }
    },
  },
  mounted: function () {
  },
  components: {},
  watch: {
    "transferItems": function () {
      this.maybeScroll()
    },
  }
}
</script>

<style lang="scss">
</style>