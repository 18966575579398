<template lang="pug">
.v-add-edit-transfer(v-if='isLoggedIn')
  .form__panel
    .heading-1
      | {{addEditStr}} Transfer{{scenarioText}}
    AddEditForm(:fields='fields' :fieldValues='fieldValues' :oneOfTwoMandatoryFields='oneOfTwoMandatoryFields'  :adding='addingTransfer' @dataAdded="transferAdded" @dataEdited="transferEdited" @cancelled="cancelled" :addText='addText' :changes='newMoneyChanges' focusName="Transfer")
      ChangesForm(:fields='changeFields' :fieldValues='changeFieldValues' :moneyType='changeMoneyType' @postChanges='postTransferChanges')
  MoneyBar
</template>

<script>
import AddEditForm from "../components/addEditForm.vue"
import {clone} from "@/common/copies";
import FieldListsMixin from "@/components/fieldListsMixin.vue";
import ChangesForm from "@/components/changesForm.vue";
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  mixins: [FieldListsMixin, PermissionsMixin],
  props: {},
  name: 'addEditTransfer',
  data() {
    return {
      newMoneyChanges: [],
      fieldValues: {},
      changeFieldValues: [],
      oneOfTwoMandatoryFields: [['from_asset_id','from_liability_id'],['to_asset_id','to_liability_id']]
    }
  },
  computed: {
    transferId: function () { return this.$route.params.id },
    addingTransfer: function () { return (this.transferId === undefined) },
    addEditStr: function () { return (this.addingTransfer ? "Add" : "Edit") },
    addText: function () { return "Save Transfer"},
    changeMoneyType: function () { return "Transfer"},
    scenarioId: function () { let n = this.$route.query.scenario_id; return n ? Number(n) : undefined },
    scenarioText: function () { return this.scenarioId ? " for Scenario" : "" },
    fields: function() {
      return [
        {name: 'name', label: 'Name', type: 'string', mandatory: true},
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the transfer first occurs"},
        {name: 'end_date', label: 'End Date', type: 'date', help_text: "The date the transfer stops. Leave empty if it doesnt"},
        {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The amount that will be transferred"},
        {name: 'from_asset_id', label: 'From Asset', type: 'list', list_items: this.assets(this.scenarioId), help_text: "Which asset account will the transfer be taken from", oneOfTwoMandatoryField: true},
        {name: 'from_liability_id', label: 'From Liability', type: 'list', list_items: this.liabilities(this.scenarioId), help_text: "Which liability account will the transfer be taken from", oneOfTwoMandatoryField: true},
        {name: 'to_asset_id', label: 'To Asset', type: 'list', list_items: this.assets(this.scenarioId), help_text: "Which asset account will the transfer be put in to", oneOfTwoMandatoryField: true},
        {name: 'to_liability_id', label: 'To Liability', type: 'list', list_items: this.liabilities(this.scenarioId), help_text: "Which liability account will the transfer be put in to", oneOfTwoMandatoryField: true},
        {name: 'frequency', label: 'Frequency', type: 'list', mandatory: true, list_items: this.frequencies, help_text: "How often will the transfer occur"},
        {name: 'description', label: 'Description', type: 'text'},
        {name: 'changes', label: 'Changes', type: 'string', editable: false},
      ]
    },
    changeFields: function() {
      return [
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the change in transfer will occur"},
        {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new transfer amount"},
      ]
    },
    baseUrl: function () { return "/api/transfers"},
  },
  methods: {
    returnPageUrl: function (id) {
      return this.scenarioId ? "/addEditScenario/"+this.scenarioId+"?compEdited=Transfer_"+id : "/transfers"
    },
    prepareData: function (model) {
      let mcs = this.newMoneyChanges
      let data = {}
      data['money_transfer'] = model
      data['money_transfer']['money_changes'] = mcs
      data['money_changes'] = mcs
      if (this.scenarioId) {
        data['money_transfer']['scenario_id'] = this.scenarioId
        // All the money changes will include the scenario_id
        for (var index in mcs) {
          mcs[index]['scenario_id'] = this.scenarioId
        }
      }
      return data
    },
    transferAdded: function (model) {
      axios.post(this.baseUrl, this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    transferEdited: function (model) {
      axios.put(this.baseUrl+"/"+this.transferId,this.prepareData(model)).then((response) => {
        this.$router.push(this.returnPageUrl(response.data.id));
      });
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrl(this.transferId || ''))
    },
    loadTransfer: function (transferId) {
      axios.get(this.baseUrl + "/" + transferId).then((response) => {
        let transfer = response.data
        this.fieldValues = {
          // id: assetId,
          name: transfer['name'],
          start_date: transfer['start_date'],
          end_date: transfer['end_date'],
          amount: transfer['amount'],
          from_asset_id: transfer['from_asset_id'],
          from_liability_id: transfer['from_liability_id'],
          to_asset_id: transfer['to_asset_id'],
          to_liability_id: transfer['to_liability_id'],
          frequency: transfer['frequency'],
          description: transfer['description'],
        }
        this.changeFieldValues = transfer['money_changes']
        this.newMoneyChanges = []
        for (let i = 0; i < this.changeFieldValues.length; i++) {
          this.newMoneyChanges.push(this.changeFieldValues[i].money_changes)
        }
      });
    },
    postTransferChanges: function (data) {
      this.newMoneyChanges = clone(data)
    }

  },
  mounted: function () {
    if (this.transferId !== undefined) {
      this.loadTransfer(this.transferId)
    }
  },
  components: {
    AddEditForm,
    ChangesForm,
    MoneyBar
  },
  // watch: {
  //   "transfers": function () {
  //     this.loadTransfer();
  //   },
  // }
}
</script>

<style lang="scss">
</style>