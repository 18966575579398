<template lang="pug">
.v-after-inactive-signup
  h2.pad-t-10.pad-b-20 Fyffle Signup
  p.mgn-b-30 Congratulations, you have signed up to Fyffle! Now you need to confirm your registration by clicking on the <b>Confirm my account</b> link found in the email Fyffle has sent you. Then you can login.
</template>

<script>

export default {
  name: 'afterInactiveSignUp.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>
