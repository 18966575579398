<template lang='pug'>
.v-dashboard(v-if='isLoggedIn')
  .dashboard__money-panel
    .dashboard__money-item.dashboard__money-item--welcome
      .dashboard__money-desc-text.pad-l-0 Welcome
      .dashboard__money-main-text.pad-t-5 {{ profileName }}
    .dashboard__money-item.cursor-pointer.dashboard__money-item--assets(@click='gotoAssets')
      .dashboard__money-desc-text Assets
      .dashboard__money-main-text.dashboard__money-currency
        span {{ formatDollars(totalAssets) }}
        span.dashboard__money-cents-text .{{ formatCents(totalAssets) }}
    .dashboard__money-item.cursor-pointer.dashboard__money-item--liabilities(@click='gotoLiabilities')
      .dashboard__money-desc-text Liabilities
      .dashboard__money-main-text.dashboard__money-currency
        span {{ formatDollars(totalLiabilities) }}
        span.dashboard__money-cents-text .{{ formatCents(totalLiabilities) }}
    .dashboard__money-item.cursor-pointer.dashboard__money-item--incomes(@click='gotoIncomes')
      .dashboard__money-desc-text Annual Incomes
      .dashboard__money-main-text.dashboard__money-currency
        span {{ formatDollars(totalIncomes) }}
        span.dashboard__money-cents-text .{{ formatCents(totalIncomes) }}
    .dashboard__money-item.cursor-pointer.dashboard__money-item--expenses(@click='gotoExpenses')
      .dashboard__money-desc-text Annual Expenses
      .dashboard__money-main-text.dashboard__money-currency
        span {{ formatDollars(totalExpenses) }}
        span.dashboard__money-cents-text .{{ formatCents(totalExpenses) }}
    .dashboard__money-item.dashboard__money-item--transfer.cursor-pointer(@click='gotoTransfers')
      .dashboard__money-item--transfer-text
        span.pad-r-5
          font-awesome.user-icon(icon="right-left")
        span Transfers

  .dashboard-panel-row
    .dashboard-panel.dashboard-panel__future-report(v-if='progressComplete')
      FutureForecastPanel
    .dashboard-panel.dashboard-panel__getting-started(v-else)
      GettingStartedPanel
    .dashboard-panel.dashboard-panel__scenarios
      ScenarioPanel

  .dashboard-panel-row
    .dashboard-panel.dashboard-panel__week
      WeekPanel

  .dashboard-panel-row
    .dashboard-panel.dashboard-panel__income-analysis
      IncomeAnalysis
    .dashboard-panel.dashboard-panel__expense-analysis
      ExpenseAnalysis

  <!-- GOAL | Create a budget - maybe later -->

</template>

<script>
import MoneyStatus from "../components/moneyStatus.vue"
import axios from "axios";
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import { useHead } from "@unhead/vue";
import MoneyTable from "@/components/moneyTable.vue";
import FutureForecastPanel from "@/dashboard/futureForecastPanel.vue";
import ScenarioPanel from "@/dashboard/scenarioPanel.vue";
import WeekPanel from "@/dashboard/weekPanel.vue";
import IncomeAnalysis from "@/dashboard/incomeAnalysis.vue";
import ExpenseAnalysis from "@/dashboard/expenseAnalysis.vue";
import GettingStartedPanel from "@/dashboard/gettingStartedPanel.vue";

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'Dashboard',
  data() {
    return {
      stats: {},
    }
  },
  computed: {
    profile: function() { return this.$store.state.profile },
    profileName: function () { return this.profile.name },
    progressComplete: function () { return (this.profile.progress & 127) === 127 },
    totalAssets: function () { return (this.stats && this.stats.totals) ? this.stats.totals.assets : 0 },
    totalLiabilities: function () { return (this.stats && this.stats.totals) ? this.stats.totals.liabilities : 0 },
    totalIncomes: function () { return (this.stats && this.stats.totals) ? this.stats.totals.incomes : 0 },
    totalExpenses: function () { return (this.stats && this.stats.totals) ? this.stats.totals.expenses : 0 },
  },
  methods: {
    currentFinancialPosition() {
      this.$router.push("CurrentFinancialPosition")
    },
    gotoAssets() {
      this.$router.push("Assets")
    },
    gotoLiabilities() {
      this.$router.push("Liabilities")
    },
    gotoIncomes() {
      this.$router.push("Incomes")
    },
    gotoExpenses() {
      this.$router.push("Expenses")
    },
    gotoTransfers() {
      this.$router.push("Transfers")
    },
    gotoScenarios() {
      this.$router.push("Scenarios")
    },
    reports() {
      this.$router.push("Reports")
    }
  },
  mounted: function () {
    useHead({
      title: 'Dashboard Fyffle',
      meta: [
        { name: 'description', content: 'Fyffle Dashboard' },
      ],
    })
    axios.get("/api/reports/dashboard_stats").then((response) => {
      this.stats = response.data
    });
  },
  components: {
    MoneyStatus,
    MoneyTable,
    FutureForecastPanel,
    ScenarioPanel,
    WeekPanel,
    IncomeAnalysis,
    ExpenseAnalysis,
    GettingStartedPanel
  }

}
</script>

<style lang='scss'>
</style>