<template lang="pug">
.v-privacy.mgn-b-30
  .legal-container
    h2.pad-t-10.pad-b-20 Privacy Policy
    .legal-date
      | Date Updated: 14 July 2024
    .legal-heading
      | Collection of Personal Information
    LegalList(listType='numbers' :items='collPersonalList')
    .legal-heading
      | Use and Disclosure of Personal Information
    LegalList(listType='alpha-brackets' :items='usePersonalList')
    LegalList.pad-t-20(listType='nested' :nestedItems='mainList')
    div This Privacy Policy is effective as of 14th July 2024. We retain the right to make amendments to this Privacy Policy at any time. Except for the amendments listed above, the current version of the Privacy Policy supersedes all previous versions.
</template>

<script>
import { useHead } from "@unhead/vue";
import LegalList from "@/pages/legalList.vue";

const collPersonalList = [
  'Fyffle ("We", "Our", "Us"), the operator of the website <a href="https://fyffle.com">https://fyffle.com/</a>',
  'You, the users of the <a href="https://fyffle.com">https://fyffle.com/</a> website ("You", "Your").'
]

const usePersonalList = [
    'This Privacy Policy governs our online financial tool called Future Financial Forecasting available at <a href="https://fyffle.com/privacyPolicy">https://fyffle.com/privacyPolicy</a>, owned and operated by Fyffle.',
    'Future Financial Forecasting allows users to manually enter personal financial details such as income, expenses, assets and liabilities, and run different scenarios to forecast their financial position into the future.',
    'This Privacy Policy and our practices comply with the Australian Privacy Principles contained in the Privacy Act 1988 (Cth).'
]

const mainList = [
  {type: 'head', value: 'Quality of Personal Information'},
  {type: 'head', value: 'Definitions'},
  {type: 'item', value: '<b>Personal information</b> means information or an opinion about an identified individual, or an individual who is reasonably identifiable, whether the information or opinion is true or not and whether recorded in material form or not.'},
  {type: 'item', value: '<b>Sensitive information</b> means information about an individual\'s racial or ethnic origin, political opinions, membership of a political association, religious beliefs or affiliations, philosophical beliefs, membership of a professional or trade association, membership of a trade union, sexual orientation or practices, or criminal record. It also includes health information and biometric information about an individual.'},
  {type: 'item', value: '<b>Consent</b> means voluntary agreement to some act, practice or purpose. Consent is required from an individual for the collection, use or disclosure of their personal information.'},
  {type: 'item', value: '<b>Anonymise/De-identify</b> means the process of removing personal details or other identifying information from data so that an individual can no longer be reasonably identified.'},
  {type: 'head', value: 'Security of Personal Information'},
  {type: 'item', value: 'Physical and Network Security'},
  {type: 'subitem', value: 'Our servers are kept in a secure environment protected by physical security measures.'},
  {type: 'subitem', value: 'Personal information transmitted to our site is protected by SSL/TLS encryption.'},
  {type: 'subitem', value: 'We maintain firewalls and perform regular vulnerability assessments to minimize risk of attack.'},
  {type: 'item', value: 'Access Controls'},
  {type: 'subitem', value: 'Access to personal information is restricted to authorised personnel through the use of passwords and private key authentication.'},
  {type: 'subitem', value: 'User passwords are hashed using industry standard techniques.'},
  {type: 'item', value: 'Data Security'},
  {type: 'subitem', value: 'We regularly backup personal information to minimise risk of loss in the event of a system failure or disaster.'},
  {type: 'subitem', value: 'Backups are encrypted and stored securely offsite.'},
  {type: 'item', value: 'Incident Response'},
  {type: 'subitem', value: 'We have a data breach response plan to address any incidents in a timely and serious manner to minimise harm.'},
  {type: 'subitem', value: 'Affected users will be notified as required by law.'},
  {type: 'item', value: 'Ongoing Evaluation'},
  {type: 'subitem', value: 'Our security practices and protocols are regularly reviewed and improved to address new risks.'},
  {type: 'subitem', value: 'Systems are monitored for unauthorised access or activity.'},
  {type: 'head', value: 'Openness'},
  {type: 'item', value: 'This Privacy Policy is available on our website at <a href="https://fyffle.com/privacyPolicy">https://fyffle.com/privacyPolicy</a>'},
  {type: 'item', value: 'We aim to be open about how we handle personal information and users can contact us via the details in section 8 to obtain this information or discuss any concerns relating to privacy.'},
  {type: 'item', value: 'This Privacy Policy will be regularly reviewed and updated to ensure it accurately reflects our privacy practices. The current version number and date will be displayed on the website. Any material changes will be clearly indicated.'},
  {type: 'item', value: 'Our contact details are:<br><br>Email: <a href="mailto:support@fyffle.com">support@fyffle.com</a>'},
  {type: 'head', value: 'Access and Correction'},
  {type: 'item', value: 'Right to access personal information'},
  {type: 'subitem', value: 'You have a right to request access to any personal information that we hold about you at any time.'},
  {type: 'subitem', value: 'Upon receipt of your verified request, we will provide you with access to your personal information within 30 days, including the manner and form of access.'},
  {type: 'item', value: 'Right to correction of personal information'},
  {type: 'subitem', value: 'You have a right to request correction of any personal information that we hold about you that is inaccurate, out-of-date, incomplete, irrelevant or misleading.'},
  {type: 'subitem', value: 'We will take reasonable steps to correct the information within 30 days of your request.'},
  {type: 'subitem', value: 'If we refuse to correct the personal information, we will provide you with written reasons for the refusal and notify you of your right to make a complaint to the Office of the Australian Information Commissioner.'},
  {type: 'item', value: 'Requests for access or correction'},
  {type: 'subitem', value: 'You can make a request for access or correction by contacting us through the details provided on our website or by logging into your account through the website.'},
  {type: 'subitem', value: 'We will verify your identity before granting access or making corrections to your personal information.'},
  {type: 'item', value: 'Refusal of access or correction'},
  {type: 'subitem', value: 'We may refuse to provide access or make a correction to personal information in certain permitted situations, such as where the request would impact another person\'s privacy or where the request is frivolous or vexatious.'},
  {type: 'subitem', value: 'If we refuse your request, we will provide written reasons for the refusal and details of how you can make a complaint about the refusal.'},
  {type: 'head', value: 'Identifiers'},
  {type: 'item', value: 'We will only collect and use a user\'s email address as an identifier in order to provide services and contact the user as needed in relation to their use of the Website.'},
  {type: 'item', value: 'A user\'s email address will not be used for any other purpose without their consent.'},
  {type: 'item', value: 'A user\'s email address will not be disclosed to third parties unless required by law or with the user\'s consent.'},
  {type: 'item', value: 'We will take reasonable steps to ensure a user\'s email address is protected from misuse, interference, loss, unauthorised access, modification or disclosure.'},
  {type: 'item', value: 'Users can log in to the system with their email and password to run scenarios and generate reports.'},
  {type: 'item', value: 'A user\'s email address will only be collected with their consent, which is obtained at the time of account registration.'},
  {type: 'item', value: 'Users can withdraw their consent to the use of their email address and request deletion of their account at any time by using the online account deletion service. The account will be held as suspended for 30 days and then automatically deleted.'},
  {type: 'head', value: 'Anonymity'},
  {type: 'item', value: 'We will not adopt, use or disclose a government related identifier of an individual unless permitted under the Privacy Act or a permitted general situation exists.'},
  {type: 'item', value: 'Where possible, we will anonymise personal information collected so that the individual is not reasonably identifiable.'},
  {type: 'item', value: 'For example, we may anonymise financial and usage data by aggregating information and removing personal identifiers to use for analytics and reporting purposes.'},
  {type: 'item', value: 'It is not always possible to anonymise information due to the nature of the services provided, as users manually enter their own financial details. Maintaining identification may be necessary to provide personalised forecasting services to users.'},
  {type: 'item', value: 'However, we will aim to anonymise data where possible, such as using de-identified data for analytics and reporting.'},
  {type: 'head', value: 'Transborder Data Flows'},
  {type: 'item', value: 'We may store personal information on servers located outside Australia.'},
  {type: 'subitem', value: 'The countries in which servers are located are recognised by the European Commission as having adequate privacy laws equivalent to Australia.'},
  {type: 'subitem', value: 'Your personal information is transferred to provide the services available through our website and for legitimate business purposes associated with running our business.'},
  {type: 'item', value: 'We take reasonable steps to ensure appropriate data handling practices, such as encryption of data during transfer and access controls, are in place to protect personal information held overseas.'},
  {type: 'item', value: 'You consent to the transfer of your personal information outside of Australia for these purposes.'},
  {type: 'item', value: 'Even when personal information is contained overseas, you can still exercise your rights under the Privacy Act 1988 (Cth) and under this Privacy Policy.'},
  {type: 'item', value: 'For further information about overseas transfers of personal information, please contact our Privacy Officer.'},
  {type: 'head', value: 'Sensitive Information'},
  {type: 'item', value: 'We will not collect any health information about users without their express consent.'},
  {type: 'subitem', value: 'Health information means information or an opinion about the physical or mental health or disability of an individual.'},
  {type: 'item', value: 'We will not collect any information about a user\'s racial or ethnic origin without their express consent.'},
  {type: 'item', value: 'We will not collect any information about a user\'s criminal record.'},
  {type: 'item', value: 'We do not collect any information about a user\'s political opinions or memberships.'},
  {type: 'item', value: 'We do not collect any information about a user\'s sexual activities or practices.'},
  {type: 'item', value: 'We do not collect any biometric information such as fingerprints without a user\'s express consent.'},
  {type: 'item', value: 'We do not collect any genetic information about users.'},
  {type: 'item', value: 'If we should obtain any sensitive information, we will ensure the information is managed in accordance with the Australian Privacy Principles.'},
  {type: 'head', value: 'Making Privacy Complaints and Enquiries'},
  {type: 'item', value: 'Complaints Process<br><br>All complaints will be acknowledged in writing within 7 days. They will be addressed promptly and investigated thoroughly with the outcome communicated to the complainant within 30 days. This period may be extended by up to 15 additional days where required.'},
  {type: 'item', value: 'Review by the Office of the Australian Information Commissioner<br><br>If the complainant is dissatisfied with the outcome of their complaint, they may seek an external review by the Office of the Australian Information Commissioner.'},
  {type: 'item', value: 'No Fee for Complaints<br><br>Lodging a complaint or inquiry with us regarding the handling of personal information will not attract any fee.'},
  {type: 'item', value: 'Records of Complaints<br><br>Records of all complaints and their outcomes will be maintained for a period of no less than 7 years.'},
  {type: 'head', value: 'Links'},
  {type: 'item', value: 'Third party sites. Our website may contain links to third party sites that are not owned or controlled by us. We are not responsible for the privacy practices of those third party sites. Third party sites should have their own privacy policies which we recommend users review.'},
  {type: 'item', value: 'Embedded content and widgets. Our website may contain embedded content such as YouTube videos or Facebook feeds. These third party sites may collect information about users that are sent by your browser.'},
  {type: 'item', value: 'Analytics. We do not use analytics services'},
  // {type: 'item', value: 'Analytics. We may use analytics services, such as Google Analytics, that use cookies and similar technologies to collect and receive information from users and third-party sites. Users can opt-out of being tracked. Details on the specific analytics services used will be provided in our Privacy Policy when implemented.'},
  {type: 'item', value: 'Social media. Our website contains links to social media pages like Facebook. Clicking on those links means the relevant social network\'s cookie will be placed on users\' browser. Users should review each social media platform\'s privacy policy.'},
  {type: 'item', value: 'No liability. We are not liable or responsible for the privacy practices or content of third party sites. Users should exercise caution and review a third party\'s privacy policy before disclosing any personal information.'},
  {type: 'head', value: 'Cookies'},
  {type: 'item', value: 'We use cookies to collect and analyse usage statistics on our website. Cookies allow us to identify your device but do not contain any personal information.'},
  {type: 'item', value: 'There are two main types of cookies used on our website - session cookies, which are deleted after each session, and persistent cookies, which remain on your device for the period of time specified in the cookie.'},
  {type: 'item', value: 'Cookies are used for preferences, analytics and advertising purposes. Preferences cookies remember information you have entered or choices you make to improve your experience. Analytics cookies collect information about how users interact with our website. Advertising cookies may be used to deliver advertisements that are relevant to you.'},
  {type: 'item', value: 'You can control and manage cookies through your browser settings. The Help function within your browser should tell you how. You may refuse the use of cookies by selecting the appropriate browser settings. However, please note that if you do this, some areas of our website may not work properly or at all.'},
  {type: 'item', value: 'Third parties may also use cookies on our website. We have no control over third party cookies or how your information is used by third party websites. Please review the privacy policies of third parties for more information before accepting cookies from these websites.'},
  {type: 'item', value: 'Cookies used on our website do not contain any sensitive personal information as defined by the Privacy Act 1988 (Cth).'},
  {type: 'item', value: 'Our use of cookies complies with the Australian Privacy Principles.'},
  {type: 'head', value: 'Collection Statements'},
  {type: 'item', value: 'At the time of collection of personal information through registration on the website, a collection statement will be provided to the individual stating:'},
  {type: 'subitem', value: 'The purpose for collection of the personal information, being to create an account and use features of the website.'},
  {type: 'subitem', value: 'The types of personal information that are collected, being email address.'},
  {type: 'subitem', value: 'That the personal information will be disclosed to the website operator Fyffle for the purpose of providing the requested services.'},
  {type: 'item', value: 'Sensitive information will not be collected without consent.'},
  {type: 'item', value: 'Personal information collected will be stored securely on servers located in India and protected by encryption and access controls.'},
  {type: 'item', value: 'Individuals may access or request correction of their personal information by contacting the website operator.'},
  {type: 'item', value: 'Individuals have the right to interact anonymously with the website and limit collection of personal information where possible.'},
  {type: 'item', value: 'Individuals may withdraw consent for use of their personal information for marketing or other purposes by contacting the website operator.'},
  {type: 'head', value: 'Further Information'},
  {type: 'item', value: 'Contact Details. The contact details of the responsible entity are:'},
  {type: 'subitem', value: 'Name: Fyffle'},
  {type: 'subitem', value: 'Address: 43 Blue Water Ave, Thornlands, Qld, Australia, 4164'},
  {type: 'subitem', value: 'Phone: 049 007 8849'},
  {type: 'subitem', value: 'Email: <a href="mailto:support@fyffle.com">support@fyffle.com</a>'},
  {type: 'item', value: 'Making Privacy Complaints. Written complaints about breaches of privacy should be directed to the contact details listed above. All complaints will receive a response within 30 calendar days.'},
  {type: 'item', value: 'Review of Policy. This Privacy Policy is subject to ongoing review and may change over time. Users are encouraged to refer back for updates.'},
  {type: 'item', value: 'Access and Correction. Users have a right to access or correct their personal information as set out in an earlier section. Requests can be submitted to the contact details listed above.'},
  {type: 'item', value: 'Interpretation. Any requests for clarification or interpretation of this Privacy Policy can be directed to the contact details listed above.'},
]

export default {
  name: 'privacyPolicy.vue',
  data() {
    return {
      collPersonalList: collPersonalList,
      usePersonalList: usePersonalList,
      mainList: mainList
    }
  },
  computed: {},
  methods: {},
  mounted: function () {
    useHead({
      title: 'Privacy Policy for Fyffle',
      meta: [
        { name: 'description', content: 'Privacy Policy for Fyffle' },
      ],
    })
  },
  components: {
    LegalList
  }
}
</script>

<style lang="scss">
</style>