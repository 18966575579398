<template lang="pug">
.v-future-forecast-panel
  .dashboard-panel__heading
    span Future Forecast Net Worth
    span
      button.dashboard-panel__more-button(@click='gotoReport') More
  .chart-container
    Line(:data='netWorthChartData' :options='chartOptions')
</template>

<script>
import axios from "axios";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'vue-chartjs'
import MoneyBar from "@/pages/moneyBar.vue";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)

const cdata = {
  "labels": [],
  "datasets":[]
}

export default {
  name: 'futureForecastPanel.vue',
  data() {
    return {
      netWorthChartData: cdata,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom'
          }
        },
        elements: {
          point: {
            radius: 1,
            hitRadius: 10,
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    loadNetWorthChart: function () {
      axios.get("/api/reports/net_worth_chart_data").then((response) => {
        this.netWorthChartData = response.data
      })
    },
    gotoReport: function () {
      this.$router.push("/report/net-worth-report")
    }
  },
  mounted: function () {
    this.loadNetWorthChart()
  },
  components: {
    Line,
    MoneyBar
  }
}
</script>

<style lang="scss">
.v-future-forecast-panel {
  height: 100%;
  display: flex;
  flex-direction: column;

  .chart-container {
    flex-grow: 1;
  }
}
</style>