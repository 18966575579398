<template lang="pug">
.v-income-analysis
  .dashboard-panel__heading
    span Income Analysis
    span
      button.dashboard-panel__more-button(@click='gotoReport') More
  .chart-container
    Pie(:data='incomeChartData' :options='chartOptions')
</template>

<script>
import axios from "axios";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

const cdata = {
  "labels": [],
  "datasets":[]
}

export default {
  name: 'incomeAnalysis.vue',
  data() {
    return {
      incomeChartData: cdata,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom'
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    loadData: function () {
      axios.get("/api/reports/income_expense_chart_data").then((response) => {
        this.incomeChartData = response.data
      })
    },
    gotoReport: function () {
      this.$router.push("/report/income-expense-report")
    }
  },
  mounted: function () {
    this.loadData()
  },
  components: {
    Pie
  }
}
</script>

<style lang="scss">
</style>