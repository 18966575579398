<template lang="pug">
</template>

<script>
import { capitalize} from "@/common/strings";

export default {
  name: 'commonMixin.vue',
  methods: {
    isNull: function (val) {
      return (val === undefined || val === null || val === '')
    },
    formatNumber: function(num, decPoints = 0) {
      if (this.isNull(num)) {
        return ""
      }
      return parseFloat(num).toFixed(decPoints)
    },
    formatCurrency: function(num) {
      if (this.isNull(num)) {
        return ""
      }
      return new Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: "USD"
      }).format(num)
        .trim();
    },
    formatDollars: function(num) {
      if (this.isNull(num)) {
        return ""
      }
      return new Intl.NumberFormat("en-US", {
        style: 'currency',
        currency: "USD",
        minimumFractionDigits: 0
      }).format(Math.floor(num))
        .trim();
    },
    formatCents: function(num) {
      if (this.isNull(num)) {
        return ""
      }
      var n = Math.round(num*100) % 100;

      return n.toString().padStart(2, '0')
    },
    formatFrequency: function(freq) {
      return freq ? capitalize(freq) : ''
    },
  },
}
</script>
