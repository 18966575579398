<script>

export default {
  name: 'permissionsMixin.vue',
  data() {
    return {
    }
  },
  computed: {
    profile: function () { return (this.$store.state.profile)},
    isLoggedIn: function() {
      let val = false
      if (this.profile !== undefined && this.profile !== null && this.profile.id) {
        val = true
      }
      return val;
    },
    isAdmin: function() {
      let val = false
      if (this.profile !== undefined && this.profile !== null) {
        val = this.profile.admin;
      }
      return val;
    },
    // Move to home page is not logged in
    checkLoggedIn: function() {
      if (this.isLoggedIn === false) {
        // this.$router.push("/")
        return false;
      }
      return true;
    },
    checkAdmin: function() {
      if (this.isAdmin === false) {
        // this.$router.push("/")
        return false;
      }
      return true;
    }
  },
}
</script>
