<template lang="pug">
.v-income-expense-report(v-if='isLoggedIn')
  .heading-1
    | Income Expense Report
  p Where am I making money, and where is it going? This is a list of all your income and expenses you make for the first year. Asset/Liability Interest amounts will be approximate.
  .inc-exp-container
    .inc-exp-chart
      h2 Incomes
      .chart-container
        Pie(:data='incomeChartData' :options='chartOptions')
      table.simple-table
        tr
          th Name
          th Year Amount
        tr(v-for='income in incomes')
          td {{ income.name }}
          td.number-cell {{ formatNumber(income.total) }}
        tr
          th TOTAL
          td.number-cell.bold-cell {{ formatNumber(incomeTotal) }}
    .inc-exp-chart
      h2 Expenses
      .chart-container
        Pie(:data='expenseChartData' :options='chartOptions')
      table.simple-table
        tr
          th Name
          th Year Amount
        tr(v-for='expense in expenses')
          td {{ expense.name }}
          td.number-cell {{ formatNumber(expense.total) }}
        tr
          th TOTAL
          td.number-cell.bold-cell {{ formatNumber(expenseTotal) }}
  MoneyBar.mgn-t-30
</template>

<script>
import PermissionsMixin from "@/components/permissionsMixin.vue";
import CommonMixin from "@/components/commonMixin.vue";
import MoneyBar from "@/pages/moneyBar.vue";
import axios from "axios";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Pie } from 'vue-chartjs'

ChartJS.register(ArcElement, Tooltip, Legend)

const cdata = {
  "labels": [],
  "datasets":[]
}

export default {
  mixins: [PermissionsMixin, CommonMixin],
  name: 'incomeExpenseReport.vue',
  data() {
    return {
      incomeChartData: cdata,
      expenseChartData: cdata,
      incomeTotal: 0,
      expenseTotal: 0,
      incomes: [],
      expenses: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right'
          }
        }
      }
    }
  },
  computed: {},
  methods: {
    loadData: function () {
      axios.get("/api/reports/income_expense_chart_data").then((response) => {
        this.incomeChartData = response.data
        this.incomes = []
        this.incomeTotal = 0
        for(const [index, name] of this.incomeChartData.labels.entries()) {
          let total = this.incomeChartData.datasets[0].data[index]
          this.incomeTotal = this.incomeTotal + parseFloat(total)
          this.incomes.push({name: name, total: total})
        }
      })
      axios.get("/api/reports/income_expense_chart_data?expense=true").then((response) => {
        this.expenseChartData = response.data
        this.expenses = []
        this.expenseTotal = 0
        for(const [index, name] of this.expenseChartData.labels.entries()) {
          let total = this.expenseChartData.datasets[0].data[index]
          this.expenseTotal = this.expenseTotal + parseFloat(total)
          this.expenses.push({name: name, total: total})
        }
      })
    }
  },
  mounted: function () {
    this.loadData()
  },
  components: {
    MoneyBar,
    Pie
  }
}
</script>

<style lang="scss">
.v-income-expense-report {
  .inc-exp-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: 30px;
    flex-wrap: wrap;
  }
  .inc-exp-chart {
    margin-bottom: 30px;
    width: 100%;
  }
  .chart-container {
    width: 100%;
  }
}

@media only screen and (min-width: 750px) {
  .v-income-expense-report {
    .inc-exp-chart {
      width: 48%;
    }
  }
}
</style>