<template lang="pug">
.v-invest-or-mortgage
  .learn-container
    .learn-h1 Pay off Mortgage or Invest?
    .learn-text
      | This scenario will help you decide if you are better off putting your spare income into paying off a mortgage or investing.
    .learn-text
      | Isn’t paying off your mortgage better than investing? Well, it depends, and that’s why Fyffle can help us work that out. It depends on many factors, but the main one is whether we are getting a higher return on our investment than our mortgage rate. If our investment returns well, then we will save money quicker with the investment, and then pay off our mortgage in one lump sum when our investment total exceeds the remaining mortgage amount.
    .learn-text
      | For this to work, you really need to find out how much your investment will return as a percentage for a year. You may not be able to get an accurate value, but generally, the longer the investment, the more accurate the value you can get. You will need to look at past performance to get an idea.
    .learn-text
      | There are several different types of investments you can make. Diversifying is always a good idea. You will need to do your own due diligence in finding out these values. Here are some ideas of what types of investments are available and their possible returns. Keep in mind these are long-term values (7+ years); some years may perform poorly.
      ul
        li Bank Account (0 - 5%)
        li Term Deposit (0 - 5%)
        li Bonds (3 - 6%)
        li Blue-chip Shares (6 - 10%). Plus Dividends
        li ETFs (6 - 10%). Plus Dividends
        li REITs (6 - 8%). Plus Dividends

    .learn-text
      | In our example we own a house, and have a mortgage.
      | With our extra income, we will create three scenarios to compare with.
      ul
        li Leave our money in the bank
        li Pay off our mortgage
        li Invest into ETFs

    .learn-text
      | This guide will cover the following actions:
      ul
        li
          a(href='#position') Entering our Financial Position
        li
          a(href='#bank') Leaving in the Bank Scenario
        li
          a(href='#mortgage') Entering a Pay off Mortgage Scenario
        li
          a(href='#invest') Invest to ETFs Scenario
        li
          a(href='#reports') Generating Reports

    a(name='position')
      .learn-h2 Enter our Financial Position
    .learn-text
      | In our example we will own one house and have one mortgage. Lets start by entering our Assets.
      | Click on <b>Add Asset</b>, and enter the details below for a <b>Savings Account</b>.
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/savings_account.png')
    .learn-text
      | We will also include an interest amount on the Savings Account, where we will be paid <b>2%</b> per year for keeping our money in the bank. While in the Savings Account Asset, Click on the <b>+</b> for the Asset Income, and enter values as shown below, then click <b>Add Asset</b> button:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/savings_account_interest.png')

    .learn-text
      | Now we will add an Asset for our House. Enter details below, then click <b>Add Asset</b>:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/house_asset.png')

    .learn-text
      | Next we will add our Mortgage. Click on the <b>Add new Liability</b> and enter details below for the Mortgage:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/mortgage_liability.png')

    .learn-text
      | We also need to add the interest amount for the mortgage. Click on the <b>+</b> button for the Liability Expenses, and enter values below, then Click <b>Add Expense</b>, then Click <b>Add Liability</b>:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/mortgage_interest.png')

    .learn-text
      | For our Income we will enter just one income amount, as seen below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/salary.png')

    .learn-text
      | For our Expenses, we will keep it simple and group all our expenses into weekly, monthly. This will not include the mortgage payment. See below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/weekly_expenses.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/monthly_expenses.png')

    .learn-text
      | We will pay for our mortgage by transferring money from the Savings Account into our Mortgage every month. Create a new Transfer and enter the details below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/pay_mortgage.png')

    a(name='bank')
      .learn-h2 Leave Money in Bank Scenario
    .learn-text
      | Now we have entered all of our financial position, we are ready to start creating scenarios to test our ideas.
    .learn-text
      | We create a new Scenario by clicking the <b>Add new Scenario</b> button. Lets call it <b>Bank</b>, then go to the bottom of the page and click <b>Save</b>. We will not make any changes in this scenario.
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/add_scenario_bank.png')

    a(name='mortgage')
      .learn-h2 Entering a Pay off Mortgage Scenario
    .learn-text
      | Now we want to create a scenario where we pay our extra money directly into the mortgage.
    .learn-text
      | We going to find out if we can afford to add an extra $1,000 per month into the mortgage.

    .learn-text
      | Got to Scenarios, and Click on <b>Add new Scenario</b>. We will call the Scenario "Extra into Mortgage". Now click on the <b>Add Transfer</b> button, and fill in details shown below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/mortgage_transfer1000.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/mortgage_transfer_table.png')

    .learn-text
      | Now go to <b>Reports</b> and generate reports.
    .learn-text
      | This is a problem for us. At the bottom of the reports page it gives us warnings about our Savings Account balance going below 0. See below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/report_warning.png')


    .learn-text
      | This means we cannot afford to add an extra $1,000 per month into the mortgage. Lets try $500 instead of $1,000. Click on <b>Scenarios</b>, then Click the <b>Edit</b> next to the <b>Extra into Mortgage</b> row. Now at the bottom of the page click the <b>Edit</b> for the <b>Transfer to Mortgage</b> row. Change the Amount of 1000, to 500.
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/extra_into_mortgage.png')

    .learn-text
      | Now go back to reports, and Generate report again. This time there are no warning messages so we can afford to do that.

    a(name='invest')
      .learn-h2 Invest to ETFs Scenario
    .learn-text
      | Now we want to create our final scenario where we invest our extra income into an ETF.
    .learn-text
      | We will choose an ETF that has an average return of 8%. The average amount is measured over 7+ years, which means some years may perform poorly, and other years perform really well.
    .learn-text
      | We will create another Scenario, lets call it "Invest in ETFs". We will need to add a new Asset for the ETFs. Click on the <b>Add Asset</b> button while in the Scenario. Our ETF will have an Appreciation of 8%, plus an income of 2% from dividends that will be reinvested into the ETF. See below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/etf_asset.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/etf_dividend.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/etf_assets_table.png')

    .learn-text
      | And we will transfer our extra $500 income into the ETF account. In the Scenario page, click on the <b>Add Transfer</b> and transfer the money from the Savings Account into the ETF account, see below:
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/etf_transfer.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/etf_transfer_table.png')

    a(name='reports')
      .learn-h2 Reports
    .learn-text
      | Now we will generate the report again, and we should see 3 columns for each of our 3 scenarios.
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/summary_report.png')
    .img-container
      img.img-70(src='/images/learn/invest_or_mortgage/summary_report2.png')
    .learn-text
      | The <b>Initial Assets</b> amount is the same for each scenario. It is sum of the balance of each Asset at the end of the first day (1 July 2024). Similarly with the Liabilities. From there the Net Worths differ slightly, and increasing as the years go by. We want to compare the <b>Net Worth</b> of each of the Scenarios at the Year 5 mark.
      ul
        li <b>Bank Scenario</b> is where we leave our money in the Bank, which as a interest rate of 2%. This a low rate of return, but it is consistent. This has the lowest Net Worth at Year 5, but it is predicable, since we know we will get the same return consistently over the years.
        li <b>Extra into Mortgage</b> At the end of year 5, we are about $3,500 better off by putting our extra income in the mortgage. We have the advantage of seeing our loan amount decrease also.
        li <b>Invest in ETFs</b> At the end of year 5, we are about $7,500 better off by putting our extra income into an ETF with a high rate of return. This value is not predictable because the value of the ETFs will go up and down over time, and so will the dividend return, but can be used as a rough guide. The trade off is we will not see our Mortgage loan amount go down, we just need to keep in mind we need to sum together our total asset balances with our mortgage - this is the Net Worth. The other thing to keep in mind is tax. You will need to pay Capital Gains Tax once you sell your ETF.










</template>

<script>

export default {
  name: 'investOrMortgage.vue',
  data() {
    return {}
  },
  computed: {},
  methods: {},
  mounted: function () {
  },
  components: {}
}
</script>

<style lang="scss">
</style>