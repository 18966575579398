// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails')

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
import jquery from "jquery"
window.$ = jquery
window.jQuery = jquery

import { createApp } from 'vue';

import App from "../app.vue"
// import axios from 'axios';
import router from "../routes.js";
import store from "../store.js";
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import "../common/autoLogout.js"
import { createHead } from "@unhead/vue";
const head = createHead()

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

// LOOK HERE FOR FONTAWESOME: https://fontawesome.com/search
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { faChartPie } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faTable } from '@fortawesome/free-solid-svg-icons'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import { faRightLeft } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faInfoCircle)
library.add(faChartPie)
library.add(faChartLine)
library.add(faTable)
library.add(faRedo)
library.add(faChevronDown)
library.add(faCircleUser)
library.add(faRightLeft)

const app = createApp(App).
  component('font-awesome', FontAwesomeIcon).
  component('VueDatePicker', VueDatePicker).
  use(FloatingVue).
  use(head).
  use(router).
  use(store)

app.mount('#app');