<template lang="pug">
.v-changes2-form
  .form__panel.form__section
    h2 {{ addStr}} {{ moneyType}} Changes for {{moneyName}}
    p Any changes that will happen to the {{moneyType}} in the future.
    div
      AddEditForm(:fields='fields' :adding='adding' @dataAdded="changeAdded" @cancelled="cancelled" :addText='addText')
</template>

<script>
import moment from 'moment'
import AddEditForm from "../components/addEditForm.vue"
import { clone } from "@/common/copies"

export default {
  props: { fields: Array, fieldValues: Array, changes: Array, moneyType: String, adding: Boolean, moneyName: String },
  name: 'changes2Form',
  data() {
    return {
      showInputs: false,
      newChanges: [],
    }
  },
  computed: {
    hasChanges: function () { return this.newChanges != null && this.newChanges.length > 0},
    visibleFields: function () {
      return this.fields.filter( (f) => f.editable !== false )
    },
    addStr: function () { return (this.adding ? "Add" : "Edit") },
    addText: function () { return this.addStr + " Change" }
  },
  methods: {
    changeValue: function (change, field) {
      if (change === null) {
        return ''
      }
      if (field.type === 'date') {
        return moment(change[field.name]).format("YYYY-MM-DD")
      } else {
        return change[field.name];
      }
    },
    addChange: function () {
      this.showInputs = false
    },
    newChange: function () {
      this.showInputs = true
    },
    changeAdded: function (data) {
      this.showInputs = false
      this.newChanges.push(data)
      this.postChanges()
    },
    cancelled: function () {
      this.showInputs = false
      this.$emit('cancelled');
    },
    deleteChange: function (index) {
      this.newChanges.splice(index,1)
      this.postChanges()
    },
    loadChanges: function () {
      if (this.changes != null) {
        this.newChanges = clone(this.changes)
      } else {
        this.newChanges = []
      }
    },
    postChanges: function () {
      this.$emit('postChanges', this.newChanges);
    }
  },
  mounted: function () {
    this.loadChanges()
  },
  components: {
    AddEditForm
  },
  watch: {
    "fieldValues": function () {
      if (this.fieldValues !== undefined) {
        let chgs = []
        for (let i=0; i < this.fieldValues.length; i++) {
          chgs.push(this.fieldValues[i]['money_changes'])
        }
        this.newChanges = clone(chgs)
      }
    }
  }
}
</script>

<style lang="scss">
</style>