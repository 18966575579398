export function numberToWord(n) {
  return ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve'][n] || ("" + n);
}

export function capitalize(word) {
  if ((word != null ? word.length : void 0) > 0) {
    return "" + (word[0].toUpperCase()) + word.slice(1);
  } else {
    return word;
  }
}

export function pluralize(singular, count, plural) {
  if (count === 1) {
    return singular;
  } else if (plural != null) {
    return plural;
  } else {
    return singular + "s";
  }
}

export function humanize(str) {
  return str.replace(/^[\s_]+|[\s_]+$/g, '').replace(/[_\s]+/g, ' ').replace(/^[a-z]/, function(m) {
    return m.toUpperCase();
  });
}

export function titleize(str) {
  return _.startCase(str);
}

export function sentencify(words) {
  if (words.length > 2) {
    return (words.slice(0, words.length - 1).join(', ')) + " and " + words[words.length - 1];
  } else {
    return words.join(' and ');
  }
}

export function summarize(words) {
  if (words.length > 3) {
    return words[0] + ", " + words[1] + " and " + (words.length - 2) + " others";
  } else {
    return this.sentencify(words);
  }
}

export function ordinalize(number) {
  var mod10, mod100;
  mod10 = number % 10;
  mod100 = number % 100;
  if (mod10 === 1 && mod100 !== 11) {
    return number + "st";
  } else if (mod10 === 2 && mod100 !== 12) {
    return number + "nd";
  } else if (mod10 === 3 && mod100 !== 13) {
    return number + "rd";
  } else {
    return number + "th";
  }
}

export function tuplify(words) {
  return words.map((function(_this) {
    return function(w) {
      return {
        name: _this.capitalize(w),
        value: w
      };
    };
  })(this));
}

export function pixelify(obj) {
  return _.mapValues(obj, function(value) {
    return value + "px";
  });
}

export function stripTags(str) {
  if (str != null) {
    return str.toString().replace(/<\/p>\<p>/g, " ").replace(/<\/?[^>]+(>|$)/g, "").replace(/(&amp;)+/g, '&').replace(/(&lt;)+/g, '<').replace(/(&gt;)+/g, '>').replace(/(&nbsp;)+/g, ' ');
  } else {
    return "";
  }
}

export function replaceLineBreaks(str) {
  if (str != null) {
    return str.replace(/(?:\r\n|\r|\n)/g, '<br>');
  } else {
    return "";
  }
}

export function truncate_filename(name, length) {
  var ext_pos, str;
  if ((name != null ? name.length : void 0) > length) {
    ext_pos = name.lastIndexOf('.');
    str = name.substr(0, length) + '...';
    if (ext_pos > 0) {
      str += name.substr(ext_pos, name.length - 1);
    }
    return str;
  } else {
    return name;
  }
}
