<template lang="pug">
.v-transfer-change
  Changes2Form(:fields='changeFields' :adding='adding' :fieldValues='changeFieldValues' moneyType='Transfer' :moneyName='moneyName' @postChanges='postTransferChanges' @cancelled='cancelled')
  MoneyBar(v-if='scenarioId')
</template>

<script>
import Changes2Form from "@/components/changes2Form.vue";
import axios from "axios";
import MoneyBar from "@/pages/moneyBar.vue";

export default {
  props: ['returnPageUrl'],
  name: 'transferChange',
  data() {
    return {
      changeFields: [
        {name: 'start_date', label: 'Start Date', type: 'date', mandatory: true, help_text: "The date the change in transfer will occur"},
        {name: 'amount', label: 'Amount', type: 'number', mandatory: true, help_text: "The new transfer amount"},
      ],
      changeFieldValues: [],
      changes: undefined,
      transfer: {}
    }
  },
  computed: {
    moneyText: function () { return "Transfer" },
    moneyTextLower: function () { return this.moneyText.toLowerCase() },
    moneyTextLowerPlural: function () { return "transfers" },
    moneyName: function () { return this.transfer ? this.transfer.name : '' },
    scenarioId: function () { let n = this.$route.params.scenarioId; return n ? Number(n) : undefined },
    transferId: function () { return this.$route.params.transferId },
    changesId: function () { return this.$route.params.id },
    adding: function () { return true },
    addEditStr: function () { return this.changesId ? "Edit" : "Add"},
    baseUrl: function () { return "/api/money_changes"},
  },
  methods: {
    returnPageUrlB: function (id) {
      return this.scenarioId ?
          "/addEditScenario/"+this.scenarioId+"?compEdited="+this.moneyText+"_"+id : "/"+this.moneyTextLowerPlural
    },
    postTransferChanges: function (newChanges) {
      this.changes = newChanges[0]
      let model = {
        money_id: this.transferId,
        money_type: 9,
        money_changes: this.changes,
        start_date: this.changes['start_date'],
        scenario_id: this.scenarioId
      }
      if (this.adding) {
        axios.post(this.baseUrl, {money_change: model}).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      } else {
        axios.put(this.baseUrl+"/"+this.changesId, model).then((response) => {
          this.$router.push(this.returnPageUrlB(response.data.id))
        });
      }
    },
    cancelled: function () {
      this.$router.push(this.returnPageUrlB(this.moneyId || ''))
    },
    loadTransfer: function () {
      axios.get("/api/"+this.moneyTextLowerPlural+"/"+this.transferId+"?scenario_id="+this.scenarioId).then((response) => {
        this.transfer = response.data
      });
    }
  },
  mounted: function () {
    this.loadTransfer()
  },
  components: {
    Changes2Form,
    MoneyBar
  }
}
</script>

<style lang="scss">
</style>